import ACTIONS from '@redux/actions';

const pingMiddleware = store => next => action => {
  const { app } = store.getState().app.present;

  if (app.online !== window.navigator.onLine) {
    next({
      type: ACTIONS.APP.SET_NETWORK_STATUS,
      payload: window.navigator.onLine,
    });
  }

  next(action);
};

export default pingMiddleware;
