import React, { useState, Fragment } from 'react';
import { connect } from 'react-redux';
import uuidv4 from 'uuid/v4';
import GRIDS from '@enums/Grids';
import { closeAddBar, createBar, updateBar, addBar } from '@redux/actioncreators/grid';
import GridService from '@js/grid/GridService';
import AbsoluteGrid from '@components/grid/AbsoluteGrid';
import { Translate, t } from '@components/translations';
import BAR_EDIT_MODE from '@enums/BarEditMode';
import { usePassportContext, PassportEnums } from '@tti/passport';
import { closeSubNav, setCustomBarLevel, setCustomBarName } from 'js/redux/actioncreators/app';
import GridBarHeader from './GridBarHeader';
import Dropdown, { Option } from '../shared/Dropdown';

const GridBarModal = ({
  grid,
  app,
  project,
  updateBarInternal,
  createBarInternal,
  addBarInternal,
  closeAddBarInternal,
  setCustomBarNameInternal,
  setCustomBarLevelInternal,
  closeSubNavInternal,
}) => {
  const [createErrors, setCreateErrors] = useState([]);
  const { passportContext, getClaims } = usePassportContext();
  const isGlobalAdmin =
    getClaims(PassportEnums.ClaimType.Role, passportContext.claims).find(
      x => x.value === PassportEnums.RoleType.ProfileManagerGlobalAdmin,
    ) !== undefined;

  const selectedBay = app.selectedBay === -1 ? 0 : app.selectedBay;

  const [barName, barLevel] = (function() {
    let barNameTemp;
    let barLevelTemp;

    // First check if we have a customBarName set //
    if (app.customBarName !== undefined) {
      barNameTemp = app.customBarName;
    }

    // However if we are in edit mode, swap to using that value if it's blank //
    if (barNameTemp === undefined && grid.barEditMode === BAR_EDIT_MODE.EDIT) {
      if (grid.editedBarMetaData?.bar) {
        barNameTemp = grid.editedBarMetaData.bar.name;
      }
    }

    // Apply the same logic to the level //
    if (app.customBarLevel !== undefined) {
      barLevelTemp = app.customBarLevel;
    }

    // However if we are in edit mode, swap to using that value if it's blank //
    if (barLevelTemp === undefined && grid.barEditMode === BAR_EDIT_MODE.EDIT) {
      if (grid.editedBarMetaData?.bar) {
        barLevelTemp = grid.editedBarMetaData.bar.level;
      }
    }

    // If values are still undefined, set them to something nicer //
    if (barNameTemp === undefined) {
      barNameTemp = '';
    }

    if (barLevelTemp === undefined) {
      barLevelTemp = 0;
    }

    return [barNameTemp, barLevelTemp];
  })();

  const handleCreateBar = (addToBay, isGlobal) => {
    const barId = uuidv4();

    const gridSettings = grid.settings[selectedBay];
    const bay = project.projectBays[selectedBay];

    const bar = {
      id: barId,
      width: gridSettings.gridStepX * gridSettings.gridPitch,
      height: GridService.getBarHeight(grid[GRIDS.BAR], grid.data, selectedBay),
      entities: grid[GRIDS.BAR],
      name: barName,
      level: barLevel,
    };

    // Check all products have a y axis of 0 otherwise we won't accept em
    const barErrors = GridService.isBarValid(barName, grid[GRIDS.BAR]);

    if (barErrors.length > 0) {
      setCreateErrors(barErrors);
      return;
    }

    closeSubNavInternal();
    createBarInternal({
      gridId: GRIDS.BAR,
      bar,
      wallbayTypeGuid: bay.wallbayTypeGuid,
      isGlobal,
      addToBay,
      gridSettings,
      gridIndex: selectedBay,
    });
  };

  const handleAddBar = () => {
    const params = {
      gridId: GRIDS.WALLBAY,
      wallbayBarGuid: grid.editedBarGuid,
      gridSettings: grid.settings[selectedBay],
      level: barLevel,
      gridIndex: selectedBay,
    };

    addBarInternal(params);
  };

  const errors = [...createErrors, ...grid.barErrors];

  const resetValues = () => {
    setCustomBarNameInternal(undefined);
    setCustomBarLevelInternal(undefined);
  };

  return (
    <div className="grid-bar">
      <div className="grid-bar__modal" onClick={closeAddBarInternal}>
        <div className="grid-bar__inner" onClick={e => e.stopPropagation()}>
          {
            <Fragment>
              <GridBarHeader
                title={barName}
                setCustomBarNameInternal={setCustomBarNameInternal}
                setCustomBarLevelInternal={setCustomBarLevelInternal}
              />
              {(grid.barEditMode === BAR_EDIT_MODE.ADD || grid.barEditMode === BAR_EDIT_MODE.PREVIEW_EXISTING) && (
                <form className="grid-bar__form">
                  {grid.barEditMode === BAR_EDIT_MODE.ADD && (
                    <Fragment>
                      <div className="form__row">
                        <h4 className="form__title">
                          <Translate resourceString="profile-manager.custombar.create" />
                        </h4>
                      </div>
                    </Fragment>
                  )}
                  <div className="grid-bar__form--split">
                    <div className="grid-bar__form-half">
                      {grid.barEditMode === BAR_EDIT_MODE.ADD && (
                        <div className="form__row">
                          <input
                            id="txtName"
                            type="text"
                            placeholder={t('profile-manager.custombar.nameplaceholder')}
                            value={barName}
                            onChange={e => setCustomBarNameInternal(e.target.value)}
                          ></input>
                        </div>
                      )}
                      {(grid.barEditMode === BAR_EDIT_MODE.ADD || grid.barEditMode === BAR_EDIT_MODE.PREVIEW_EXISTING) && (
                        <div className="form__row">
                          <label className="no-anim dropdown__wrapper">
                            <Translate resourceString="profile-manager.custombar.selectlevel" />
                            <Dropdown onChange={level => setCustomBarLevelInternal(level.target.value)} value={barLevel}>
                              <Option value={0} label={'profile-manager.custombar.level.0'} translateLabel={true} />
                              <Option value={1} label={'profile-manager.custombar.level.1'} translateLabel={true} />
                              <Option value={2} label={'profile-manager.custombar.level.2'} translateLabel={true} />
                            </Dropdown>
                          </label>
                        </div>
                      )}
                    </div>
                    <div className="grid-bar__form-half">
                      <p>
                        <Translate resourceString="profile-manager.custombar.create.description" />
                      </p>
                    </div>
                  </div>
                </form>
              )}

              {grid.barEditMode === BAR_EDIT_MODE.EDIT && (
                <form className="grid-bar__form">
                  <div className="form__row">
                    <h4 className="form__title">
                      <Translate resourceString="profile-manager.custombar.edit" />
                    </h4>
                  </div>
                  <div className="grid-bar__form--split">
                    <div className="grid-bar__form-half">
                      <div className="form__row">
                        <input
                          id="txtName"
                          type="text"
                          placeholder={t('profile-manager.custombar.nameplaceholder')}
                          value={barName}
                          onChange={e => setCustomBarNameInternal(e.target.value)}
                        ></input>
                      </div>
                    </div>
                    <div className="grid-bar__form-half">
                      <div className="form__row">
                        <label className="no-anim dropdown__wrapper">
                          <Translate resourceString="profile-manager.custombar.selectlevel" />
                          <Dropdown onChange={level => setCustomBarLevelInternal(level.target.value)} value={barLevel}>
                            <Option value={0} label={'profile-manager.custombar.level.0'} translateLabel={true} />
                            <Option value={1} label={'profile-manager.custombar.level.1'} translateLabel={true} />
                            <Option value={2} label={'profile-manager.custombar.level.2'} translateLabel={true} />
                          </Dropdown>
                        </label>
                      </div>
                    </div>
                  </div>
                </form>
              )}

              <AbsoluteGrid
                gridId={GRIDS.BAR}
                gridIndex={grid.editedBarMetaData ? grid.editedBarMetaData.gridIndex : selectedBay}
                showScrollBar={true}
              />

              <div className="grid-bar__footer">
                {errors.length > 0 && (
                  <ul className="form-errors">
                    {errors.map(x => (
                      <li key={x}>{x}</li>
                    ))}
                  </ul>
                )}
                {grid.barEditMode === BAR_EDIT_MODE.EDIT && (
                  <div
                    className="btn btn--primary btn--inline"
                    onClick={() => {
                      resetValues();
                      updateBarInternal({ gridId: GRIDS.BAR, name: barName, level: barLevel });
                      closeSubNavInternal();
                    }}
                  >
                    <Translate resourceString="update" />
                  </div>
                )}

                {grid.barEditMode === BAR_EDIT_MODE.PREVIEW_EXISTING && (
                  <div
                    className="btn btn--primary btn--inline"
                    onClick={() => {
                      resetValues();
                      handleAddBar();
                      closeSubNavInternal();
                    }}
                  >
                    <Translate resourceString="add" />
                  </div>
                )}

                {grid.barEditMode === BAR_EDIT_MODE.ADD && (
                  <Fragment>
                    <div
                      className="btn btn--primary btn--inline"
                      onClick={() => {
                        resetValues();
                        handleCreateBar(true, false);
                      }}
                    >
                      <Translate resourceString="profile-manager.custombar.saveandadd" />
                    </div>

                    {isGlobalAdmin && (
                      <div
                        className="btn btn--primary btn--inline"
                        onClick={() => {
                          resetValues();
                          handleCreateBar(false, true);
                        }}
                      >
                        <Translate resourceString="profile-manager.custombar.saveasglobal" />
                      </div>
                    )}
                  </Fragment>
                )}
              </div>
            </Fragment>
          }
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  app: state.app.present.app,
  grid: state.app.present.grid,
  project: state.app.present.project,
});

const mapDispatchToProps = dispatch => ({
  createBarInternal: params => dispatch(createBar(params)),
  updateBarInternal: ({ gridId, name, level }) => dispatch(updateBar({ gridId, name, level })),
  closeAddBarInternal: () => dispatch(closeAddBar()),
  addBarInternal: params => dispatch(addBar(params)),
  setCustomBarNameInternal: params => dispatch(setCustomBarName(params)),
  setCustomBarLevelInternal: params => dispatch(setCustomBarLevel(params)),
  closeSubNavInternal: () => dispatch(closeSubNav()),
});

export default connect(mapStateToProps, mapDispatchToProps)(GridBarModal);
