import React from 'react';

const Loader = () => (
<div className="grid__loader">
    <div className="grid__spinner">
        <div className="spinner">
        </div>
    </div>
</div>
);

export default Loader;
