import { useEffect } from 'react';

/**
 * Hook to register event listeners for key press
 * @param Callbacks
 */
const useKeyDown = ({ onKeyDown, keyCode, shiftMod = false, ctrlMod = false }) => {
  const handleInput = e => {
    if (e.keyCode === keyCode && e.ctrlKey === ctrlMod && e.shiftKey === shiftMod && !e.repeat) {
      e.preventDefault();
      onKeyDown(e);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleInput);

    return () => document.removeEventListener('keydown', handleInput);
  }, []);
};

export default useKeyDown;
