import { t } from 'js/components/translations/index';
import { closeSubNav } from 'js/redux/actioncreators/app';
import React from 'react';
import { connect } from 'react-redux';

const CloseSubnav = ({ closeSubNavInternal, invert = false }) => {
  return (
    <div className="close-subnav" onClick={() => closeSubNavInternal()}>
      <span
        className={`close-subnav__icon ${invert ? 'close-subnav__icon--black' : ''}`}
        title={t('profile-manager.navigation.close')}
      ></span>
    </div>
  );
};

const mapStateToProps = state => ({
  app: state.app.present.app,
});

const mapDispatchToProps = dispatch => ({
  closeSubNavInternal: () => dispatch(closeSubNav()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CloseSubnav);
