import DIRECTIONS from 'js/enums/Directions';
import React, { useRef, createContext, useContext } from 'react';
import useArrowKeys from './useArrowKeys';

const initialValue = [];
const SelectedEntitiesContext = createContext();

export const SelectedEntities = ({ onMove, gridId, children }) => {
  const selectedEntities = useRef([...initialValue]);
  const type = useRef(null);
  const level = useRef(0);
  const gIndex = useRef(0);

  const clearSelectedEntities = () => {
    selectedEntities.current = [...initialValue];
    type.current = null;
  };

  const setSelectedEntitiesFromArray = values => {
    selectedEntities.current = [...values];
  };

  const updateSelectedEntities = (e, value) => {
    if (Array.isArray(value)) {
      setSelectedEntitiesFromArray(value);
      return;
    }

    if (type.current === null) {
      type.current = value.entity.type;
    }

    if (type.current !== value.entity.type) {
      clearSelectedEntities();
    }

    if (level.current !== value.gridLevel) {
      clearSelectedEntities();

      level.current = value.gridLevel;
    }

    if (gIndex.current !== value.gridIndex) {
      clearSelectedEntities();

      gIndex.current = value.gridIndex;
    }

    if (e.ctrlKey === true) {
      if (selectedEntities.current && selectedEntities.current.findIndex(x => x.entity.id === value.entity.id) > -1) {
        // If we already have the value selected, unselect it
        selectedEntities.current = selectedEntities.current.filter(x => x.entity.id !== value.entity.id);
      } else {
        // Otherwise we set the value
        selectedEntities.current = [...selectedEntities.current, value];
      }
    } else if (
      selectedEntities.current &&
      selectedEntities.current.length === 1 &&
      selectedEntities.current.findIndex(x => x.entity.id === value.entity.id) > -1
    ) {
      // If we have one thing selected and its the same value Remove it
      selectedEntities.current = [];
    } else {
      selectedEntities.current = [value];
    }
  };

  // eslint-disable-next-line no-underscore-dangle
  const _onMove = (direction, entities) => {
    if (entities.length === 0) {
      return;
    }

    const { gridIndex, gridLevel: currentLevel } = entities[0];

    onMove({ gridId, ids: entities.map(x => x.entity.id), direction, gridLevel: currentLevel, gridIndex });
  };

  useArrowKeys({
    onLeft: () => _onMove(DIRECTIONS.LEFT, selectedEntities.current),
    onUp: () => _onMove(DIRECTIONS.UP, selectedEntities.current),
    onRight: () => _onMove(DIRECTIONS.RIGHT, selectedEntities.current),
    onDown: () => _onMove(DIRECTIONS.DOWN, selectedEntities.current),
  });

  return (
    <SelectedEntitiesContext.Provider
      value={{ selectedEntities: selectedEntities.current || [...initialValue], updateSelectedEntities, clearSelectedEntities }}
    >
      {children}
    </SelectedEntitiesContext.Provider>
  );
};

const useSelectedEntities = () => {
  const data = useContext(SelectedEntitiesContext);
  return data;
};

export default useSelectedEntities;
