import React from 'react';
import { connect } from 'react-redux';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import Toast from './Toast';

const Toasts = ({ toasts }) => (
  <TransitionGroup className="toast-track">
    {toasts.map(x => (
      <CSSTransition key={x.id} classNames="animated">
        <Toast {...x} />
      </CSSTransition>
    ))}
  </TransitionGroup>
);

const mapStateToProps = state => ({
  toasts: state.app.present.toast,
});

// const mapDispatchToProps = dispatch => ({
// });

export default connect(mapStateToProps, null)(Toasts);
