import React, { useState } from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';
import classNames from 'classnames';
import { searchProducts } from '@redux/actioncreators/products';
import { addArticleNumbers } from '@redux/actioncreators/grid';
import SUB_NAVS from '@enums/SubNavs';
import FILL_MODES from '@enums/FillModes';
import GRIDS from '@enums/Grids';
import BAR_EDIT_MODE from '@enums/BarEditMode';
import { t } from 'js/components/translations/index';
import CloseSubnav from '../buttons/CloseSubnav';

const pattern = /^([0-9]*)$/gm;

const ArticleNumberImport = props => {
  const [articleNumbers, setArticleNumbers] = useState('');
  const [textArea, setTextArea] = useState('');
  const [error, setError] = useState(true);
  const { grid, app, addArticleNumbersInternal } = props;

  if (app.subNav !== SUB_NAVS.ARTICLE_NUMBER_IMPORT) {
    return null;
  }

  const handleOnChange = e => {
    const { value } = e.currentTarget;
    setTextArea(value);

    const result = value.match(pattern);

    const newLines = value.split('\n');
    if (newLines !== null && result !== null && newLines.length === result.length) {
      setError(false);
    } else {
      setError(true);
    }

    setArticleNumbers(result);
  };

  const handleSubmit = () => {
    let gridId = GRIDS.WALLBAY;

    if (grid.barEditMode !== BAR_EDIT_MODE.CLOSED) {
      gridId = GRIDS.BAR;
    }

    const settings = {
      fillDirection: FILL_MODES.ROW,
    };

    const gridSettings = props.grid.settings[app.selectedBay];

    addArticleNumbersInternal({
      articleNumbers,
      gridId,
      settings,
      gridSettings,
      gridIndex: app.selectedBay,
      logResults: true,
    });
  };

  const inputClassNames = classNames({
    'an-import__input': true,
    'is-invalid': error,
  });

  return (
    <div className="sub-nav" onClick={e => e.stopPropagation()}>
      <div className="an-import">
        <div className="an-import__header">
          <h2>{t('profile-manager.import.paste-article-numbers')}</h2>
          <CloseSubnav invert={true} />
        </div>
        <div className="an-import__inner">
          <textarea className={inputClassNames} value={textArea} onChange={handleOnChange} />
        </div>

        <button type="button" className="sub-nav__submit" disabled={error} onClick={handleSubmit}>
          <div className="sub-nav__submit-text">{t('profile-manager.import.add-products')}</div>
        </button>
        <div className="an-import__inner">
          {grid.importResults.success.length > 0 && (
            <p>
              {t('profile-manager.import.add-products.success')}: {grid.importResults.success.length}
            </p>
          )}

          {grid.importResults.errors.length > 0 && (
            <p>
              {t('profile-manager.import.add-products.not-found')}: {grid.importResults.errors.length}
              <ul>
                {grid.importResults.errors.map(x => (
                  <li key={x}>{x}</li>
                ))}
              </ul>
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  products: state.app.present.products,
  app: state.app.present.app,
  grid: state.app.present.grid,
});

const mapDispatchToProps = dispatch => ({
  searchProductsInternal: debounce(params => dispatch(searchProducts(params)), 275),
  addArticleNumbersInternal: params => dispatch(addArticleNumbers(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ArticleNumberImport);
