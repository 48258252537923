import './js/polyfills';
import React from 'react';
import ReactDOM from 'react-dom';
import './scss/main.scss';
import { isIE } from 'js/utils/index';
import LegacyWarning from 'js/components/legacy/LegacyWarning';
import App from './App';
import * as serviceWorker from './serviceWorker';

if (isIE() || window.matchMedia('(max-width: 991px)').matches) {
  ReactDOM.render(<LegacyWarning />, document.getElementById('root'));
} else {
  ReactDOM.render(<App />, document.getElementById('root'));
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
