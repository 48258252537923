import React, { useRef, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import GRIDS from '@enums/Grids';
import { clearNavigation } from '@redux/actioncreators/app';

import Grid from '@components/grid/Grid';

import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import AppInsightsContext from 'js/appInsights/AppInsightsContext';
import { toggleCtrl, toggleShift } from 'js/redux/actioncreators/app';
import useKeyDown from 'js/hooks/useKeyDown';
import useKeyUp from 'js/hooks/useKeyUp';
import isBoltFDSUPanel from 'js/utils/isBoltFDSUPanel';
import isPackoutCompatibleKits from 'js/utils/isPackoutCompatibleKits';

const BuildInner = props => {
  const transformRef = useRef(null);
  const { app, clearNavigationInternal, project } = props;
  const { showGridBackground } = app;

  const isAllBoltPanels = useMemo(() => project.projectBays.every(bay => isBoltFDSUPanel(bay.wallbayTypeGuid)), [project]);
  const isAllPackoutPosPanels = useMemo(() => project.projectBays.every(bay => isPackoutCompatibleKits(bay.wallbayTypeGuid)), [project]);

  useKeyDown({
    onKeyDown: () => {
      props.toggleShiftPressedInternal({ pressed: true });
    },
    shiftMod: true,
    keyCode: 16,
  });

  useKeyUp({
    onKeyUp: () => props.toggleShiftPressedInternal({ pressed: false }),
    shiftMod: true,
    keyCode: 16,
  });

  useKeyDown({
    onKeyDown: () => {
      props.toggleCtrlPressedInternal({ pressed: true });
    },
    ctrlMod: true,
    keyCode: 17,
  });

  useKeyUp({
    onKeyUp: () => {
      props.toggleCtrlPressedInternal({ pressed: false });
    },
    ctrlMod: true,
    keyCode: 17,
  });

  return (
    <TransformWrapper
      ref={transformRef}
      onPanningStart={() => AppInsightsContext.current.trackEvent({ name: 'Started Panning' })}
      onPanningStop={() => AppInsightsContext.current.trackEvent({ name: 'Finished Panning' })}
      onZoomStart={() => AppInsightsContext.current.trackEvent({ name: 'Started Zooming' })}
      onZoomStop={() => AppInsightsContext.current.trackEvent({ name: 'Finished Zooming' })}
      panning={{
        excluded: [
          'wb-cell',
          'wb-cell__inner',
          'cell-bar-header',
          'grid__cells--absolute',
          'wb-cell--placeholder',
          'wb-cell__corners',
          'grid__name',
        ],
        disabled: app.shiftPressed,
      }}
      doubleClick={{
        disabled: app.shiftPressed,
      }}
      limitToBounds={false}
      minScale={0.2}
    >
      {p => {
        return (
          <TransformComponent>
            <div
              className="grids-wrapper"
              data-reduce-space={isAllBoltPanels || isAllPackoutPosPanels}
              onClick={() => clearNavigationInternal()}
            >
              {project.projectBays.map((x, index) => (
                <Grid
                  zoomTransform={p.state}
                  key={index}
                  gridId={GRIDS.WALLBAY}
                  gridIndex={index}
                  showDots={showGridBackground}
                  showHeader
                  showBay
                  showShadow
                />
              ))}
            </div>
          </TransformComponent>
        );
      }}
    </TransformWrapper>
  );
};

const mapStateToProps = state => ({
  app: state.app.present.app,
  grid: state.app.present.grid,
  project: state.app.present.project,
});

const mapDispatchToProps = dispatch => ({
  clearNavigationInternal: () => dispatch(clearNavigation()),
  toggleShiftPressedInternal: params => dispatch(toggleShift(params)),
  toggleCtrlPressedInternal: params => dispatch(toggleCtrl(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BuildInner);
