import React from 'react';
import { Page, Document } from '@react-pdf/renderer';
import GridService from 'js/grid/GridService';
import Grids from 'js/enums/Grids';
import ProductList from './ProductList';
import ProductDetails from './ProductDetails';
import PersonalDetails from './PersonalDetails';
import BayGrid from './BayGrid';
import InventoryPage from './InventoryPage';

const InventoryPdfDocument = ({ store }) => {
  const { grid, app, project } = store.app.present;

  const productsWithQuantities = project.projectBays.map(bay => GridService.getUniqueProductsFromProjectBay(bay)).flat();

  const filteredProducts = GridService.getProductsFromArticleNumbers(
    productsWithQuantities.map(x => x.articleNumber),
    grid.data,
  );

  const t = key => {
    const translated = app.translations[key];

    if (translated) {
      return translated;
    }

    // eslint-disable-next-line no-console
    console.error(`Translation missing in worker thread for ${key}`);
    return key;
  };

  const {
    pdfSettingAddPlanogram: showGrid,
    pdfSettingUseProductImages: showProductImages,
    pdfSettingUseWhiteBorders: showWhiteBorders,
    pdfSettingAddProductDetailPages: showProductDetail,
    pdfSettingAddInventoryPage: showInventoryPage,
  } = project;

  return (
    <Document
      title={`TTI Milwaukee Inventory ${project.customerOrderNumber ?? ''} `}
      creator="TTI Milwaukee"
      producer="TTI Milwaukee"
      language="en"
    >
      <InventoryPage>
        <PersonalDetails details={project} t={t} />
      </InventoryPage>

      {project.projectBays.map((bay, index) => {
        const currentGrid = grid[Grids.WALLBAY][index];

        if (currentGrid === undefined) {
          return null;
        }

        const entities = GridService.getEntitiesFromGrids(currentGrid, Grids.WALLBAY, grid);

        const totalPegs = GridService.getTotalPegs(grid.pegs, entities, grid.data, project.projectBays[index].quantities);

        const bayProducts = GridService.getUniqueProductsFromProjectBay(bay);

        return (
          <React.Fragment key={`${bay.wallbayBayGuid}-grid`}>
            {showInventoryPage && (
              <ProductList t={t} bayNumber={index + 1} bayName={bay.bayName} products={bayProducts} project={project} pegs={totalPegs} />
            )}

            {showGrid && (
              <Page size="A4" key={`${bay.wallbayBayGuid}-grid`}>
                <BayGrid t={t} index={index} showProductImages={showProductImages} showWhiteBorders={showWhiteBorders} store={store} />
              </Page>
            )}
          </React.Fragment>
        );
      })}

      {showProductDetail &&
        filteredProducts.map(product => (
          <InventoryPage key={product.articleNumber}>
            <ProductDetails {...product} />
          </InventoryPage>
        ))}
    </Document>
  );
};

export default InventoryPdfDocument;
