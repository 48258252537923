import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { setHoverProduct } from 'js/redux/actioncreators/grid';
import GridService from 'js/grid/GridService';
import { t } from '../translations/index';

const ProductInfo = ({ grid, setHoverProductInternal }) => {
  const classes = classNames({
    'product-info': true,
    active: grid.hoverProduct != null,
  });

  return (
    <div className={classes}>
      {grid.hoverProduct && (
        <Fragment>
          <div className="product-info__details">
            <div className="product-info__title">{grid.hoverProduct.title}</div>
            <div className="product-info__dimensions">
              <span>
                {t('profile-manager.product-info.width')}: {grid.hoverProduct.width}mm
              </span>
              <span>
                {t('profile-manager.product-info.height')}: {grid.hoverProduct.height}mm
              </span>
            </div>
            <div className="product-info__an">{grid.hoverProduct.subTitle}</div>
          </div>
          {grid.hoverProduct.imageUrl && (
            <div className="product-info__media">
              <img src={`${GridService.formatProductImage(grid.hoverProduct.imageUrl, 100)}`} />
            </div>
          )}
          <span className="product-info__close" onClick={() => setHoverProductInternal(null)}></span>
        </Fragment>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  grid: state.app.present.grid,
});

const mapDispatchToProps = dispatch => ({
  setHoverProductInternal: params => dispatch(setHoverProduct(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductInfo);
