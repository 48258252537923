export default {
  UP: 'UP',
  RIGHT: 'RIGHT',
  DOWN: 'DOWN',
  LEFT: 'LEFT',
  UP_RIGHT: 'UP_RIGHT',
  DOWN_RIGHT: 'DOWN_RIGHT',
  DOWN_LEFT: 'DOWN_LEFT',
  UP_LEFT: 'UP_LEFT',
};
