import React, { Fragment } from 'react';
import { View, Text } from '@react-pdf/renderer';
import chunk from 'lodash/chunk';

import Item from './Item';
import styles from './styles';
import InventoryPage from './InventoryPage';

const Products = ({ bayName, bayNumber, products, project, pegs, t }) => {
  const chunks = chunk(products, 23);

  /** Ideally we could use the fixed and wrap props from react-pdf to handle the pagination,
   * but they don't seem to be working. Possibly buggy, judging by some github issues.
   * So I'm doing it here, as we already imported Lodash :) */

  return (
    <>
      {chunks.map((chunkOfProducts, index) => (
        <InventoryPage key={index}>
          <View style={styles.section}>
            <Text style={styles.heading}>
              {bayName} ({bayNumber})
            </Text>
            <Text style={styles.heading}>{t('products')}</Text>

            {chunkOfProducts.map(product => {
              const quantityValue =
                project.projectBays[index].quantities && project.projectBays[index].quantities[product.articleNumber]
                  ? project.projectBays[index].quantities[product.articleNumber]
                  : undefined;

              return <Item key={product.articleNumber} {...product} quantity={quantityValue} />;
            })}

            {pegs && pegs.length > 0 && chunks.length - 1 === index ? (
              <Fragment>
                <Text style={styles.heading}>{t('profile-manager.output.pegtotals')}</Text>

                {pegs.map((x, i) => (
                  <Text key={i}>
                    {x.title} - {x.quantity}
                  </Text>
                ))}
              </Fragment>
            ) : null}
          </View>
        </InventoryPage>
      ))}
    </>
  );
};
export default Products;
