import { useEffect } from 'react';

const ARROW_KEYS = {
  LEFT: 37,
  UP: 38,
  RIGHT: 39,
  DOWN: 40,
};

/**
 * Hook to register event listeners for arrow keys
 * @param Callbacks
 */
const useArrowKeys = ({ onLeft, onUp, onRight, onDown }) => {
  const handleInput = e => {
    switch (e.keyCode) {
      case ARROW_KEYS.LEFT: {
        if (onLeft) {
          e.preventDefault();
          onLeft(e);
        }
        break;
      }

      case ARROW_KEYS.UP: {
        if (onUp) {
          e.preventDefault();
          onUp(e);
        }
        break;
      }

      case ARROW_KEYS.RIGHT: {
        if (onRight) {
          e.preventDefault();
          onRight(e);
        }
        break;
      }

      case ARROW_KEYS.DOWN: {
        if (onDown) {
          e.preventDefault();
          onDown(e);
        }
        break;
      }

      default: {
        break;
      }
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleInput);

    return () => document.removeEventListener('keydown', handleInput);
  }, []);
};

export default useArrowKeys;
