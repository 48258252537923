import React, { Fragment, useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import ApiService from '@js/api/ApiService';
import { addNewBay } from '@redux/actioncreators/grid';
import isBoltFDSUPanel from 'js/utils/isBoltFDSUPanel';
import isPackoutCompatibleKits from 'js/utils/isPackoutCompatibleKits';
import ProjectTemplateList from '../projects/ProjectTemplateList';
import ProjectTypeList from '../projects/ProjectTypeList';
import TemplateBayList from '../projects/TemplateBayList';
import { t, Translate } from '../translations/index';
import Loader from '../shared/Loader';

const AddBayOptions = ({ _addNewBay, project, onComplete }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [templates, setTemplates] = useState([]);
  const [template, setTemplate] = useState(null);
  const [detailedTemplate, setDetailedTemplate] = useState(null);
  const [bayName, setBayName] = useState(t('profile-manager.bay.defaultname'));

  useEffect(() => {
    async function fetchTemplates() {
      setIsLoading(true);

      const response = await ApiService.request({
        slug: 'get-templates',
        verb: 'GET',
        params: {
          userGuid: '',
        },
      });

      setTemplates(response.templates);
      setIsLoading(false);
    }

    fetchTemplates();
  }, []);

  useEffect(() => {
    if (template === null || template === 'EMPTY') {
      return;
    }

    async function fetchTemplate() {
      setIsLoading(true);

      const response = await ApiService.request({
        slug: 'get-template',
        verb: 'GET',
        params: {
          userGuid: '',
          templateGuid: template.templateGuid,
        },
      });

      setDetailedTemplate(response.template);
      setIsLoading(false);
    }

    fetchTemplate();
  }, [template]);

  const handleAddEmptyBay = type => {
    const isFDSUPanel = isBoltFDSUPanel(type);
    const isPackout = isPackoutCompatibleKits(type);
    const newBays = [];

    let iteratorLimit = 1;
    if (isFDSUPanel || isPackout) {
      iteratorLimit = 4;
    }

    for (let index = 0; index < iteratorLimit; index += 1) {
      newBays.push({
        data: '',
        wallbayProjectGuid: project.projectGuid,
        wallbayTypeGuid: type,
        bayName,
      });
    }

    _addNewBay(
      {
        wallbayProjectGuid: project.projectGuid,
        bays: [...newBays],
      },
      true,
    );

    onComplete();
  };

  const handleAddBay = index => {
    const bay = detailedTemplate.bays[index];

    const params = {
      wallbayProjectGuid: project.projectGuid,
      bays: [
        {
          data: JSON.parse(bay.jsonData),
          wallbayProjectGuid: project.projectGuid,
          wallbayTypeGuid: bay.wallbayTypeGuid,
          bayName: bay.bayName ? bay.bayName : t('profile-manager.bay.defaultname'),
        },
      ],
    };

    _addNewBay(params, true);

    onComplete();
  };

  const handleAddAllBays = () => {
    const bayParams = detailedTemplate.bays.map(x => ({
      data: JSON.parse(x.jsonData),
      wallbayProjectGuid: project.projectGuid,
      wallbayTypeGuid: x.wallbayTypeGuid,
      bayName: x.bayName ? x.bayName : t('profile-manager.bay.defaultname'),
    }));

    _addNewBay(
      {
        wallbayProjectGuid: project.projectGuid,
        bays: bayParams,
      },
      true,
    );
    onComplete();
  };

  if (isLoading) {
    return (
      <form className="no-padding loader-container">
        <Loader />
      </form>
    );
  }

  return (
    <Fragment>
      <form className="no-padding max-300">
        <div className="form__row">
          <h4 className="form__title form__title--left">
            <Translate resourceString="profile-manager.bay.title" />
          </h4>
        </div>
        {template === 'EMPTY' && (
          <div className="form__row">
            <input id="txtName" type="text" value={bayName} onChange={e => setBayName(e.currentTarget.value)}></input>
            <label htmlFor="txtName">
              <Translate resourceString="profile-manager.bay.entername" />:
            </label>
          </div>
        )}
      </form>
      {!template && (
        <ProjectTemplateList
          templates={templates}
          onTemplateClick={templateGuid => setTemplate(templates.find(x => x.templateGuid === templateGuid))}
          onCreateEmptyTemplateClick={() => setTemplate('EMPTY')}
        />
      )}

      {template === 'EMPTY' && (
        <ProjectTypeList
          listCustom={false}
          onTypeClick={typeGuid => handleAddEmptyBay(typeGuid)}
          onRemoveClick={e => console.log(e)}
          onCreateCustomClick={e => console.log(e)}
        />
      )}

      {template && detailedTemplate && (
        <TemplateBayList bays={detailedTemplate.bays} onTemplateBayClick={handleAddBay} onAddAllBaysClick={handleAddAllBays} />
      )}
    </Fragment>
  );
};

const mapStateToProps = state => ({
  grid: state.app.present.grid,
  project: state.app.present.project,
});

const mapDispatchToProps = dispatch => ({
  _addNewBay: (params, shouldFetchProject) => dispatch(addNewBay(params, shouldFetchProject)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddBayOptions);
