import React, { useState, useRef, useMemo } from 'react';
import { connect } from 'react-redux';
import ContentEditable from 'react-contenteditable';
import debounce from 'lodash/debounce';
import { removeBay } from '@redux/actioncreators/grid';
import { updateBarName } from 'js/redux/actioncreators/project';
import isBoltFDSUPanel from 'js/utils/isBoltFDSUPanel';
import isPackoutCompatibleKits from 'js/utils/isPackoutCompatibleKits';

const GridName = ({ project, gridIndex, _updateBarName, _removeBay }) => {
  const [bayName, setBayName] = useState(project.projectBays[gridIndex].bayName);
  const isBoltPanel = useMemo(() => isBoltFDSUPanel(project.projectBays[gridIndex].wallbayTypeGuid), [project.projectBays, gridIndex]);
  const isPackoutPos = useMemo(() => isPackoutCompatibleKits(project.projectBays[gridIndex].wallbayTypeGuid), [
    project.projectBays,
    gridIndex,
  ]);
  const ref = useRef(null);

  const debouncedUpdateProject = useRef(
    debounce(params => _updateBarName(params), 375),
    [bayName],
  );

  const handleNameChange = e => {
    setBayName(e.target.value);
    debouncedUpdateProject.current({ bayName: e.target.value, gridIndex });
  };

  const handleRemoveBay = (e, data, target) => {
    const params = {
      bayGuid: project.projectBays[gridIndex].wallbayBayGuid,
      wallbayProjectGuid: project.projectGuid,
    };

    _removeBay(params);
  };

  return (
    <div className="grid__name-wrapper">
      <ContentEditable innerRef={ref} html={bayName} disabled={false} onChange={handleNameChange} className="grid__name"></ContentEditable>
      {!isBoltPanel && !isPackoutPos && <div className="grid__delete" onClick={handleRemoveBay}></div>}
    </div>
  );
};

const mapStateToProps = state => ({
  project: state.app.present.project,
});

const mapDispatchToProps = dispatch => ({
  _updateBarName: params => dispatch(updateBarName(params)),
  _removeBay: params => dispatch(removeBay(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GridName);
