import { View, Page } from '@react-pdf/renderer';
import React from 'react';
import Header from './Header';
import styles from './styles';

const InventoryPage = ({ children }) => (
  <Page size="A4" style={styles.page}>
    <View style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Header />
      <View style={{ flexGrow: 1 }}>{children}</View>
    </View>
  </Page>
);

export default InventoryPage;
