import React, { useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import Cell from '@components/grid/Cell';
// import GridBarHeader from '@components/grid/GridBarHeader';

const AbsoluteGrid = props => {
  const { app, grid, bar } = props;

  // Setup
  // const containerRef = useRef(null);

  // React hooks
  useEffect(() => {
    // AbsoluteGridService.setAbsoluteCssVariables(containerRef.current, settings);
  }, []);

  const renderCell = (cell, index) => {
    const product = grid.data.find(x => x.articleNumber === cell.entity.articleNumber);

    return (
      <Cell
        key={cell.entity.id}
        index={index}
        entity={cell.entity}
        product={product}
        placeholder={cell.placeholder}
        selectedEntities={[]}
        clearSelectedEntities={() => {}}
        onClick={() => {}}
        onRightClick={() => {}}
        showProductImages={app.showProductImages}
        isAbsolute={true}
        noBg={app.showBackgrounds === false}
      />
    );
  };

  const cellClassNames = classNames({
    grid__cells: true,
    'grid__cells--absolute': true,
  });

  return (
    <Fragment>
      {bar.entities.length > 0 && <div className={cellClassNames}>{bar.entities.map((x, index) => renderCell(x, index))}</div>}
    </Fragment>
  );
};

const mapStateToProps = state => ({
  app: state.app.present.app,
  grid: state.app.present.grid,
});

export default connect(mapStateToProps, null)(AbsoluteGrid);
