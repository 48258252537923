import React, { useState, Fragment } from 'react';
import { connect } from 'react-redux';

import { Translate } from '@components/translations';
import Tooltip from '@components/navigation/Tooltip';
import { setAppCulture } from '@redux/actioncreators/app';
import { t } from 'js/components/translations/index';

const LanguageSelector = ({ app, setAppCultureInternal }) => {
  const [active, setIsActive] = useState(false);

  const handleCultureClick = cultureCode => {
    setAppCultureInternal({ cultureCode });
    setIsActive(false);
  };

  return (
    <Fragment>
      <div className="navigation__flag" onClick={() => setIsActive(!active)}>
        <button type="button" className={`flag ${app.uiCultureCode}`}><p>{t('profile-manager.selectlanguage')}</p></button>
      </div>

      {active && (
        <div className="language-selector">
          <div className="language-overlay" onClick={() => setIsActive(!active)}></div>
          <div className="language-grid">
            <div className="language-grid-inner">
              {app.cultures.map(x => (
                <div key={x.cultureCode} className="language-grid-item" onClick={() => handleCultureClick(x.cultureCode)}>
                  <span className={`flag ${x.cultureCode}`}></span>
                  <span className="flag-title">
                    <Translate resourceString={x.resourceString} />
                  </span>
                </div>
              ))}
              <div className="language-grid-close" onClick={() => setIsActive(false)}></div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

const mapStateToProps = state => ({
  app: state.app.present.app,
});

const mapDispatchToProps = dispatch => ({
  setAppCultureInternal: ({ cultureCode }) => dispatch(setAppCulture({ cultureCode })),
});

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSelector);
