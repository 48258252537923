import store from '@redux/store';
import keys from './keys';
import translate from './Translate';

function t(resourceString) {
  if (keys.find(x => x.toLowerCase() === resourceString.toLowerCase()) === undefined) {
    // console.warn(`${resourceString} missing in keys.js`);
    return resourceString;
  }

  const { translations } = store.getState().app.present.app;

  // Check keys.js to see if we have added the key.
  if (keys.findIndex(x => x.toLowerCase() === resourceString.toLowerCase()) === -1) {
    // console.warn(`${resourceString} missing in keys.js`);
    return resourceString;
  }

  // Try and lookup the value in the translations
  let value = translations[resourceString];

  // Fall back to the resource string if no value
  if (!value) {
    // console.warn(`Translation missing for ${resourceString}`);
    value = resourceString;
  }

  // Return the translated value
  return value;
}

export { translate as Translate };
export { t };
