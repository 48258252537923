export const KEYS = {
  USER_GUID: process.env.REACT_APP_USER_KEY,
  UI_CULTURE: 'UI_CULTURE',
};

class LocalStorage {
  /**
   * Gets a value using the given key from local storage
   * @param {string} key
   * @returns undefined if not found
   */
  get(key) {
    const value = localStorage.getItem(key);

    if (value === null || value === undefined) {
      return undefined;
    }

    return JSON.parse(value);
  }

  /**
   * Sets a value in local storage.
   * @param {string} key
   * @param {*} value
   */
  set(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
  }
}

export default new LocalStorage();
