import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { t } from '@components/translations';
import { setSelectedBay } from '@redux/actioncreators/app';
import BarEditMode from 'js/enums/BarEditMode';
import Loader from 'js/components/shared/Loader';

const BaySelector = props => {
  const { app, project, grid, setSelectedBayInternal } = props;
  const isBarModalOpen = grid.barEditMode === BarEditMode.ADD || grid.barEditMode === BarEditMode.EDIT;

  useEffect(() => {
    if (project.projectBays.length > 1) {
      return;
    }

    setSelectedBayInternal(0);
  }, []);

  return (
    <React.Fragment>
      <div className="nav-list">
        {project.projectBays.length > 1 && app.selectedBay === -1 && grid.barEditMode === BarEditMode.CLOSED ? (
          <ul>
            {project.projectBays.map((x, index) => (
              <li key={index} className="has-icon" role="presentation" onClick={() => setSelectedBayInternal(index)}>
                {index + 1}. {x.bayName}
                <span className="icon icon--inline icon--chevron" />
              </li>
            ))}
          </ul>
        ) : (
          <Fragment>
            <ul>
              {props.children}
              {!isBarModalOpen && project.projectBays.length > 1 && (
                <li className="has-icon" role="presentation" onClick={() => setSelectedBayInternal(-1)}>
                  <span className="icon icon--back" />
                  <Fragment>{`${t('profile-manager.back')}`}</Fragment>
                </li>
              )}
            </ul>
          </Fragment>
        )}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  grid: state.app.present.grid,
  app: state.app.present.app,
  project: state.app.present.project,
});

const mapDispatchToProps = dispatch => ({
  setSelectedBayInternal: index => dispatch(setSelectedBay(index)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BaySelector);
