import { push } from 'connected-react-router';
import ACTIONS from '@redux/actions';
import ApiService from '@js/api/ApiService';
// import GridService from '@js/grid/GridService';
import TOAST_TYPES from '@enums/ToastTypes';
import { addToast } from '@redux/actioncreators/toast';
import { populate } from '@redux/actioncreators/grid';
import GRIDS from '@enums/Grids';
import { SAVES } from '@js/data';
import { t } from '@components/translations';
import isBoltFDSUPanel from 'js/utils/isBoltFDSUPanel';
import isPackoutCompatibleKits from 'js/utils/isPackoutCompatibleKits';

export const fetchProject = (projectGuid, test = false) => async dispatch => {
  dispatch({ type: ACTIONS.PROJECT.FETCH_PROJECT, payload: { projectGuid } });

  const response = await ApiService.request({ slug: 'get-project', verb: 'GET', params: { projectGuid } });

  if (test) {
    dispatch({
      type: ACTIONS.PROJECT.SET_PROJECT_TEST,
      payload: response,
    });
  } else {
    dispatch(setProject(response));
  }

  return dispatch(populate({ gridId: GRIDS.WALLBAY }));
};

export const fetchProjects = params => async dispatch => {
  dispatch({ type: ACTIONS.PROJECT.FETCH_PROJECTS });

  try {
    const projects = await ApiService.request({ slug: 'get-user-projects', verb: 'GET', params });

    dispatch({
      type: ACTIONS.PROJECT.FETCH_PROJECTS_SUCCESS,
      payload: projects,
    });
  } catch (error) {
    dispatch({
      type: ACTIONS.PROJECT.FETCH_PROJECTS_ERROR,
      payload: error,
    });
  }
};

export const createProjectFromTemplate = ({ projectName, projectTemplateGuid, projectUserGuid, redirect }) => async dispatch => {
  let newProjectName = projectName;
  dispatch({ type: ACTIONS.PROJECT.FETCH_CREATE_PROJECT_FROM_TEMPLATE });

  if (newProjectName === undefined) {
    newProjectName = 'New Project';
  }

  try {
    const response = await ApiService.request({
      slug: 'create-project-from-template',
      verb: 'POST',
      params: { projectName: newProjectName, projectUserGuid, projectTemplateGuid },
    });

    dispatch({
      type: ACTIONS.PROJECT.FETCH_CREATE_PROJECT_FROM_TEMPLATE_SUCCESS,
      payload: { ...response },
    });

    if (redirect === true) {
      dispatch(push(`/build/${response.projectGuid}`));
    }
  } catch (error) {
    dispatch({
      type: ACTIONS.PROJECT.FETCH_CREATE_PROJECT_FROM_TEMPLATE_ERROR,
      payload: error,
    });
    dispatch(clearProject());
  }
};

export const createProject = ({ projectName, projectTypeGuid, projectUserGuid, redirect }) => async dispatch => {
  let newProjectName = projectName;
  dispatch({ type: ACTIONS.PROJECT.FETCH_CREATE_PROJECT });

  if (newProjectName === undefined) {
    newProjectName = 'New Project';
  }

  try {
    const createProjectResponse = await ApiService.request({
      slug: 'create-project',
      verb: 'POST',
      params: { projectName: newProjectName, projectUserGuid, wallbayTypeGuid: projectTypeGuid },
    });

    let defaultBays = 1;
    if (isBoltFDSUPanel(projectTypeGuid) || isPackoutCompatibleKits(projectTypeGuid)) {
      // Bolt FDSU Panels - create 4 bays by default //
      // PACKOUT Compatible Kits POS - create 4 bays by default (but different 2 lots of sizes) //
      defaultBays = 4;
    }

    const bayParams = [];
    for (let i = 0; i < defaultBays; i += 1) {
      bayParams.push({
        wallbayProjectGuid: createProjectResponse.projectGuid,
        wallbayTypeGuid: projectTypeGuid,
        bayName: t('profile-manager.bay.defaultname'),
        jsonData: JSON.stringify(SAVES.BAY),
      });
    }

    await ApiService.request({
      slug: 'create-bay',
      verb: 'POST',
      params: [...bayParams],
    });

    dispatch({
      type: ACTIONS.PROJECT.FETCH_CREATE_PROJECT_SUCCESS,
      payload: { ...createProjectResponse },
    });

    if (redirect === true) {
      dispatch(push(`/build/${createProjectResponse.projectGuid}`));
    }
  } catch (error) {
    dispatch({
      type: ACTIONS.PROJECT.FETCH_CREATE_PROJECT_ERROR,
      payload: error,
    });
    dispatch(clearProject());
  }
};

export const deleteProject = ({ userGuid, projectGuid }) => async dispatch => {
  dispatch({ type: ACTIONS.PROJECT.FETCH_DELETE_PROJECT });

  try {
    await ApiService.request({ slug: 'delete-project', verb: 'DELETE', params: { projectGuid } });

    dispatch({
      type: ACTIONS.PROJECT.FETCH_DELETE_PROJECT_SUCCESS,
    });

    return dispatch(fetchProjects({ userGuid }));
  } catch (error) {
    return dispatch({
      type: ACTIONS.PROJECT.FETCH_DELETE_PROJECT_ERROR,
      payload: error,
    });
  }
};

export const fetchProjectTypes = ({ userGuid }) => async dispatch => {
  dispatch({ type: ACTIONS.PROJECT.FETCH_PROJECT_TYPES });

  try {
    const response = await ApiService.request({ slug: 'get-project-types', verb: 'GET', params: { userGuid } });

    return dispatch({
      type: ACTIONS.PROJECT.FETCH_PROJECT_TYPES_SUCCESS,
      payload: response,
    });
  } catch (error) {
    return dispatch({
      type: ACTIONS.PROJECT.FETCH_PROJECT_TYPES_ERROR,
      payload: error,
    });
  }
};

export const deleteProjectType = ({ userGuid, projectTypeGuid, reFetch }) => async dispatch => {
  dispatch({ type: ACTIONS.PROJECT.DELETE_PROJECT_TYPE });

  try {
    await ApiService.request({
      slug: 'delete-project-type',
      verb: 'DELETE',
      params: { projectTypeGuid },
    });

    dispatch({ type: ACTIONS.PROJECT.DELETE_PROJECT_TYPE_SUCCESS });

    if (reFetch) {
      dispatch(fetchProjectTypes({ userGuid }));
    }
  } catch (error) {
    dispatch({ type: ACTIONS.PROJECT.DELETE_PROJECT_TYPE_ERROR });
  }
};

export const createProjectType = ({ userGuid, displayName, height, width }) => async dispatch => {
  dispatch({ type: ACTIONS.PROJECT.CREATE_PROJECT_TYPE });

  try {
    // Create a type
    const response = await ApiService.request({
      slug: 'create-project-type',
      verb: 'POST',
      params: {
        userGuid,
        displayName,
        height,
        width,
      },
    });

    dispatch({
      type: ACTIONS.PROJECT.CREATE_PROJECT_TYPE_SUCCESS,
      payload: response,
    });

    // Use the GUID from the response to create a new project using that type
    const createProjectParams = {
      projectUserGuid: userGuid,
      projectTypeGuid: response.projectTypeGuid,
      redirect: true,
    };
    return dispatch(createProject(createProjectParams));
  } catch (error) {
    return dispatch({
      type: ACTIONS.PROJECT.CREATE_PROJECT_TYPE_ERROR,
      payload: error,
    });
  }
};

export const createProjectTemplate = ({ userGuid, templateName, templateDescription }) => async (dispatch, getState) => {
  dispatch({ type: ACTIONS.PROJECT.CREATE_PROJECT_TEMPLATE });

  const state = getState().app.present;
  const { project } = state;
  const bays = [];

  for (let index = 0; index < project.projectBays.length; index += 1) {
    const bay = project.projectBays[index];
    bays.push({
      jsonData: bay.jsonData,
      wallbayTypeGuid: bay.wallbayTypeGuid,
      bayName: bay.bayName,
    });
  }

  const params = {
    userGuid,
    templateName,
    templateDescription,
    bays,
  };

  try {
    const response = await ApiService.request({
      slug: 'create-project-template',
      verb: 'POST',
      params,
    });

    dispatch({ type: ACTIONS.PROJECT.CREATE_PROJECT_TEMPLATE_SUCCESS, payload: response });
    dispatch(addToast('Project Template Created', { type: TOAST_TYPES.SUCCESS }));
  } catch (error) {
    dispatch({ type: ACTIONS.PROJECT.CREATE_PROJECT_TEMPLATE_ERROR, payload: error });
    dispatch(addToast('Project Template Create Error', { type: TOAST_TYPES.ERROR }));
  }
};

export const fetchProjectTemplates = ({ projectTypeGuid, userGuid }) => async dispatch => {
  dispatch({ type: ACTIONS.PROJECT.FETCH_TEMPLATES_FOR_TYPE });

  try {
    const response = await ApiService.request({
      slug: 'get-project-templates-for-type',
      verb: 'GET',
      params: { userGuid, projectTypeGuid },
    });

    dispatch({ type: ACTIONS.PROJECT.FETCH_TEMPLATES_FOR_TYPE_SUCCESS, payload: response });
  } catch (error) {
    dispatch({ type: ACTIONS.PROJECT.FETCH_TEMPLATES_FOR_TYPE_ERROR, payload: error });
    dispatch(addToast('Project Template Fetch Error', { type: TOAST_TYPES.ERROR }));
  }
};

export function setProject(project) {
  return {
    type: ACTIONS.PROJECT.SET_PROJECT,
    payload: project,
  };
}

export function clearProject() {
  return {
    type: ACTIONS.PROJECT.SET_PROJECT,
    payload: { project: undefined },
  };
}

export function setProjectName({ name }) {
  return {
    type: ACTIONS.PROJECT.SET_NAME,
    payload: { name },
  };
}

export function setProjectField(params) {
  return {
    type: ACTIONS.PROJECT.SET_FIELD,
    payload: params,
  };
}

export const fetchSimplePdf = params => async dispatch => {
  dispatch({ type: ACTIONS.PROJECT.FETCH_PDF });

  try {
    const response = await ApiService.request({
      slug: 'generate-simple-output-pdf',
      verb: 'POST',
      params,
    });

    dispatch({
      type: ACTIONS.PROJECT.FETCH_PDF_SUCCESS,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: ACTIONS.PROJECT.FETCH_PDF_ERROR,
      payload: error,
    });
  }
};

export function updateProductQuantity(params) {
  return {
    type: ACTIONS.PROJECT.UPDATE_QUANTITY,
    payload: params,
  };
}

export function setPdfOption(params) {
  return {
    type: ACTIONS.PROJECT.SET_PDF_OPTION,
    payload: params,
  };
}

export function updateBarName(params) {
  return {
    type: ACTIONS.PROJECT.UPDATE_BAR_NAME,
    payload: params,
  };
}
