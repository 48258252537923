import GridService from 'js/grid/GridService';
import loop from 'js/utils/loop';
import React from 'react';
import GRIDS from '@enums/Grids';
import { View, Text } from '@react-pdf/renderer';
import styles, { brand } from './styles';
import DotSvg from './Dot';
import Logo from './Logo';
import Entity from './Entity/Entity';

const BayGrid = ({ index, store, showProductImages, showWhiteBorders }) => {
  const { grid, project } = store.app.present;

  if (grid[GRIDS.WALLBAY].length === 0) {
    return null;
  }
  const productsLookup = articleNumber => {
    /**
     * So, I wouldn't _normally_ use == instead of === BUT
     * It appears that sometimes articleNumber comes through as a string
     * and sometimes as a number in the redux store.
     *
     * That's definitely something to change if we rebuild this.
     * But for now, let's disable the linting on this rule :P
     *  */

    /* eslint-disable-next-line eqeqeq */
    return grid.data.find(product => product.articleNumber == articleNumber);
  };

  const { gridPitch } = grid.settings[0];
  const { wallbayHeight, wallbayWidth } = project.projectBays[index].wallbayType;
  const maxSizeInPt = 595 - 60; // 595 is A4 in pt

  const xPegs = Math.ceil(wallbayWidth / gridPitch);
  const yPegs = Math.ceil(wallbayHeight / gridPitch);
  const pegSizeInPt = maxSizeInPt / Math.max(xPegs, yPegs);

  const entities = GridService.getEntitiesFromGrids(grid[GRIDS.WALLBAY][index], GRIDS.WALLBAY, grid).flat();

  return (
    <Bay xPegs={xPegs} yPegs={yPegs} pegSizeInPt={pegSizeInPt} isThin={wallbayWidth <= 300}>
      {entities.map(entity => (
        <Entity
          entity={entity}
          gridPitch={gridPitch}
          index={index}
          key={entity.entity.id}
          pegSizeInPt={pegSizeInPt}
          productsLookup={productsLookup}
          showProductImages={showProductImages}
          showWhiteBorders={showWhiteBorders}
        />
      ))}
    </Bay>
  );
};

function Bay({ children, xPegs, yPegs, pegSizeInPt, isThin }) {
  return (
    <View
      style={{
        backgroundColor: brand.darkRed,
        border: `3 solid ${brand.reallyDarkRed}`,
        borderRadius: 3,
        margin: 'auto',
      }}
    >
      <View
        style={{
          width: xPegs * pegSizeInPt,
        }}
      >
        <View style={{ borderBottom: `3 solid ${brand.reallyDarkRed}` }}>
          <Logo style={styles.centeredLogo} />
        </View>
        <View
          style={{
            position: 'relative',
            height: yPegs * pegSizeInPt,
          }}
        >
          {loop(yPegs).map(y =>
            loop(xPegs).map(x => {
              return <DotSvg x={x} y={y} pegSizeInPt={pegSizeInPt} key={`(${x},${y})`} />;
            }),
          )}
          {children}
        </View>
        <View
          style={{
            color: 'white',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            fontSize: 12,
            paddingTop: 21,
            paddingBottom: 21,

            borderTop: `3 solid ${brand.reallyDarkRed}`,
          }}
        >
          {!isThin && (
            <>
              <Text style={{ fontFamily: 'helvetica53', marginRight: 5 }}>Nothing but</Text>
              <Text style={{ fontFamily: 'helvetica93' }}>HEAVY DUTY.</Text>
              <Text style={{ fontFamily: 'helvetica53' }}>™</Text>
            </>
          )}
        </View>
      </View>
    </View>
  );
}

export default BayGrid;
