import debounce from 'lodash/debounce';
import ACTIONS from '@redux/actions';
import ApiService from '@js/api/ApiService';
import GridService from '@js/grid/GridService';
import Grids from 'js/enums/Grids';

// Whitelist of actions which will trigger an autosave.
const WHITELIST = [
  ACTIONS.GRID.ADD,
  ACTIONS.GRID.REMOVE,
  ACTIONS.GRID.ADD_BAR,
  ACTIONS.GRID.UPDATE_BAR,
  ACTIONS.GRID.MOVE_PRODUCT_POSITION,
  ACTIONS.GRID.UPDATE_POSITION,
  ACTIONS.GRID.UPDATE_MULTIPLE_POSITIONS,
  ACTIONS.GRID.ADD_BAY,
  ACTIONS.PROJECT.UPDATE_QUANTITY,
  ACTIONS.PROJECT.SET_NAME,
  ACTIONS.PROJECT.SET_FIELD,
  ACTIONS.PROJECT.SET_PDF_OPTION,
  ACTIONS.PROJECT.UPDATE_BAR_NAME,
  ACTIONS.HISTORY.UNDO,
  ACTIONS.HISTORY.REDO,
];

const autoSave = async (state, next) => {
  const { grid, project } = state.app.present;

  next({ type: ACTIONS.APP.SAVE_FETCH });

  // Get the populated grids
  const populatedGrids = GridService.getGridsFromEntities(grid);

  if (project.projectGuid === undefined) {
    return;
  }

  const baysToUpdate = [];

  // Populate the json data from the grid
  for (let index = 0; index < project.projectBays.length; index += 1) {
    // Get the bay
    const bay = project.projectBays[index];

    // Populate the jsonData
    bay.jsonData = JSON.stringify(populatedGrids[Grids.WALLBAY][index]);

    // populate the bays to update
    baysToUpdate[index] = {
      ...bay,
    };
  }

  // Request model
  const params = {
    projectData: {
      projectGuid: project.projectGuid,
      projectUserGuid: project.projectUserGuid,
      projectName: project.projectName,
      createdWhen: project.createdWhen,
      modifiedWhen: project.modifiedWhen,
      createdWhenFormatted: project.createdWhenFormatted,
      modifiedWhenFormatted: project.modifiedWhenFormatted,

      customerAddressLine1: project.customerAddressLine1,
      customerAddressLine2: project.customerAddressLine2,
      customerEmailAddress: project.customerEmailAddress,
      customerFirstName: project.customerFirstName,
      customerLastName: project.customerLastName,
      customerNotes: project.customerNotes,
      customerOrderNumber: project.customerOrderNumber,
      customerPostcode: project.customerPostcode,
      customerTownCity: project.customerTownCity,
      customerQuantities: project.customerQuantities,

      pdfSettingAddPlanogram: project.pdfSettingAddPlanogram,
      pdfSettingUseProductImages: project.pdfSettingUseProductImages,
      pdfSettingUseWhiteBorders: project.pdfSettingUseWhiteBorders,
      pdfSettingAddProductDetailPages: project.pdfSettingAddProductDetailPages,
      pdfSettingAddInventoryPage: project.pdfSettingAddInventoryPage,

      projectBays: [...baysToUpdate],
    },
  };

  try {
    await ApiService.request({ slug: 'update-project', verb: 'PUT', params });

    next({
      type: ACTIONS.APP.SAVE,
    });
  } catch (error) {
    next({
      type: ACTIONS.APP.SAVE_FETCH_ERROR,
      payload: error,
    });
  }
};

const autoSaveDebounce = debounce(autoSave, 400, {
  maxWait: 5000,
});

const autoSaveMiddleware = store => next => action => {
  next(action);

  if (WHITELIST.indexOf(action.type) === -1) {
    return;
  }

  const state = { ...store.getState() };
  const { router, app } = state;

  if (router.location.pathname.indexOf('/build/') === -1) {
    return;
  }

  // If it's not dirty, we want to set it
  if (app.present.app.isDirty === false) {
    next({
      type: ACTIONS.APP.IS_DIRTY,
    });
  }

  autoSaveDebounce(state, next);
};

export default autoSaveMiddleware;
