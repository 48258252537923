import React from 'react';
import { Translate } from '@components/translations';

const ProjectList = ({ projects, onProjectClick, onCreateProjectClick, showRemove, onRemoveClick }) => (
  <div className="project-list">
    {projects.map(x => (
      <button type="button" key={x.projectGuid} className="project-item" onClick={e => onProjectClick(e, { projectGuid: x.projectGuid })}>
        <div className="project-item__img-wrapper">
          <div className="project-item__placeholder"></div>
        </div>

        <div className="project-item__details">
          <h3 className="project-item__title">{x.projectName}</h3>
          <p className="project-item__date">
            <Translate resourceString="lastedited" /> {x.modifiedWhenFormatted}
          </p>
          <br />
          {showRemove && onRemoveClick && (
            <button type="button" className="project-item__remove" tabIndex="-1" onClick={e => onRemoveClick(e, x)}>
              <span className="project-item__remove-icon" />
              <Translate resourceString="remove" />
            </button>
          )}
        </div>
      </button>
    ))}
    <button type="button" className="project-item" onClick={onCreateProjectClick}>
      <div className="project-item__new">
        <div className="project-item__new-inner">
          <div className="project-item__new-plus" />
          <div className="project-item__new-text">
            <Translate resourceString="profile-manager.createproject" />
          </div>
        </div>
      </div>
    </button>
  </div>
);

export default ProjectList;
