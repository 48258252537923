import Grids from 'js/enums/Grids';
import { getAllPegs } from 'js/redux/actioncreators/grid';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// eslint-disable-next-line import/no-webpack-loader-syntax
import PdfWorker from 'workerize-loader!./render.worker';

const worker = new PdfWorker();

const defaultFinish = (url, filename) => {
  /**
   * In 2021 this still seems to be the best way to download a URL with JS ¯\_(ツ)_/¯
   */
  const temporaryLink = document.createElement('a');
  document.body.appendChild(temporaryLink);
  temporaryLink.style = 'display:none;';
  temporaryLink.href = url;
  temporaryLink.setAttribute('download', filename);
  temporaryLink.click();
  temporaryLink.remove();
  URL.revokeObjectURL(url);
};

/**
 * Renders inventory for the current project as a PDF
 *
 * Uses a worker thread so the UI doesn't lock up
 *
 * @param {*} onFinish optional callback to use instead of the default which prompts the user to download
 * @returns { canRender, startRender, isRendering} where
 *
 * canRender is a boolean that means enough of the store has loaded to proceed
 * startRender is a function which initiates the render
 * isRendering is a boolean that shows if it's currently rendering it, for UI purposes
 */
const useRenderedPdf = (onFinish = defaultFinish) => {
  const canRender = useSelector(
    state =>
      state.app.present.grid.isPopulated &&
      state.app.present.grid[Grids.WALLBAY].length > 0 &&
      !state.app.present.app.translations.translationsLoading &&
      state.app.present.grid.pegsLoading === false &&
      state.app.present.grid.pegs.length > 0 &&
      state.app.present.grid.dataLoading === false,
  );
  // This blocks rendering until they have some bays - but why would they be rendering if there wasn't a bay?
  const store = useSelector(state => state);
  const dispatch = useDispatch();
  const [isRendering, setIsRendering] = useState(false);

  useEffect(() => {
    dispatch(getAllPegs());
  }, []);

  const dateTimeString = new Date()
    .toLocaleString()
    .slice(0, -3)
    .replace(/[:/]/g, '-')
    .replace(',', ''); // can't have colons in windows names

  const { customerOrderNumber } = store.app.present.project;
  const orderString = customerOrderNumber ? `order ${customerOrderNumber}` : '';

  const pdfFilename = `milwaukee inventory  ${orderString} ${dateTimeString}`;

  const startRender = () => {
    if (!canRender) {
      throw new Error("Not ready to render yet - check canRender and don't call until it is true");
    }
    setIsRendering(true);
    worker
      .render(store)
      .then(output => {
        const pdfUrl = URL.createObjectURL(output);
        setIsRendering(false);
        onFinish(pdfUrl, pdfFilename);
      })
      .catch(err => {
        throw err;
      });
  };

  return {
    canRender,
    isRendering,
    startRender,
  };
};

export default useRenderedPdf;
