import React from 'react';
import { connect } from 'react-redux';
import { openSubNav, closeSubNav, openHoverNav, closeHoverNav } from '@redux/actioncreators/app';
import { openAddBar } from '@redux/actioncreators/grid';
import SUB_NAVS from '@enums/SubNavs';
import HOVER_NAVS from '@enums/HoverNavs';
import BAR_EDIT_MODE from '@enums/BarEditMode';
import classNames from 'classnames';
import { t, Translate } from '@components/translations';
import BaySelector from './BaySelector';

const AddProductsOptions = props => {
  const { app, grid, openSubNavInternal, closeSubNavInternal, openHoverNavInternal, closeHoverNavInternal, openAddBarInternal } = props;
  const isActive = app.hoverNav === HOVER_NAVS.ADD_PRODUCTS;
  const isSubNavActive = app.subNav === SUB_NAVS.PRODUCT_SEARCH || app.subNav === SUB_NAVS.ARTICLE_NUMBER_IMPORT;
  const isBarModalOpen = grid.barEditMode === BAR_EDIT_MODE.ADD || grid.barEditMode === BAR_EDIT_MODE.EDIT;

  const handleClick = id => {
    openSubNavInternal(id);
  };

  const buttonClassNames = classNames({
    navigation__icon: true,
    'navigation__icon--plus': true,
    active: isActive || isSubNavActive,
  });

  const handleButtonClick = () => {
    if (isActive) {
      closeHoverNavInternal();
    } else if (isSubNavActive) {
      closeSubNavInternal();
    } else {
      openHoverNavInternal(HOVER_NAVS.ADD_PRODUCTS);
    }
  };

  return (
    <React.Fragment>
      <button type="button" className={buttonClassNames} onClick={() => handleButtonClick()}>
        <p>{t('profile-manager.addproduct')}</p>
      </button>

      {isActive && (
        <BaySelector>
          <li className="has-icon" role="presentation" onClick={() => handleClick(SUB_NAVS.ARTICLE_NUMBER_IMPORT)}>
            <span className="icon icon--plus" />
            <Translate resourceString="profile-manager.importbyarticle" />
          </li>
          <li className="has-icon" role="presentation" onClick={() => handleClick(SUB_NAVS.PRODUCT_SEARCH)}>
            <span className="icon icon--search" />
            <Translate resourceString="profile-manager.searchplaceholder" />
          </li>
          {!isBarModalOpen && (
            <>
              <li className="has-icon" role="presentation" onClick={openAddBarInternal}>
                <span className="icon icon--custom-bars" />
                <Translate resourceString="profile-manager.custombar.create" />
              </li>
              <li className="has-icon" role="presentation" onClick={() => handleClick(SUB_NAVS.BAR_LIST)}>
                <span className="icon icon--bars" />
                <Translate resourceString="profile-manager.custombar.addexisting" />
              </li>
            </>
          )}
          <li className="has-icon" role="presentation" onClick={() => handleClick(SUB_NAVS.PLACEHOLDER)}>
            <span className="icon icon--placeholder" />
            <Translate resourceString="profile-manager.placeholder.create" />
          </li>
        </BaySelector>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  products: state.app.present.products,
  app: state.app.present.app,
  grid: state.app.present.grid,
});

const mapDispatchToProps = dispatch => ({
  searchProductsInternal: params => dispatch(AddProductsOptions(params)),
  openSubNavInternal: id => dispatch(openSubNav(id)),
  closeSubNavInternal: () => dispatch(closeSubNav()),
  openHoverNavInternal: id => dispatch(openHoverNav(id)),
  closeHoverNavInternal: () => dispatch(closeHoverNav()),
  openAddBarInternal: () => dispatch(openAddBar()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddProductsOptions);
