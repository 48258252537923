/* eslint-disable prefer-destructuring */
import ACTIONS from '@redux/actions';
import GRIDS from '@enums/Grids';
import { removeBay, addNewBay } from '@redux/actioncreators/grid';
import { t } from '@components/translations';

const WHITELIST = [ACTIONS.HISTORY.UNDO, ACTIONS.HISTORY.REDO];

const historyMiddleware = store => next => action => {
  if (WHITELIST.indexOf(action.type) === -1) {
    next(action);
    return;
  }

  const state = store.getState();

  const past = state.app.past[state.app.past.length - 1]?.project;
  const future = state.app.future[0]?.project;
  const present = state.app.present.project;

  let target = null;

  switch (action.type) {
    default: {
      break;
    }

    case ACTIONS.HISTORY.UNDO: {
      console.log('undo');
      if (!past) {
        break;
      }

      target = past;
      break;
    }

    case ACTIONS.HISTORY.REDO: {
      console.log('redo');
      if (!future) {
        break;
      }

      target = future;
      break;
    }
  }

  if (!target) {
    next(action);
    return;
  }

  // The present state has MORE bays than the target, we need to delete any that are missing
  if (present.projectBays.length > target.projectBays.length) {
    const baysToDelete = present.projectBays.filter(x => target.projectBays.findIndex(y => y.wallbayBayGuid === x.wallbayBayGuid) === -1);

    if (baysToDelete.length > 0) {
      console.log(`Found ${baysToDelete.length} bays to remove`);

      for (let index = 0; index < baysToDelete.length; index += 1) {
        const bayToDelete = baysToDelete[index];

        const params = { bayGuid: bayToDelete.wallbayBayGuid, wallbayProjectGuid: present.projectGuid, test: true };

        store.dispatch(removeBay(params));
      }
    }

    // next(action);
    // return;
  }

  // The present state has LESS bays than the target, we need to ADD any that are missing
  if (present.projectBays.length < target.projectBays.length) {
    const baysToAdd = target.projectBays.filter(x => present.projectBays.findIndex(y => y.wallbayBayGuid === x.wallbayBayGuid) === -1);

    if (baysToAdd.length > 0) {
      console.log(`Found ${baysToAdd.length} bays to add`);

      for (let index = 0; index < baysToAdd.length; index += 1) {
        const bayToAdd = baysToAdd[index];

        const params = {
          wallbayProjectGuid: present.projectGuid,
          test: true,
          bays: [
            {
              data: JSON.parse(bayToAdd.jsonData),
              wallbayProjectGuid: present.projectGuid,
              wallbayTypeGuid: bayToAdd.wallbayTypeGuid,
              wallbayBayGuid: bayToAdd.wallbayBayGuid,
              bayName: bayToAdd.bayName ? bayToAdd.bayName : t('profile-manager.bay.defaultname'),
            },
          ],
        };

        store.dispatch(addNewBay(params, true));
      }
    }

    // next(action);
    // return;
  }

  next(action);
};

export default historyMiddleware;
