import ACTIONS from '@redux/actions';
import ApiService from '@js/api/ApiService';

export const searchProducts = ({ searchTerm, cultureCode }) => async dispatch => {
  dispatch({ type: ACTIONS.PRODUCT.FETCH_SEARCH });

  try {
    const response = await ApiService.request({ slug: 'search-products', verb: 'GET', params: { searchTerm, cultureCode } });

    dispatch({
      type: ACTIONS.PRODUCT.FETCH_SEARCH_SUCCESS,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: ACTIONS.PRODUCT.FETCH_SEARCH_ERROR,
      payload: error,
    });
  }
};
