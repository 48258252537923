import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { fetchProjectTypes, createProjectType, createProject, deleteProjectType } from '@redux/actioncreators/project';

import { addToast } from '@redux/actioncreators/toast';

import Loader from '@components/shared/Loader';
import CreateProjectTypeForm from '@components/projects/CreateProjectTypeForm';
import { Translate } from '@components/translations';
import Modal from '@components/shared/Modal';
import TOAST_TYPES from '@enums/ToastTypes';
import ProjectTypeList from './ProjectTypeList';

const ProjectTypeSection = props => {
  const { user, deleteProjectTypeInternal, project, listCustom, createProjectInternal, addToastInternal } = props;
  const [showCustomWallbay, setShowCustomWallbay] = useState(false);
  const [showDeleteWarning, setShowDeleteWarning] = useState(false);
  const [projectToDelete, setProjectToDelete] = useState(null);

  const handleRemoveClick = (e, projectTypeGuid) => {
    e.preventDefault();
    e.stopPropagation();

    if (showDeleteWarning === false) {
      setProjectToDelete(projectTypeGuid);
      setShowDeleteWarning(true);
      return;
    }

    deleteProjectTypeInternal({ userGuid: user.userGuid, projectTypeGuid: projectToDelete, reFetch: true });
    setProjectToDelete(null);
    setShowDeleteWarning(false);
  };

  const handleCreateProject = typeGuid => {
    const params = {
      projectUserGuid: user.userGuid,
      projectTypeGuid: typeGuid,
      redirect: true,
    };

    createProjectInternal(params);
    addToastInternal('Project Created', { type: TOAST_TYPES.SUCCESS });
  };

  if (project.projectTypesLoading && project.projectTypes.length === 0) {
    return (
      <div className="project-list__loader">
        <Loader />
      </div>
    );
  }

  return (
    <Fragment>
      <ProjectTypeList
        listCustom={listCustom}
        onTypeClick={handleCreateProject}
        onRemoveClick={handleRemoveClick}
        onCreateCustomClick={setShowCustomWallbay}
      />

      {listCustom && showDeleteWarning && (
        <Modal showClose={false} isOpen={showDeleteWarning}>
          <form>
            <div className="form__row">
              <h4 className="form__title">
                <Translate resourceString="profile-manager.custombay.delete.title" />
              </h4>
            </div>
            <div className="form__row">
              <p>
                <Translate resourceString="profile-manager.custombay.delete.description" />
              </p>
            </div>
            <div className="form__footer">
              <button type="button" className="btn btn--grey" onClick={() => setShowDeleteWarning(false)}>
                <Translate resourceString="profile-manager.custombay.delete.no" />
              </button>
              <button type="button" className="btn btn--primary" onClick={e => handleRemoveClick(e)}>
                <Translate resourceString="profile-manager.custombay.delete.yes" />
              </button>
            </div>
          </form>
        </Modal>
      )}
      {listCustom && (
        <Modal showClose isOpen={showCustomWallbay} setIsOpen={setShowCustomWallbay}>
          <CreateProjectTypeForm />
        </Modal>
      )}
    </Fragment>
  );
};

const mapStateToProps = state => ({
  project: state.app.present.project,
  user: state.app.present.user,
});

const mapDispatchToProps = dispatch => ({
  fetchProjectTypesInternal: ({ userGuid }) => dispatch(fetchProjectTypes({ userGuid })),
  createProjectTypeInternal: params => dispatch(createProjectType(params)),
  deleteProjectTypeInternal: params => dispatch(deleteProjectType(params)),
  createProjectInternal: params => dispatch(createProject(params)),

  addToastInternal: (message, settings) => dispatch(addToast(message, settings)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProjectTypeSection));
