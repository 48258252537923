export default [
  'Lang_en-EU',
  'Lang_cs-CZ',
  'Lang_da-DK',
  'Lang_de-CH',
  'Lang_de-DE',
  'Lang_en-GB',
  'Lang_es-ES',
  'Lang_fi-FI',
  'Lang_fr-BE',
  'Lang_fr-FR',
  'Lang_hu-HU',
  'Lang_it-IT',
  'Lang_nl-BE',
  'Lang_nl-NL',
  'Lang_nn-NO',
  'Lang_pl-PL',
  'Lang_ru-RU',
  'Lang_sk-SK',
  'Lang_sv-SE',
  'Lang_lt-lt',
  'Lang_et-EE',
  'Lang_lv-LV',
  'Lang_ar-AE',
  'Lang_en-ZA',
  'Lang_pt-PT',
  'Lang_tr-TR',
  'Lang_ro-RO',
  'lang_en-TT',
  'lang_de-AT',
  'lang-fr-LU',
  'profile-manager.title',
  'profile-manager.selectbay',
  'profile-manager.recent-projects',
  'profile-manager.milwaukeebays',
  'profile-manager.custombays',
  'profile-manager.selectatemplate',
  'profile-manager.templates',
  'profile-manager.404.message',
  'profile-manager.404.backhome',
  'lastedited',
  'remove',
  'profile-manager.createproject',
  'profile-manager.custombay',
  'custom.name',
  'custom.firstname',
  'custom.lastname',
  'custom.emailaddress',
  'custom.reviews.addressline1',
  'custom.reviews.addressline2',
  'custom.reviews.towncity',
  'custom.reviews.postcode',
  'custom.back-to-dashboard',
  'width',
  'height',
  'create',
  'profile-manager.custombay.nameplaceholder',
  'profile-manager.customtemplate.create',
  'profile-manager.customtemplate.nameplaceholder',
  'profile-manager.customtemplate.descriptionplaceholder',
  'profile-manager.standardtemplate',
  'profile-manager.standardtemplate.description',
  'profile-manager.custombay.create',
  'profile-manager.custombay.delete.title',
  'profile-manager.custombay.delete.description',
  'profile-manager.custombay.delete.yes',
  'profile-manager.custombay.delete.no',
  'profile-manager.grid.status.saving',
  'profile-manager.grid.status.pending',
  'profile-manager.grid.status.saved',
  'profile-manager.grid.activelist.title',
  'profile-manager.importbyarticle',
  'profile-manager.searchplaceholder',
  'profile-manager.overflowlist',
  'profile-manager.addproduct',
  'profile-manager.activeitems',
  'profile-manager.selectlanguage',
  'profile-manager.alignmentmodal',
  'profile-manager.viewinventory',
  'profile-manager.savetemplate',
  'profile-manager.grid.show',
  'profile-manager.grid.hide',
  'profile-manager.images.show',
  'profile-manager.images.hide',
  'profile-manager.milwaukeebays.description',
  'profile-manager.custombays.description',
  'profile-manager.recent-projects.description',
  'profile-manager.templates.description',
  'profile-manager.wallbay',
  'undo',
  'redo',
  'zoom',
  'notes',
  'ordernumber',
  'customerdetails',
  'downloadpdf',
  'generatingpdf',
  'generatingExcel',
  'downloadExcel',
  'quantity',
  'products',
  'pegs',
  'features',
  'signout',
  'profile-manager.projectinventoryexport',
  'profile-manager.wallbayinventory',
  'profile-manager.exportoptions',
  'profile-manager.exportoptions.planogram',
  'profile-manager.exportoptions.productimages',
  'profile-manager.exportoptions.outlines',
  'profile-manager.exportoptions.detailpages',
  'profile-manager.exportoptions.inventory',
  'profile-manager.exportoptions.description',
  'profile-manager.custombar.create',
  'profile-manager.custombar.edit',
  'profile-manager.custombar.nameplaceholder',
  'profile-manager.custombar.level.0',
  'profile-manager.custombar.level.1',
  'profile-manager.custombar.level.2',
  'profile-manager.custombar.selectlevel',
  'profile-manager.custombar.saveandadd',
  'profile-manager.custombar.saveasglobal',
  'profile-manager.custombar.addexisting',
  'profile-manager.bar',
  'profile-manager.bars.global',
  'profile-manager.bars.user',
  'update',
  'profile-manager.backgrounds.hide',
  'profile-manager.backgrounds.show',
  'profile-manager.custombar.create.description',
  // new from multiple bays work
  'profile-manager.placeholder.create',
  'profile-manager.placeholder.edit',
  'profile-manager.placeholder',
  'profile-manager.placeholders',
  'profile-manager.back',
  'profile-manager.bay.delete',
  'profile-manager.bay.empty',
  'profile-manager.bay.defaultname',
  'profile-manager.bay.entername',
  'profile-manager.bay.title',

  'profile-manager.output.name',
  'profile-manager.output.size',
  'profile-manager.output.pegtotals',
  'profile-manager.error.noroom',
  'profile-manager.error',

  'profile-manager.error.productsizedchanged',
  'profile-manager.copy',
  'profile-manager.close',
  'profile-manager.addnewbay',

  'profile-manager.product-info.width',
  'profile-manager.product-info.height',

  'profile-manager.zoom.fit',
  'profile-manager.zoom.reset',

  // Alignment //
  'profile-manager.align.align-to-top',
  'profile-manager.align.align-to-left',
  'profile-manager.align.align-to-right',
  'profile-manager.align.align-to-bottom',
  'profile-manager.align.alignment-options',
  'profile-manager.align.alignment-options.single-select',
  'profile-manager.align.alignment-options.single-select.description',
  'profile-manager.align.alignment-options.multiple-select',
  'profile-manager.align.alignment-options.multiple-select.description',
  'profile-manager.align.alignment-options.specific-multiple-select',
  'profile-manager.align.alignment-options.specific-multiple-select.description',
  'profile-manager.align.alignment-options.multiple-alignment',
  'profile-manager.align.alignment-options.multiple-alignment.description',

  'profile-manager.options.send-to',
  'profile-manager.options.delete',
  'profile-manager.options.inspect',
  'profile-manager.options.edit',
  'profile-manager.navigation.close',
  'profile-manager.preview',
  'profile-manager.import.paste-article-numbers',
  'profile-manager.import.add-products',
  'profile-manager.import.add-products.success',
  'profile-manager.import.add-products.not-found',
  'profile-manager.search.search-product-name-article-number',
  'profile-manager.search.no-results-for-term',
  'profile-manager.search.add-products',
  'profile-manager.toast.was-removed',
  'profile-manager.add-all-bays',

  'profile-manager.bay.1m-wall-bay',
  'profile-manager.bay.900mm-wall-bay',
  'profile-manager.bay.1m-low-level-gondola',
  'profile-manager.bay.900mm-low-level-gondola',
  'profile-manager.bay.1m-extended-wall-bay',
  'profile-manager.bay.90cm-extended-wall-bay',
  'profile-manager.bay.cross-extension-panel',
  'profile-manager.bay.bolt-fdsu-panels',
  'profile-manager.bay.packout-compatible-kits-pos',
  'profile-manager.bay.medium-gondola',  

  'profile-manager.overflow.title',
];

// changed
// profile-manager.bars.user - My Created Bars
// profile-manager.custombar.create - Create a new custom bar
// profile-manager.custombar.create.title - Delete
// profile-manager.custombar.create.description - Add products to your bar using the menu on the left. To delete a product from the bar, right click.
