import React from 'react';

const Modal = ({
  showClose, children, isOpen, setIsOpen,
}) => {
  if (isOpen === false) {
    return null;
  }

  return (
    <div className="modal">
      <div className="site-wide modal__inner">
        {showClose && <div role="presentation" className="modal__close" onClick={() => setIsOpen(false)} />}
        {children}
      </div>
    </div>
  );
};

export default Modal;
