import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { withRouter } from 'react-router';
import Tooltip from '@components/navigation/Tooltip';
import { t } from '@components/translations';

const OutputLink = ({ goToOutput, match }) => (
  <button type="button" className="navigation__icon navigation__icon--print" onClick={() => goToOutput(match.params.projectGuid)}>
    <p>{t('profile-manager.viewinventory')}</p>
  </button>
);

// const mapStateToProps = state => ({
// });

const mapDispatchToProps = dispatch => ({
  goToOutput: projectGuid => dispatch(push(`/build/output/${projectGuid}`)),
});

export default connect(null, mapDispatchToProps)(withRouter(OutputLink));
