import React from 'react';

import { Text, View } from '@react-pdf/renderer';
import styles from './styles';

const Item = ({ articleNumber, variantName, quantityText = 'quantity', quantity = 1 }) => (
  <View style={styles.item} wrap={false}>
    <Text style={styles.id}>{articleNumber}</Text>
    <View style={{ paddingLeft: 10 }}>
      <Text style={styles.detailName}>{variantName}</Text>
      <Text style={styles.greyed}>
        {quantityText} - {quantity}
      </Text>
    </View>
  </View>
);

export default Item;
