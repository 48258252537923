import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import undoable, { includeAction, groupByActionTypes } from 'redux-undo';
import ACTIONS from '@redux/actions';

import app from './app';
import user from './user';
import project from './project';
import grid from './grid';
import products from './products';
import toast from './toast';
import history from './history';

const undoableOptions = {
  undoType: 'HISTORY/UNDO',
  redoType: 'HISTORY/REDO',
  clearHistoryType: 'HISTORY/CLEAR',
  limit: 20,
  ignoreInitialState: true,
  syncFilter: true,
};

const appReducer = combineReducers({
  app,
  user,
  project,
  grid,
  products,
  toast,
  history,
});

const WHITELIST = [
  ACTIONS.GRID.ADD,
  ACTIONS.GRID.REMOVE,
  ACTIONS.GRID.UPDATE_POSITION,
  // ACTIONS.GRID.UPDATE_MULTIPLE_POSITIONS,
  ACTIONS.GRID.MOVE_PRODUCT_POSITION,
  ACTIONS.GRID.ADD_BAR,
  // ACTIONS.GRID.ADD_BAY,
  // ACTIONS.PROJECT.SET_PROJECT,
];

export default browserHistory =>
  combineReducers({
    app: undoable(appReducer, {
      ...undoableOptions,
      filter: (action, currentState, previousHistory) => {
        if (WHITELIST.indexOf(action.type) > -1) {
          return true;
        }

        if (
          action.type === ACTIONS.PROJECT.SET_PROJECT &&
          currentState.project.projectGuid !== undefined &&
          previousHistory.present.project.projectGuid !== undefined
        ) {
          return true;
        }

        return false;
      },
      groupBy: groupByActionTypes([ACTIONS.GRID.MOVE_PRODUCT_POSITION]),
    }),
    router: connectRouter(browserHistory),
  });
