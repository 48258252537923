import { createStore, applyMiddleware, compose } from 'redux';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';

import pingMiddleware from '@redux/middleware/ping';
import autoSaveMiddleware from '@redux/middleware/autosave';
import appLoadingMiddleware from '@redux/middleware/appLoading';
import historyMiddleware from '@redux/middleware/history';

import createRootReducer from '@redux/reducers';

export const history = createBrowserHistory();

/* eslint-disable no-underscore-dangle */
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
/* eslint-enable no-underscore-dangle */

const rootReducer = createRootReducer(history);

const store = createStore(
  rootReducer,
  composeEnhancers(
    applyMiddleware(thunk, routerMiddleware(history), appLoadingMiddleware, pingMiddleware, autoSaveMiddleware, historyMiddleware),
  ),
);

export default store;
