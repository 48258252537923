import Types from 'js/enums/Types';
import { getOffset } from '@utils';

class AbsoluteGridService {
  setAbsoluteCssVariables(element, settings) {
    // eslint-disable-next-line no-param-reassign
    element.style.width = `${settings.gridStepsX * settings.gridPitch}px`;

    // eslint-disable-next-line no-param-reassign
    element.style.height = `600px`;
  }

  getNextAvailableSpace(grid, products, product, id, gridId, settings) {
    // Order grid on X axis from 0 upwards
    const orderedGrid = grid.slice().sort((a, b) => a.entity.x - b.entity.x);

    for (let index = 0; index < orderedGrid.length; index += 1) {
      const cell = orderedGrid[index];
      const nextCell = orderedGrid[index + 1];

      let widthObject;
      if (cell.entity.type === Types.PRODUCT) {
        widthObject = products.find(x => x.articleNumber === cell.entity.articleNumber);
      } else if (cell.entity.type === Types.PLACEHOLDER) {
        widthObject = cell.placeholder;
      }

      const distanceToEnd = settings.gridStepsX * settings.gridPitch - (cell.entity.x + widthObject.originalWidth);

      // if the next item doesn't exist then check to the end of the grid
      if (nextCell === undefined) {
        if (distanceToEnd > product.originalWidth) {
          return {
            x: cell.entity.x + widthObject.originalWidth,
            y: cell.entity.y,
          };
        }

        return undefined;
      }

      // check the distance between the X of the current item and the X of the next item
      const distance = nextCell.entity.x - (cell.entity.x + widthObject.originalWidth);

      if (distance > product.originalWidth && distanceToEnd > product.originalWidth) {
        // if there is enough room then return the coordinates of the right side of the first element
        return {
          x: cell.entity.x + widthObject.originalWidth,
          y: cell.entity.y,
        };
      }
    }

    return { x: 0, y: 0 };
  }

  getGridPositionFromPosition(container, monitor, snapY = true) {
    const mousePosition = monitor.getClientOffset();

    const sourceOffset = monitor.getInitialSourceClientOffset();
    const clientOffset = monitor.getInitialClientOffset();

    const position = {
      x: mousePosition.x - (clientOffset.x - sourceOffset.x),
      y: mousePosition.y - (clientOffset.y - sourceOffset.y),
    };

    const offset = getOffset(container);
    const relativePosition = {
      x: position.x - offset.left,
      y: position.y - offset.top + window.scrollY,
    };

    if (snapY && relativePosition.y < 35) {
      relativePosition.y = 0;
    }

    return relativePosition;
  }

  isEmpty(grid, products, position, entityId, width, height, settings) {
    // check grid bounds
    if (position.x < 0 || position.x + width > settings.width) {
      return false;
    }

    for (let index = 0; index < grid.length; index += 1) {
      const gridItem = grid[index];

      // ignore checking the current item otherwise you won't be able to move it by 1
      if (gridItem.entity.id === entityId) {
        // eslint-disable-next-line no-continue
        continue;
      }

      // get the product
      // get width object
      let widthObject;

      switch (gridItem.entity.type) {
        default:
        case Types.PRODUCT: {
          widthObject = products.find(x => x.articleNumber === gridItem.entity.articleNumber);
          break;
        }

        case Types.PLACEHOLDER: {
          widthObject = gridItem.placeholder;
          break;
        }
      }

      // Dragging Rectangle
      const rect1 = {
        topLeft: {
          x: position.x,
          y: position.y,
        },
        bottomRight: {
          x: position.x + width,
          y: position.y + height,
        },
      };

      // Product Rectangle
      const rect2 = {
        topLeft: {
          x: gridItem.entity.x,
          y: gridItem.entity.y,
        },
        bottomRight: {
          x: gridItem.entity.x + widthObject.originalWidth,
          y: gridItem.entity.y + widthObject.originalHeight,
        },
      };

      if (this.doOverlap(rect1, rect2)) {
        return false;
      }
    }

    return true;
  }

  doOverlap(r1, r2) {
    return !(
      r2.topLeft.x >= r1.bottomRight.x ||
      r2.bottomRight.x <= r1.topLeft.x ||
      r2.topLeft.y >= r1.bottomRight.y ||
      r2.bottomRight.y <= r1.topLeft.y
    );
  }
}

export default new AbsoluteGridService();
