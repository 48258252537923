import ACTIONS from '@redux/actions';

export const undo = () => (dispatch, getState) => {
  dispatch({
    type: ACTIONS.HISTORY.UNDO,
  });

  // Get the present
  // const { app } = getState();
  // const { present } = app.history;

  // dispatch({
  //   type: ACTIONS.HISTORY.PROPAGATE_PRESENT,
  //   payload: present,
  // });
};

export const redo = () => (dispatch, getState) => {
  dispatch({
    type: ACTIONS.HISTORY.REDO,
  });

  // Get the present
  // const { app } = getState();
  // const { present } = app.history;

  // dispatch({
  //   type: ACTIONS.HISTORY.PROPAGATE_PRESENT,
  //   payload: present,
  // });
};

export function clearHistory() {
  return {
    type: ACTIONS.HISTORY.CLEAR,
  };
}
