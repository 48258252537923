import React, { useState } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import uuidv4 from 'uuid/v4';
import debounce from 'lodash/debounce';

import { searchProducts } from '@redux/actioncreators/products';
import { addEntity } from '@redux/actioncreators/grid';
import FILL_MODES from '@enums/FillModes';
import GRIDS from '@enums/Grids';
import SUB_NAVS from '@enums/SubNavs';
import BAR_EDIT_MODE from '@enums/BarEditMode';

import Loader from '@components/shared/Loader';
import Types from 'js/enums/Types';
import NumberInput from 'js/components/shared/NumberInput';
import { t } from 'js/components/translations/index';
import CloseSubnav from '../buttons/CloseSubnav';

const ProductSearch = props => {
  const { products, grid, app, searchProductsInternal, addEntityInternal } = props;

  if (app.subNav !== SUB_NAVS.PRODUCT_SEARCH) {
    return null;
  }

  const [selectedProducts, setSelectedProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  const handleChange = value => {
    setSearchTerm(value);
    searchProductsInternal({ searchTerm: value.trim(), cultureCode: app.uiCultureCode });
  };

  const handleQuantityChange = (product, value) => {
    const existingIndex = selectedProducts.findIndex(x => x.articleNumber === product.articleNumber);

    if (existingIndex > -1) {
      if (value > 0) {
        selectedProducts[existingIndex].quantity = value;
      } else {
        selectedProducts.splice(existingIndex, 1);
      }
    } else {
      const productCopy = { ...product };
      productCopy.quantity = value;
      selectedProducts.push(productCopy);
    }

    setSelectedProducts([...selectedProducts]);
  };

  const handleAddClick = () => {
    const actions = [];

    // Populate an array and duplicate the products according to their quantity //
    const selectedProductsWithQuantities = [];
    selectedProducts.forEach(product => {
      for (let i = 0; i < product.quantity; i += 1) {
        selectedProductsWithQuantities.push(product);
      }
    });

    for (let index = 0; index < selectedProductsWithQuantities.length; index += 1) {
      const product = selectedProductsWithQuantities[index];
      let gridId = GRIDS.WALLBAY;

      if (grid.barEditMode !== BAR_EDIT_MODE.CLOSED) {
        gridId = GRIDS.BAR;
      }

      const entity = {
        id: uuidv4(),
        articleNumber: product.articleNumber,
        gridId,
        type: Types.PRODUCT,
        gridIndex: app.selectedBay,
      };

      const settings = {
        fillDirection: FILL_MODES.ROW,
      };

      actions.push({
        entity,
        product,
        settings,
      });
    }

    addEntityInternal(actions);

    setSelectedProducts([]);
  };

  const renderProduct = product => {
    const isActive = selectedProducts.findIndex(x => x.articleNumber === product.articleNumber) > -1;

    const itemClassNames = classNames({
      'product-search__item': true,
      active: isActive,
    });

    return (
      <div key={product.articleNumber} className={itemClassNames} role="presentation">
        <div className="product-search__details">
          <h2>{product.variantName}</h2>
          <h5>{product.articleNumber}</h5>
        </div>
        <div className="product-search__quantity">
          <NumberInput
            value={product.quantity !== undefined ? product.quantity : 0}
            onChange={value => handleQuantityChange(product, value)}
          />
        </div>
      </div>
    );
  };

  const renderProducts = () => {
    if (searchTerm.length === 0) {
      return null;
    }

    if (products.isLoading === true) {
      return (
        <div className="product-search__message">
          <Loader />
        </div>
      );
    }

    if (products.noResults === true) {
      return (
        <div className="product-search__message">
          {t('profile-manager.search.no-results-for-term')} &quot;{searchTerm}&quot;
        </div>
      );
    }

    return <ul>{products.searchResults.map(x => renderProduct(x))}</ul>;
  };

  return (
    <div className="sub-nav" onClick={e => e.stopPropagation()}>
      <div className="product-search">
        <div className="product-search__header">
          <input
            type="text"
            placeholder={t('profile-manager.search.search-product-name-article-number')}
            value={searchTerm}
            onChange={e => handleChange(e.currentTarget.value)}
          />
          <CloseSubnav />

          {/* <div className="product-search__utils">
            <div className="product-search__util">
              <input id="chkbox" type="checkbox" />
              <label htmlFor="chkbox">Select All</label>
            </div>
          </div> */}
        </div>

        <div className="product-search__items">
          {renderProducts()}
          <button type="button" className="sub-nav__submit" onClick={handleAddClick}>
            <div className="sub-nav__submit-text">
              {t('profile-manager.search.add-products')} ({selectedProducts.reduce((acc, cur) => acc + cur.quantity ?? 1, 0)})
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  products: state.app.present.products,
  app: state.app.present.app,
  grid: state.app.present.grid,
});

const mapDispatchToProps = dispatch => ({
  searchProductsInternal: debounce(params => dispatch(searchProducts(params)), 275),
  addEntityInternal: params => dispatch(addEntity(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductSearch);
