import ACTIONS from '@redux/actions';
import LocalStorage, { KEYS } from '@js/storage/localStorage';

const initialState = {
  userGuid: undefined,
  userLoading: false,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.USER.SET_USER: {
      const newState = { ...state };
      const userGuid = action.payload;

      newState.userGuid = userGuid;
      LocalStorage.set(KEYS.USER_GUID, userGuid);

      newState.userLoading = false;

      return newState;
    }

    case ACTIONS.USER.CHECK_USER_REQUEST:
    case ACTIONS.USER.CREATE_USER_FETCH: {
      const newState = { ...state };

      newState.userLoading = true;

      return newState;
    }

    case ACTIONS.USER.CREATE_USER_FETCH_ERROR: {
      const error = action.payload;
      console.log(error);
      return state;
    }

    default:
      return state;
  }
}
