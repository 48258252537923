import React from 'react';
import { closeAddBar } from '@redux/actioncreators/grid';
import { connect } from 'react-redux';
import Logo from '@img/svg/logo.svg';

const GridBarHeader = ({ showClose = true, title, setCustomBarNameInternal, setCustomBarLevelInternal, closeAddBarInternal }) => (
  <div className="grid-bar-header">
    <img src={Logo} className="grid-bar-header__logo" />
    {title && <h2 className="grid-bar-header__title">{title}</h2>}
    {showClose && <div className="grid-bar-header__close" onClick={() => {
      setCustomBarNameInternal(undefined);
      setCustomBarLevelInternal(undefined);
      closeAddBarInternal();
    }}></div>}
  </div>
);

const mapDispatchToProps = dispatch => ({
  closeAddBarInternal: () => dispatch(closeAddBar()),
});

export default connect(
  null,
  mapDispatchToProps,
)(GridBarHeader);
