import ACTIONS from '@redux/actions';

const initialState = [
  // {
  //   type: TOAST_TYPES.INFO,
  //   message: 'Hello There!',
  // },
  // {
  //   type: TOAST_TYPES.SUCCESS,
  //   message: 'Project Added',
  // },
  // {
  //   type: TOAST_TYPES.WARNING,
  //   message: 'Project Warning, 50% full',
  // },
  // {
  //   type: TOAST_TYPES.ERROR,
  //   message: 'Project API Error',
  // },
];

export default function (state = initialState, action) {
  switch (action.type) {
    case ACTIONS.TOAST.ADD: {
      const newState = [...state];

      newState.push(action.payload);

      return newState;
    }

    case ACTIONS.TOAST.REMOVE: {
      const newState = [...state];
      const id = action.payload;

      const index = newState.findIndex(x => x.id === id);
      if (index > -1) {
        newState.splice(index, 1);
      }

      return newState;
    }

    default:
      return state;
  }
}
