import React from 'react';
import classNames from 'classnames';

const HoverIndicator = props => {
  const { entities, isAbsolute } = props;

  if (entities === undefined || entities.length === 0) {
    return null;
  }

  // + 1 due to CSS grid not being 0 indexed
  const getStyleFromPosition = entity => {
    if (isAbsolute === true) {
      return {
        position: 'absolute',
        top: '0px',
        left: '0px',
        transform: `translate(${entity.x}px, ${entity.y}px)`,
        width: `${entity.width}px`,
        height: `${entity.height}px`,
      };
    }

    return {
      gridColumnStart: entity.x + 1,
      gridColumnEnd: entity.x + entity.width + 1,
      gridRowStart: entity.y + 1,
      gridRowEnd: entity.y + entity.height + 1,
    };
  };

  const cellClassNames = classNames({
    'wb-cell': true,
    'wb-cell--drop': true,
    hover: true,
    'no-border': isAbsolute,
  });

  return entities.map(x => <div key={`${x.x}|${x.y}|${x.width}|${x.height}`} className={cellClassNames} style={getStyleFromPosition(x)} />);
};

export default HoverIndicator;
