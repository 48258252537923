import React, { useEffect } from 'react';
import { Translate } from '@components/translations';
import { connect } from 'react-redux';
import { fetchProjectTypes } from '@redux/actioncreators/project';

import Standard from '@img/bays/1m.png';
import Gondola from '@img/bays/1m-gondola.png';
import GreyBay from '@img/bays/greybay.png';
import CrossExtensionPanel from '@img/bays/cross-extension-panel.png';
import FDSU from '@img/bays/fdsu.png';
import CompatibleKits from '@img/bays/compatible-kits.png';
import MediumGondola from '@img/bays/medium-gondola.png';

const ProjectTypeList = ({ user, project, _fetchProjectTypes, onTypeClick, onRemoveClick, onCreateCustomClick, listCustom }) => {
  useEffect(() => {
    _fetchProjectTypes({ userGuid: user.userGuid });
  }, []);

  const getTypeImage = wallbayTypeCodeName => {
    switch (wallbayTypeCodeName) {
      case 'Wallbay.1mStandard': {
        return <div className="project-item__img" style={{ backgroundImage: `url('${Standard}')` }} />;
      }

      case 'Wallbay.900mmStandard': {
        return <div className="project-item__img" style={{ backgroundImage: `url('${Standard}')` }} />;
      }

      case 'Wallbay.1mExtended': {
        return <div className="project-item__img" style={{ backgroundImage: `url('${Standard}')` }} />;
      }

      case 'Wallbay.90cmExtended': {
        return <div className="project-item__img" style={{ backgroundImage: `url('${Standard}')` }} />;
      }

      case 'Wallbay.1mGondola': {
        return <div className="project-item__img" style={{ backgroundImage: `url('${Gondola}')` }} />;
      }

      case 'Wallbay.900mmGondola': {
        return <div className="project-item__img" style={{ backgroundImage: `url('${Gondola}')` }} />;
      }

      case 'Wallbay.CrossExtensionPanel': {
        return <div className="project-item__img" style={{ backgroundImage: `url('${CrossExtensionPanel}')` }} />;
      }

      case 'Wallbay.BoltFDSUPanels': {
        return <div className="project-item__img" style={{ backgroundImage: `url('${FDSU}')` }} />;
      }

      case 'Wallbay.PackoutCompatibleKits': {
        return <div className="project-item__img" style={{ backgroundImage: `url('${CompatibleKits}')` }} />;
      }

      case 'Wallbay.MediumGondola': {
        return <div className="project-item__img" style={{ backgroundImage: `url('${MediumGondola}')` }} />;
      }

      default: {
        return <div className="project-item__img" style={{ backgroundImage: `url('${GreyBay}')` }} />;
      }
    }
  };

  const types = project.projectTypes.filter(x => x.isCustomType === listCustom);

  return (
    <div className="project-list">
      {types.map(x => (
        <div key={x.wallbayTypeGuid} role="presentation" className="project-item" onClick={() => onTypeClick(x.wallbayTypeGuid)}>
          <div className="project-item__img-wrapper">{getTypeImage(x.wallbayTypeCodeName)}</div>
          <div className="project-item__details">
            <h3 className="project-item__title">
              <Translate resourceString={`profile-manager.bay.${x.wallbayTypeName.toLowerCase().replace(/ /g, '-')}`} />
            </h3>
            <p className="project-item__date">
              {x.wallbayWidth}x{x.wallbayHeight}
            </p>
          </div>

          {listCustom && (
            <button type="button" className="project-item__remove" tabIndex="-1" onClick={e => onRemoveClick(e, x.wallbayTypeGuid)}>
              <br />
              <span className="project-item__remove-icon" />
              <Translate resourceString="remove" />
            </button>
          )}
        </div>
      ))}

      {listCustom && (
        <button type="button" className="project-item" onClick={() => onCreateCustomClick(true)}>
          <div className="project-item__img-wrapper">{getTypeImage()}</div>
          <div className="project-item__details">
            <h3 className="project-item__title">
              <Translate resourceString="profile-manager.custombay.create" />
            </h3>
            <br />
          </div>
        </button>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  project: state.app.present.project,
  user: state.app.present.user,
});

const mapDispatchToProps = dispatch => ({
  _fetchProjectTypes: ({ userGuid }) => dispatch(fetchProjectTypes({ userGuid })),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectTypeList);
