import ACTIONS from '@redux/actions';
import AppInsightsContext from 'js/appInsights/AppInsightsContext';
// import { SAVES } from '@js/data';

const initialState = {
  projectGuid: undefined,
  projectName: '',
  projects: [],
  projectTypes: [],
  projectBays: [],
  templates: [],
  templatesLoading: false,
  projectsLoading: false,
  projectTypesLoading: false,
  pdfLoading: false,
  pdfUrl: '',
  projectLoading: false,

  customerAddressLine1: null,
  customerAddressLine2: null,
  customerEmailAddress: null,
  customerFirstName: null,
  customerLastName: null,
  customerNotes: null,
  customerOrderNumber: null,
  customerPostcode: null,
  customerTownCity: null,
  customerQuantities: null,

  pdfSettingsAddPlanogram: true,
  pdfSettingsUseProductImages: true,
  pdfSettingsUseWhiteBorders: false,
  pdfSettingsAddProductDetailPages: true,
  pdfSettingsAddInventoryPage: true,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.PROJECT.FETCH_PROJECTS: {
      const newState = JSON.parse(JSON.stringify(state));

      newState.projectsLoading = true;

      AppInsightsContext.current.trackEvent({ name: 'Fetching projects' });

      return newState;
    }

    case ACTIONS.PROJECT.FETCH_PROJECTS_SUCCESS: {
      const newState = JSON.parse(JSON.stringify(state));
      const { projects } = action.payload;

      newState.projects = projects;
      newState.projectsLoading = false;

      AppInsightsContext.current.trackEvent({ name: 'Fetched projects' });

      return newState;
    }

    case ACTIONS.PROJECT.FETCH_PROJECT: {
      const newState = JSON.parse(JSON.stringify(state));

      newState.projectLoading = true;

      AppInsightsContext.current.trackEvent({ name: 'Fetch project' });

      return newState;
    }

    case ACTIONS.PROJECT.SET_PROJECT_TEST:
    case ACTIONS.PROJECT.SET_PROJECT: {
      const { project } = action.payload;
      const newState = JSON.parse(JSON.stringify(state));

      if (project === undefined) {
        return JSON.parse(JSON.stringify(initialState));
      }

      AppInsightsContext.current.trackEvent({ name: 'Set project' });

      return {
        ...newState,
        ...project,
        projectLoading: false,
      };
    }

    case ACTIONS.PROJECT.SET_NAME: {
      const newState = JSON.parse(JSON.stringify(state));
      const { name } = action.payload;

      newState.projectName = name;

      AppInsightsContext.current.trackEvent({ name: 'Set project name' });

      return newState;
    }

    case ACTIONS.PROJECT.FETCH_CREATE_PROJECT_FROM_TEMPLATE_SUCCESS:
    case ACTIONS.PROJECT.FETCH_CREATE_PROJECT_SUCCESS: {
      const newState = JSON.parse(JSON.stringify(state));
      const { projectGuid, params } = action.payload;

      newState.projectGuid = projectGuid;
      newState.projectName = params.projectName;

      AppInsightsContext.current.trackEvent({ name: 'Create project' });

      return newState;
    }

    case ACTIONS.PROJECT.FETCH_PROJECT_TYPES: {
      const newState = JSON.parse(JSON.stringify(state));

      newState.projectTypesLoading = true;

      AppInsightsContext.current.trackEvent({ name: 'Fetching project types' });

      return newState;
    }

    case ACTIONS.PROJECT.FETCH_PROJECT_TYPES_SUCCESS: {
      const newState = JSON.parse(JSON.stringify(state));
      const { wallbayTypes } = action.payload;

      newState.projectTypes = wallbayTypes;
      newState.projectTypesLoading = false;

      AppInsightsContext.current.trackEvent({ name: 'Fetched project types' });

      return newState;
    }

    case ACTIONS.PROJECT.FETCH_TEMPLATES_FOR_TYPE: {
      const newState = JSON.parse(JSON.stringify(state));

      newState.templatesLoading = true;

      AppInsightsContext.current.trackEvent({ name: 'Fetching project templates for type' });

      return newState;
    }

    case ACTIONS.PROJECT.FETCH_TEMPLATES_FOR_TYPE_SUCCESS: {
      const newState = JSON.parse(JSON.stringify(state));
      const { templates } = action.payload;

      newState.templates = templates;
      newState.templatesLoading = false;

      AppInsightsContext.current.trackEvent({ name: 'Fetched project templates for type' });

      return newState;
    }

    case ACTIONS.USER.CLEAR_PROJECT: {
      AppInsightsContext.current.trackEvent({ name: 'Clearing current project' });

      return initialState;
    }

    case ACTIONS.PROJECT.FETCH_PDF: {
      const newState = JSON.parse(JSON.stringify(state));

      newState.pdfLoading = true;
      newState.pdfUrl = '';
      AppInsightsContext.current.trackEvent({ name: 'Fetching project PDF' });

      return newState;
    }

    case ACTIONS.PROJECT.FETCH_PDF_SUCCESS: {
      const newState = JSON.parse(JSON.stringify(state));

      newState.pdfLoading = false;
      newState.pdfUrl = action.payload.pdfUrl;
      AppInsightsContext.current.trackEvent({ name: 'Fetched project PDF' });

      return newState;
    }

    case ACTIONS.PROJECT.SET_FIELD: {
      const newState = JSON.parse(JSON.stringify(state));

      newState[action.payload.fieldName] = action.payload.value;

      AppInsightsContext.current.trackEvent({ name: `Setting project ${action.payload.fieldName}` });

      return newState;
    }

    case ACTIONS.PROJECT.UPDATE_QUANTITY: {
      const newState = JSON.parse(JSON.stringify(state));
      const { articleNumber, quantity, index } = action.payload;

      // Create it if its null
      if (newState.projectBays[index].quantities === null) {
        newState.projectBays[index].quantities = {};
      }

      // Set the value
      newState.projectBays[index].quantities[articleNumber] = quantity;

      AppInsightsContext.current.trackEvent({ name: 'Updating project product quantity' });

      return newState;
    }

    case ACTIONS.PROJECT.SET_PDF_OPTION: {
      const newState = JSON.parse(JSON.stringify(state));
      const { field, value } = action.payload;

      newState[field] = value;

      AppInsightsContext.current.trackEvent({ name: `Setting project PDF option ${field}` });

      return newState;
    }

    case ACTIONS.PROJECT.UPDATE_BAR_NAME: {
      const newState = JSON.parse(JSON.stringify(state));
      const { gridIndex, bayName } = action.payload;

      newState.projectBays[gridIndex].bayName = bayName;

      AppInsightsContext.current.trackEvent({ name: 'Updating bar name' });

      return newState;
    }

    default:
      return state;
  }
}
