import React from 'react';
import { Translate } from '@components/translations';

const ProjectTemplateList = ({ templates, onCreateEmptyTemplateClick, onTemplateClick, showEmptyTemplate = true }) => (
  <div className="project-list">
    {showEmptyTemplate && (
      <button type="button" className="project-item project-item--square" onClick={onCreateEmptyTemplateClick}>
        <div className="project-item__img-wrapper">
          <div className="project-item__placeholder" />
        </div>

        <div className="project-item__details">
          <h3 className="project-item__title">
            <Translate resourceString="profile-manager.standardtemplate" />
          </h3>
          <p className="project-item__date">
            <Translate resourceString="profile-manager.standardtemplate.description" />
          </p>
          <br />
        </div>
      </button>
    )}

    {templates.map(x => (
      <button
        key={x.templateGuid}
        type="button"
        className="project-item project-item--square"
        onClick={() => onTemplateClick(x.templateGuid)}
      >
        <div className="project-item__img-wrapper">
          <div className="project-item__placeholder" />
        </div>

        <div className="project-item__details">
          <h3 className="project-item__title">{x.templateName}</h3>
          <p className="project-item__date">{x.templateDescription}</p>
          <br />
        </div>
      </button>
    ))}
  </div>
);

export default ProjectTemplateList;
