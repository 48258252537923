import React, { useState, useRef } from 'react';
import debounce from 'lodash/debounce';
import { connect } from 'react-redux';
import { setProjectName } from '@redux/actioncreators/project';
import ContentEditable from 'react-contenteditable';
import { Link } from 'react-router-dom';
import { t, Translate } from '@components/translations';

let debouncedUpdateProject;

const GridHeader = props => {
  const { app, project, setProjectNameInternal } = props;

  const contentEditableRef = useRef();
  const [newProjectName, setNewProjectName] = useState(project.projectName);
  debouncedUpdateProject = debounce(value => setProjectNameInternal(value), 275);

  const onChange = e => {
    setNewProjectName(e.target.value);

    debouncedUpdateProject({ name: e.target.value });
  };

  const getStatus = () => {
    if (app.app.isSaving === true) {
      return t('profile-manager.grid.status.saving');
    }

    if (app.app.isDirty === true) {
      return t('profile-manager.grid.status.pending');
    }

    return t('profile-manager.grid.status.saved');
  };

  return (
    <React.Fragment>
      <div className="grid-header">
        {/* TODO: Map a route to link back to previous step */}
        <Link to={'/'} className="grid-header__back">
          <div className="grid-header__back-arrow" />
          <Translate resourceString="custom.back-to-dashboard" />
        </Link>
        <div className="grid-header__inner">
          <span className="grid-header__edit-icon"></span>
          <ContentEditable
            innerRef={contentEditableRef}
            html={newProjectName}
            onChange={onChange}
            tagName="span"
            className="grid-header__project-name"
          />
          <span className="grid-header__status"> - {getStatus()}</span>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  app: state.app.present,
  project: state.app.present.project,
});

const mapDispatchToProps = dispatch => ({
  setProjectNameInternal: params => dispatch(setProjectName(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GridHeader);
