import { View, Image } from '@react-pdf/renderer';
import Types from 'js/enums/Types';
import React from 'react';
import GridService from 'js/grid/GridService';

const Product = ({ entity, gridPitch, offsetY = 0, pegSizeInPt, productsLookup, showProductImages, showWhiteBorders }) => {
  const { x, y, id, articleNumber } = entity;
  const product = productsLookup(articleNumber);
  const isOnBar = entity.gridId === Types.BAR;
  const sizes = product.sizes[0];
  const { height } = sizes;
  const width = isOnBar ? product.originalWidth / gridPitch : sizes.width;
  const image = GridService.formatProductImage(`${process.env.REACT_APP_S3_IMAGES_BUCKET}/${product.articleNumber}--Pack_1.png`);

  return (
    <React.Fragment key={id}>
      {showProductImages && (
        <Image
          src={image}
          key={id}
          style={{
            position: 'absolute',
            top: (offsetY + y) * pegSizeInPt,
            left: isOnBar ? (pegSizeInPt * x) / gridPitch : x * pegSizeInPt,
            width: width * pegSizeInPt,
            height: height * pegSizeInPt,
            objectFit: 'contain',
            objectPosition: '0% 0%',
          }}
        />
      )}
      {showWhiteBorders && (
        <View
          style={{
            position: 'absolute',
            top: (offsetY + y) * pegSizeInPt,
            left: isOnBar ? (pegSizeInPt * x) / gridPitch : x * pegSizeInPt,
            width: width * pegSizeInPt,
            height: height * pegSizeInPt,
            border: '2 solid white',
            borderRadius: '2',
          }}
        />
      )}
    </React.Fragment>
  );
};

export default Product;
