import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import styles from './styles';

const Detail = ({ name, value }) =>
  name && value ? (
    <View wrap={false} style={styles.detail}>
      <Text style={styles.detailName}>{name}: </Text>
      <Text style={styles.detailValue}>{value}</Text>
    </View>
  ) : null;

const PersonalDetails = ({
  t,
  details: {
    customerOrderNumber,
    customerFirstName,
    customerLastName,
    customerEmailAddress,
    customerAddressLine1,
    customerAddressLine2,
    customerTownCity,
    customerPostCode,
    customerNotes,
  },
}) => {
  return (
    <View style={styles.section}>
      <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <Text style={styles.heading}>{t('customerdetails')}</Text>
        {customerOrderNumber ? (
          <View>
            <Text style={styles.heading}>{t('ordernumber')}</Text>
            <Text style={[styles.greyed, { fontSize: 10 }]}>{customerOrderNumber}</Text>
          </View>
        ) : null}
      </View>

      <Detail name={t('custom.firstname')} value={customerFirstName} />
      <Detail name={t('custom.lastname')} value={customerLastName} />
      <Detail name={t('custom.emailaddress')} value={customerEmailAddress} />
      <Detail name={t('custom.reviews.addressline1')} value={customerAddressLine1} />
      <Detail name={t('custom.reviews.addressline2')} value={customerAddressLine2} />
      <Detail name={t('custom.reviews.towncity')} value={customerTownCity} />
      <Detail name={t('custom.reviews.postcode')} value={customerPostCode} />
      <Detail name={t('notes')} value={customerNotes} />
    </View>
  );
};

export default PersonalDetails;
