import React, { useState, Fragment, useRef } from 'react';
import { t } from 'js/components/translations/index';
import { GrSelect, GrMultiple, GrSelection, GrProjects } from 'react-icons/gr';

import SingleSelect from '@img/gif/single-select.gif';
import MultipleSelect from '@img/gif/multiple-select.gif';
import SpecificMultipleSelect from '@img/gif/specific-multiple-select.gif';
import AlignmentOptions from '@img/gif/alignment-options.gif';

const DemoSection = ({ gif, title, description, Icon, open, onClick }) => {
  const divRef = useRef(null);

  return (
    <div className={`alignment-demo ${open ? 'alignment-demo--open' : ''}`} onClick={onClick}>
      <span>
        <h3>{title}</h3>
        {Icon}
      </span>
      <div className="alignment-content" ref={divRef} style={open ? { height: `${divRef.current?.scrollHeight + 130}px` } : undefined}>
        <div className="alignment-content-image">
          <img src={gif} />
        </div>
        <p dangerouslySetInnerHTML={{ __html: description }} />
      </div>
    </div>
  );
};

const AlignmentModal = () => {
  const [active, setIsActive] = useState(false);
  const [open, setOpen] = useState(-1);

  return (
    <Fragment>
      <button type="button" className="navigation__icon navigation__icon--modal" onClick={() => setIsActive(!active)}>
        <p>{t('profile-manager.alignmentmodal')}</p>
      </button>

      {active && (
        <div className="alignment-modal">
          <div className="alignment-overlay" onClick={() => setIsActive(!active)}></div>
          <div className="alignment-grid">
            <div className="alignment-grid-inner">
              <div className="alignment-grid-close" onClick={() => setIsActive(false)}></div>
              <h2>{t('profile-manager.align.alignment-options')}</h2>
              <section className="demo-section">
                <DemoSection
                  open={open === 0}
                  onClick={() => setOpen(open === 0 ? -1 : 0)}
                  title={t('profile-manager.align.alignment-options.single-select')}
                  gif={SingleSelect}
                  Icon={<GrSelect />}
                  description={t('profile-manager.align.alignment-options.single-select.description')}
                />
                <DemoSection
                  open={open === 1}
                  onClick={() => setOpen(open === 1 ? -1 : 1)}
                  title={t('profile-manager.align.alignment-options.multiple-select')}
                  gif={MultipleSelect}
                  Icon={<GrSelection />}
                  description={t('profile-manager.align.alignment-options.multiple-select.description')}
                />
                <DemoSection
                  open={open === 2}
                  onClick={() => setOpen(open === 2 ? -1 : 2)}
                  title={t('profile-manager.align.alignment-options.specific-multiple-select')}
                  gif={SpecificMultipleSelect}
                  Icon={<GrProjects />}
                  description={t('profile-manager.align.alignment-options.specific-multiple-select.description')}
                />
                <DemoSection
                  open={open === 3}
                  onClick={() => setOpen(open === 3 ? -1 : 3)}
                  title={t('profile-manager.align.alignment-options.multiple-alignment')}
                  gif={AlignmentOptions}
                  Icon={<GrMultiple />}
                  description={t('profile-manager.align.alignment-options.multiple-alignment.description')}
                />
              </section>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default AlignmentModal;
