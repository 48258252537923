import React from 'react';
import { t } from '../translations/index';

const TemplateBayList = ({ bays, onTemplateBayClick, onAddAllBaysClick }) => (
  <>
    <div className="project-list">
      {bays.map((x, index) => (
        <button
          key={x.templateGuid}
          type="button"
          className="project-item project-item--square"
          onClick={() => {
            onTemplateBayClick(index);
          }}
        >
          <div className="project-item__img-wrapper">
            <div className="project-item__placeholder" />
          </div>

          <div className="project-item__details">
            <h3 className="project-item__title">{x.bayName}</h3>
            <br />
          </div>
        </button>
      ))}
    </div>

    <button type="button" className="btn" onClick={onAddAllBaysClick}>
      {t('profile-manager.add-all-bays')}
    </button>
  </>
);

export default TemplateBayList;
