import React from 'react';
import { connect } from 'react-redux';
import { toggleProductImages } from '@redux/actioncreators/app';
import Tooltip from '@components/navigation/Tooltip';
import { t } from '@components/translations';

const ToggleImages = ({ app, toggleProductImagesInternal }) => {
  const { showProductImages } = app;

  const tooltipText = showProductImages ? t('profile-manager.images.hide') : t('profile-manager.images.show');

  return (
    <button
        type="button"
        onClick={toggleProductImagesInternal}
        className={`navigation__icon navigation__icon--image ${showProductImages ? 'active' : ''}`}
    ><p>{t(tooltipText)}</p></button>
  );
};

const mapStateToProps = state => ({
  app: state.app.present.app,
});

const mapDispatchToProps = dispatch => ({
  toggleProductImagesInternal: () => dispatch(toggleProductImages()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ToggleImages);
