import { GRID_ACTIONS } from './grid';
import { PRODUCT_ACTIONS } from './product';
import { APP_ACTIONS } from './app';
import { USER_ACTIONS } from './user';
import { PROJECT_ACTIONS } from './project';
import { TOAST_ACTIONS } from './toast';
import { HISTORY_ACTIONS } from './history';

export default {
  APP: APP_ACTIONS,
  GRID: GRID_ACTIONS,
  PRODUCT: PRODUCT_ACTIONS,
  USER: USER_ACTIONS,
  PROJECT: PROJECT_ACTIONS,
  TOAST: TOAST_ACTIONS,
  HISTORY: HISTORY_ACTIONS,
};
