import React from 'react';
import HeavyDuty from '@img/svg/heavy-duty.svg';

const BayFooter = ({ showShadow = false }) => (
  <div className={`bay__footer ${showShadow ? 'bay__footer--shadow' : ''}`}>
    <img src={HeavyDuty} alt="Nothing But Heavy Duty" />
  </div>
);

export default BayFooter;
