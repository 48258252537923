import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { fetchProjectTemplates, createProject, createProjectFromTemplate } from '@redux/actioncreators/project';
import { addToast } from '@redux/actioncreators/toast';
import TOAST_TYPES from '@enums/ToastTypes';
import Loader from '@components/shared/Loader';
import ProjectTemplateList from './ProjectTemplateList';

const ProjectTemplateSection = ({
  user,
  project,
  match,
  createProjectInternal,
  createProjectFromTemplateInternal,
  fetchProjectTemplatesInternal,
  addToastInternal,
}) => {
  useEffect(() => {
    const { projectTypeGuid } = match.params;

    fetchProjectTemplatesInternal({ projectTypeGuid, userGuid: user.userGuid });
  }, []);

  const handleCreateEmptyTemplate = () => {
    const params = {
      projectUserGuid: user.userGuid,
      projectTypeGuid: match.params.projectTypeGuid,
      redirect: true,
    };

    createProjectInternal(params);
    addToastInternal('Project Created', { type: TOAST_TYPES.SUCCESS });
  };

  const handleCreateFromTemplate = templateGuid => {
    const params = {
      projectUserGuid: user.userGuid,
      projectTemplateGuid: templateGuid,
      redirect: true,
    };

    createProjectFromTemplateInternal(params);
    addToastInternal('Project Created From Template', { type: TOAST_TYPES.SUCCESS });
  };

  if (project.templatesLoading && project.templates.length === 0) {
    return (
      <div className="project-list__loader">
        <Loader />
      </div>
    );
  }

  return (
    <ProjectTemplateList
      showEmptyTemplate={false}
      templates={project.templates}
      onCreateEmptyTemplateClick={handleCreateEmptyTemplate}
      onTemplateClick={handleCreateFromTemplate}
    />
  );
};

const mapStateToProps = state => ({
  project: state.app.present.project,
  user: state.app.present.user,
});

const mapDispatchToProps = dispatch => ({
  fetchProjectTemplatesInternal: params => dispatch(fetchProjectTemplates(params)),
  addToastInternal: (message, settings) => dispatch(addToast(message, settings)),
  createProjectInternal: params => dispatch(createProject(params)),
  createProjectFromTemplateInternal: params => dispatch(createProjectFromTemplate(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProjectTemplateSection));
