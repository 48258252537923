import Cookies from 'js-cookie';

export const cookieKeys = {};

class CookieStorage {
  /**
   * Gets a value using the given key from cookies
   * @param {string} key
   * @returns undefined if not found
   */
  get(key) {
    const value = Cookies.get(key);

    if (value === undefined) {
      return undefined;
    }

    return JSON.parse(value);
  }

  /**
   * Sets a cookie.
   * @param {string} key
   * @param {*} value
   */
  set(key, value) {
    Cookies.set(key, JSON.stringify(value));
  }

  /**
   * Deletes a cookie.
   * @param key
   */
  delete(key) {
    Cookies.remove(key);
  }
}

export default new CookieStorage();
