import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { push } from 'connected-react-router';
import { fetchProjects, deleteProject } from '@redux/actioncreators/project';
import { addToast } from '@redux/actioncreators/toast';
import TOAST_TYPES from '@enums/ToastTypes';
import Loader from '@components/shared/Loader';
import ProjectList from './ProjectList';
import { t } from '../translations/index';

const ProjectSection = props => {
  const { user, goToBays, fetchProjectsInternal, deleteProjectInternal, addToastInternal, project } = props;

  useEffect(() => {
    fetchProjectsInternal({ userGuid: user.userGuid });
  }, []);

  const handleRemoveClick = (e, clickedProject) => {
    e.preventDefault();
    e.stopPropagation();

    addToastInternal(`${clickedProject.projectName} ${t('profile-manager.toast.was-removed')}`, { type: TOAST_TYPES.ERROR });
    deleteProjectInternal({ projectGuid: clickedProject.projectGuid, userGuid: user.userGuid });
  };

  if (project.projectsLoading && project.projects.length === 0) {
    return (
      <div className="project-list__loader">
        <Loader />
      </div>
    );
  }

  const handleProjectClick = (e, { projectGuid }) => {
    window.location.href = `/build/${projectGuid}`;
  };

  return (
    <ProjectList
      projects={project.projects}
      onProjectClick={handleProjectClick}
      onCreateProjectClick={goToBays}
      showRemove={true}
      onRemoveClick={handleRemoveClick}
    />
  );
};

const mapStateToProps = state => ({
  project: state.app.present.project,
  user: state.app.present.user,
});

const mapDispatchToProps = dispatch => ({
  fetchProjectsInternal: params => dispatch(fetchProjects(params)),
  deleteProjectInternal: params => dispatch(deleteProject(params)),
  addToastInternal: (message, settings) => dispatch(addToast(message, settings)),
  goToBays: () => dispatch(push(`/dashboard/bays/`)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProjectSection));
