export const PROJECT_ACTIONS = {
  FETCH_PROJECT: 'PROJECT/FETCH_PROJECT',
  FETCH_PROJECT_ERROR: 'PROJECT/FETCH_PROJECT_ERROR',

  FETCH_PROJECTS: 'PROJECT/FETCH_PROJECTS',
  FETCH_PROJECTS_SUCCESS: 'PROJECT/FETCH_PROJECTS_SUCCESS',
  FETCH_PROJECTS_ERROR: 'PROJECT/FETCH_PROJECTS_ERROR',

  SET_PROJECT: 'PROJECT/SET_PROJECT',
  SET_PROJECT_TEST: 'PROJECT/SET_PROJECT_TEST',
  CLEAR_PROJECT: 'PROJECT/CLEAR_PROJECT',

  SET_NAME: 'PROJECT/SET_NAME',
  SET_FIELD: 'PROJECT/SET_FIELD',
  UPDATE_QUANTITY: 'PROJECT/UPDATE_QUANTITY',

  FETCH_CREATE_PROJECT: 'PROJECT/FETCH_CREATE_PROJECT',
  FETCH_CREATE_PROJECT_SUCCESS: 'PROJECT/FETCH_CREATE_PROJECT_SUCCESS',
  FETCH_CREATE_PROJECT_ERROR: 'PROJECT/FETCH_CREATE_PROJECT_ERROR',

  FETCH_CREATE_PROJECT_FROM_TEMPLATE: 'PROJECT/FETCH_CREATE_PROJECT_FROM_TEMPLATE',
  FETCH_CREATE_PROJECT_FROM_TEMPLATE_SUCCESS: 'PROJECT/FETCH_CREATE_PROJECT_FROM_TEMPLATE_SUCCESS',
  FETCH_CREATE_PROJECT_FROM_TEMPLATE_ERROR: 'PROJECT/FETCH_CREATE_PROJECT_FROM_TEMPLATE_ERROR',

  FETCH_DELETE_PROJECT: 'PROJECT/FETCH_DELETE_PROJECT',
  FETCH_DELETE_PROJECT_SUCCESS: 'PROJECT/FETCH_DELETE_PROJECT_SUCCESS',
  FETCH_DELETE_PROJECT_ERROR: 'PROJECT/FETCH_DELETE_PROJECT_ERROR',

  FETCH_PROJECT_TYPES: 'PROJECT/FETCH_PROJECT_TYPES',
  FETCH_PROJECT_TYPES_SUCCESS: 'PROJECT/FETCH_PROJECT_TYPES_SUCCESS',
  FETCH_PROJECT_TYPES_ERROR: 'PROJECT/FETCH_PROJECT_TYPES_ERROR',

  CREATE_PROJECT_TYPE: 'PROJECT/CREATE_PROJECT_TYPE',
  CREATE_PROJECT_TYPE_SUCCESS: 'PROJECT/CREATE_PROJECT_TYPE_SUCCESS',
  CREATE_PROJECT_TYPE_ERROR: 'PROJECT/CREATE_PROJECT_TYPE_ERROR',

  DELETE_PROJECT_TYPE: 'PROJECT/DELETE_PROJECT_TYPE',
  DELETE_PROJECT_TYPE_SUCCESS: 'PROJECT/DELETE_PROJECT_TYPE_SUCCESS',
  DELETE_PROJECT_TYPE_ERROR: 'PROJECT/DELETE_PROJECT_TYPE_ERROR',

  CREATE_PROJECT_TEMPLATE: 'PROJECT/CREATE_PROJECT_TEMPLATE',
  CREATE_PROJECT_TEMPLATE_SUCCESS: 'PROJECT/CREATE_PROJECT_TEMPLATE_SUCCESS',
  CREATE_PROJECT_TEMPLATE_ERROR: 'PROJECT/CREATE_PROJECT_TEMPLATE_ERROR',

  FETCH_TEMPLATES_FOR_TYPE: 'PROJECT/FETCH_TEMPLATES_FOR_TYPE',
  FETCH_TEMPLATES_FOR_TYPE_SUCCESS: 'PROJECT/FETCH_TEMPLATES_FOR_TYPE_SUCCESS',
  FETCH_TEMPLATES_FOR_TYPE_ERROR: 'PROJECT/FETCH_TEMPLATES_FOR_TYPE_ERROR',

  FETCH_PDF: 'PROJECT/FETCH_PDF',
  FETCH_PDF_SUCCESS: 'PROJECT/FETCH_PDF_SUCCESS',
  FETCH_PDF_ERROR: 'PROJECT/FETCH_PDF_ERROR',

  SET_PDF_OPTION: 'PROJECT/SET_PDF_OPTION',
  UPDATE_BAR_NAME: 'PROJECT/UPDATE_BAR_NAME',
};
