import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { createProjectTemplate } from '@redux/actioncreators/project';

import { t, Translate } from '@components/translations';

const CreateTemplateForm = ({ setIsOpen, project, createProjectTemplateInternal }) => {
  const [formFields, setFormFields] = useState({ templateName: '', templateDescription: '' });

  const handleCustomCreate = () => {
    const params = {
      userGuid: project.projectUserGuid,
      ...formFields,
    };

    createProjectTemplateInternal(params);
    setIsOpen(false);
  };

  const updateFormField = (e, fieldName) => {
    const { value } = e.target;

    const newFormFields = { ...formFields };

    newFormFields[fieldName] = value;
    setFormFields(newFormFields);
  };

  return (
    <form>
      <div className="form__row">
        <h4 className="form__title">
          <Translate resourceString="profile-manager.customtemplate.create" />
        </h4>
      </div>
      <div className="form__row">
        <input
          id="txtName"
          type="text"
          placeholder={t('profile-manager.customtemplate.nameplaceholder')}
          value={formFields.templateName}
          onChange={e => updateFormField(e, 'templateName')}
        ></input>
        <label htmlFor="">
          <Translate resourceString="custom.name" />:
        </label>
      </div>
      <div className="form__row">
        <input
          id="txtDescription"
          type="text"
          placeholder={t('profile-manager.customtemplate.descriptionplaceholder')}
          value={formFields.templateDescription}
          onChange={e => updateFormField(e, 'templateDescription')}
        ></input>
        <label htmlFor="txtDescription">
          <Translate resourceString="description" />:
        </label>
      </div>
      <div className="form__footer">
        <button type="button" className="btn btn--primary" onClick={() => handleCustomCreate()}>
          <Translate resourceString="create" />
        </button>
      </div>
    </form>
  );
};

const mapStateToProps = state => ({
  project: state.app.present.project,
});

const mapDispatchToProps = dispatch => ({
  createProjectTemplateInternal: params => dispatch(createProjectTemplate(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CreateTemplateForm));
