/* eslint-disable */
// This is the decompiled jQuery getOffset() function
export function getOffset(element) {
  var docElem,
    win,
    rect,
    doc,
    elem = element;

  if (!elem) {
    return;
  }

  rect = elem.getBoundingClientRect();

  // Make sure element is not hidden (display: none) or disconnected
  if (rect.width || rect.height || elem.getClientRects().length) {
    doc = elem.ownerDocument;
    win = window;
    docElem = doc.documentElement;

    return {
      top: rect.top + win.pageYOffset - docElem.clientTop,
      left: rect.left + win.pageXOffset - docElem.clientLeft,
    };
  }
}
/* eslint-enable */

/* eslint-disable */
export function memorySizeOf(obj) {
  var bytes = 0;

  function sizeOf(obj) {
    if (obj !== null && obj !== undefined) {
      switch (typeof obj) {
        case 'number':
          bytes += 8;
          break;
        case 'string':
          bytes += obj.length * 2;
          break;
        case 'boolean':
          bytes += 4;
          break;
        case 'object':
          var objClass = Object.prototype.toString.call(obj).slice(8, -1);
          if (objClass === 'Object' || objClass === 'Array') {
            for (var key in obj) {
              if (!obj.hasOwnProperty(key)) continue;
              sizeOf(obj[key]);
            }
          } else bytes += obj.toString().length * 2;
          break;
      }
    }
    return bytes;
  }

  function formatByteSize(bytes) {
    if (bytes < 1024) return bytes + ' bytes';
    else if (bytes < 1048576) return (bytes / 1024).toFixed(3) + ' KiB';
    else if (bytes < 1073741824) return (bytes / 1048576).toFixed(3) + ' MiB';
    else return (bytes / 1073741824).toFixed(3) + ' GiB';
  }

  return formatByteSize(sizeOf(obj));
}
/* eslint-enable */

/**
 * calculate the zoom factor based on size of the child relative to the parent (so everything fits in)
 */
export function getScaleFactor(parent, child, margin) {
  // Get the parent div size
  const parentSize = {
    width: parent.width - margin * 2,
    height: parent.height - margin * 2,
  };

  // Calculate the scale
  return Math.min(parentSize.width / child.width, parentSize.height / child.height);
}

/**
 * Checks if the current client is IE.
 */
export function isIE() {
  /* eslint-disable */
  if (
    navigator.appName == 'Microsoft Internet Explorer' ||
    !!(navigator.userAgent.match(/Trident/) || navigator.userAgent.match(/rv:11/))
  ) {
    return true;
  }
  /* eslint-enable */
  return false;
}

export function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}
