import React from 'react';

const LegacyWarning = () => (
  <div className="legacy-warning">
    <div className="legacy-warning__inner">
      <p>
        The Milwaukee Profile Manager is not supported on legacy browsers or mobile devices.
        <br />
        <br />
        For the best experience we recommend using the latest release of&nbsp;
        <a href="https://www.google.com/chrome/" rel="noopener noreferrer" target="_blank">
          Google Chrome
        </a>
        .
      </p>
    </div>
  </div>
);

export default LegacyWarning;
