import { connect } from 'react-redux';

import { t } from './index';

const translate = ({ translations, translationsLoading, resourceString }) => {
  if (!resourceString) {
    return null;
  }

  if (translationsLoading === true || Object.keys(translations).length === 0) {
    return null;
  }

  return t(resourceString);
};

const mapStateToProps = state => ({
  translationsLoading: state.app.present.app.translationsLoading,
  translations: state.app.present.app.translations,
});

// const mapDispatchToProps = dispatch => ({
// });

export default connect(mapStateToProps, null)(translate);
