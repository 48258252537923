import Types from 'js/enums/Types';
import React from 'react';
import Bar from './Bar';
import PlaceHolder from './Placeholder';
import Product from './Product';

const Entity = ({
  articleNumber,
  entity: { entity, placeholder, bar },
  gridPitch,
  index,
  offsetX,
  offsetY,
  pegSizeInPt,
  productsLookup,
  showProductImages,
  showWhiteBorders,
}) => {
  switch (entity.type) {
    case Types.PLACEHOLDER:
      return <PlaceHolder placeholder={placeholder} entity={entity} pegSizeInPt={pegSizeInPt} />;

    case Types.PRODUCT:
      return (
        <Product
          articleNumber={articleNumber}
          entity={entity}
          gridPitch={gridPitch}
          index={index}
          offsetX={offsetX}
          offsetY={offsetY}
          pegSizeInPt={pegSizeInPt}
          productsLookup={productsLookup}
          showProductImages={showProductImages}
          showWhiteBorders={showWhiteBorders}
        />
      );

    case Types.BAR:
      return (
        <Bar
          bar={bar}
          entity={entity}
          gridPitch={gridPitch}
          index={index}
          pegSizeInPt={pegSizeInPt}
          productsLookup={productsLookup}
          showProductImages={showProductImages}
          showWhiteBorders={showWhiteBorders}
        />
      );

    default:
      return null;
  }
};

export default Entity;
