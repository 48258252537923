import React from 'react';
import { t } from '../translations/index';

export const Option = ({value, label, translateValue = false, translateLabel = false}) => {
    const valueTranslation = translateValue ? t(value) : value;
    const labelTranslation = translateLabel ? t(label) : label;

    return <option value={valueTranslation}>{labelTranslation}</option>;
}

const Dropdown = ({ children, value = null, onChange}) => {
  return (
    <select className="dropdown" value={value} onChange={onChange}>{children}</select>
  );
};

export default Dropdown;
