import ACTIONS from '@redux/actions';

const initialState = {
  bufferSize: 10,
  past: [],
  future: [],
  present: undefined,
};

export default function(state = initialState, action) {
  switch (action.type) {
    // case ACTIONS.HISTORY.LOG_HISTORY: {
    //   const newState = { ...state };
    //   const { app } = action.payload;

    //   const json = JSON.stringify(app);
    //   const historyClone = JSON.parse(json);

    //   // Add the present to the end of the past
    //   newState.past.push(historyClone);

    //   if (newState.past.length > newState.bufferSize) {
    //     newState.past.splice(0, 1);
    //   }

    //   return { ...newState };
    // }

    // case ACTIONS.HISTORY.SET_PRESENT: {
    //   const newState = { ...state };
    //   const { app } = action.payload;

    //   // Set the present
    //   newState.present = app;

    //   // clear the future
    //   newState.future = [];

    //   return { ...newState };
    // }

    // case ACTIONS.HISTORY.UNDO: {
    //   const newState = { ...state };

    //   // Get the last item from the past
    //   const newPresent = newState.past.pop();

    //   // if we don't have one, do nothing
    //   if (newPresent === undefined) {
    //     return state;
    //   }

    //   // Otherwise we set the present to be the item from the past
    //   newState.present = newPresent;

    //   // And we insert the old present to the start of the future
    //   newState.future.unshift({ ...state.present });

    //   return newState;
    // }

    // case ACTIONS.HISTORY.REDO: {
    //   const newState = { ...state };

    //   // get the first item from the future
    //   const newPresent = newState.future.shift();

    //   // If we don't have one do nothing
    //   if (newPresent === undefined) {
    //     return state;
    //   }

    //   // Otherwise we set the present to be the item from the future
    //   newState.present = newPresent;

    //   // Insert the old present at the end of the past
    //   newState.past.push({ ...state.present });

    //   return newState;
    // }

    // case ACTIONS.HISTORY.CLEAR: {
    //   const newState = { ...state };

    //   newState.past = [];
    //   newState.present = initialState.present;
    //   newState.future = [];

    //   return newState;
    // }

    default:
      return state;
  }
}
