/* eslint-disable */
export const SAVES = {
  EMPTY: [],
  BAY: [[], [], []],
  TEST: {
    WALLBAY: [
      {
        entity: { type: 'BAR', gridId: ['WALLBAY'], articleNumber: '4932430912', id: 'bar-1', x: 0, y: 2 },
        bar: {
          width: 40,
          height: 10,
          entities: [
            [
              { entity: { type: 'PRODUCT', articleNumber: '4932430912', x: 0, y: 0, id: '1' } },
              { entity: { type: 'PRODUCT', articleNumber: '4932430912', x: 150, y: 0, id: '2' } },
              { entity: { type: 'PRODUCT', articleNumber: '4932430912', x: 300, y: 0, id: '3' } },
            ],
            [
              { entity: { type: 'PRODUCT', articleNumber: '4932430912', x: 0, y: 0, id: '1' } },
              { entity: { type: 'PRODUCT', articleNumber: '4932430912', x: 150, y: 0, id: '2' } },
              { entity: { type: 'PRODUCT', articleNumber: '4932430912', x: 300, y: 0, id: '3' } },
            ],
            [
              { entity: { type: 'PRODUCT', articleNumber: '4932430912', x: 0, y: 0, id: '1' } },
              { entity: { type: 'PRODUCT', articleNumber: '4932430912', x: 150, y: 0, id: '2' } },
              { entity: { type: 'PRODUCT', articleNumber: '4932430912', x: 300, y: 0, id: '3' } },
            ],
          ],
        },
      },
      {
        entity: { gridId: ['WALLBAY'], id: '61978980-bcfb-4ef5-80e0-0333edb67d67', articleNumber: '4932430912', x: 0, y: 1 },
      },
      {
        entity: { gridId: ['WALLBAY'], id: '61978980-bcfb-4ef5-80e0-0333edb67d6a', articleNumber: '4932430912', x: 6, y: 1 },
      },
      {
        entity: { gridId: ['WALLBAY'], id: '60a1e0c4-c354-4ce1-93d3-c4d39edfbfe2', articleNumber: '48390531', x: 12, y: 1 },
      },
    ],
    OVERFLOW: [],
    quantities: {},
    personalDetails: {
      orderNumber: '',
      firstName: '',
      lastName: '',
      emailAddress: '',
      addressLine1: '',
      addressLine2: '',
      townCity: '',
      postCode: '',
      notes: '',
    },
  },
};
/* eslint-enable */
