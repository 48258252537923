export const GRID_ACTIONS = {
  POPULATE: 'GRID/POPULATE',
  ADD: 'GRID/ADD',
  REMOVE: 'GRID/REMOVE',
  ADD_BAR: 'GRID/ADD_BAR',
  MOVE_PRODUCT_POSITION: 'GRID/MOVE_PRODUCT_POSITION',
  UPDATE_POSITION: 'GRID/UPDATE_POSITION',
  UPDATE_MULTIPLE_POSITIONS: 'GRID/UPDATE_MULTIPLE_POSITIONS',
  ADD_BAR_CLOSE: 'GRID/ADD_BAR_CLOSE',
  ADD_BAR_OPEN: 'GRID/ADD_BAR_OPEN',
  ADD_BAR_EDIT: 'GRID/ADD_BAR_EDIT',
  UPDATE_BAR: 'GRID/UPDATE_BAR',
  FETCH_BARS: 'GRID/FETCH_BARS',
  FETCH_BARS_SUCCESS: 'GRID/FETCH_BARS_SUCCESS',
  FETCH_BARS_ERROR: 'GRID/FETCH_BARS_ERROR',
  CREATE_BAR: 'GRID/CREATE_BAR',
  CREATE_BAR_SUCCESS: 'GRID/CREATE_BAR_SUCCESS',
  CREATE_BAR_ERROR: 'GRID/CREATE_BAR_ERROR',
  PREVIEW_EXISTING_BAR: 'GRID/PREVIEW_EXISTING_BAR',

  DELETE_BAR: 'GRID/DELETE_BAR',
  DELETE_BAR_SUCCESS: 'GRID/DELETE_BAR_SUCCESS',
  DELETE_BAR_ERROR: 'GRID/DELETE_BAR_ERROR',

  ADD_BAR_ERROR: 'GRID/ADD_BAR_ERROR',
  CLEAR_BAR_ERRORS: 'GRID/CLEAR_BAR_ERRORS',

  CLEAR_ERRORS: 'GRID/CLEAR_ERRORS',

  SET_HOVER_PRODUCT: 'GRID/SET_HOVER_PRODUCT',
  ADD_BAY: 'GRID/ADD_BAY',
  ADD_BAY_START: 'GRID/ADD_BAY_START',
  REMOVE_BAY: 'GRID/REMOVE_BAY',

  CLEAR_OVERFLOW: 'GRID/CLEAR_OVERFLOW',
  CLEAR_OVERFLOW_GRID: 'GRID/CLEAR_OVERFLOW_GRID',

  SET_EDITING_PLACEHOLDER: 'GRID/SET_EDITING_PLACEHOLDER',

  SET_SELECTED_ENTITIES: 'GRID/SET_SELECTED_ENTITIES',
  SET_SELECTED_ENTITIES_FROM_ARRAY: 'GRID/SET_SELECTED_ENTITIES_FROM_ARRAY',
  CLEAR_SELECTED_ENTITIES: 'GRID/CLEAR_SELECTED_ENTITIES',

  ALIGN_ENTITIES: 'GRID/ALIGN_ENTITIES',

  OVERFLOW_CLOSE: 'GRID/OVERFLOW_CLOSE',
  OVERFLOW_OPEN: 'GRID/OVERFLOW_OPEN',
};
