import React from 'react';
import { connect } from 'react-redux';
import { redo } from '@redux/actioncreators/history';
import Tooltip from '@components/navigation/Tooltip';
import useKeyDown from '@hooks/useKeyDown';
import { t } from '@components/translations';

const Redo = ({ redoInternal, app }) => {
  useKeyDown({
    onKeyDown: redoInternal,
    ctrlMod: true,
    shiftMod: true,
    keyCode: 90,
  });

  return (
    <button
      type="button"
      onClick={redoInternal}
      className={`navigation__icon navigation__icon--redo ${app.future.length > 0 ? 'active' : ''}`}
    >
      <p>{`${t('redo')} (Ctrl + Shift + Z)`}</p>
    </button>
  );
};

const mapStateToProps = state => ({
  app: state.app,
});

const mapDispatchToProps = dispatch => ({
  redoInternal: () => dispatch(redo()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Redo);
