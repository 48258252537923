import querystring from 'querystring';
import CookieStorage from '@js/storage/CookieStorage';

class ApiService {
  getRequestSettings(verb, isDownload, params = {}) {
    const headers = new Headers({
      'Content-Type': 'application/json; charset=utf-8',
      Accept: 'application/json',
    });

    const bearerToken = CookieStorage.get(process.env.REACT_APP_PASSPORT_BEARER_COOKIE);
    headers.append('authorization', `Bearer ${bearerToken}`);

    const requestModel = {
      method: verb,
      mode: 'cors',
      headers,
    };

    if (verb !== 'GET' && verb !== 'DELETE') {
      requestModel.body = JSON.stringify(params);
    }

    return requestModel;
  }

  async request({ slug, verb, isDownload = false, params, controller = 'wallbay', useDataParam = true }) {
    let url = `${process.env.REACT_APP_API_ENDPOINT_BASE_URL}/api/${controller}/${slug}`;
    if (params !== undefined && (verb === 'GET' || verb === 'DELETE')) {
      url += '?';
      url += querystring.stringify({ ...params });
    }

    try {
      const response = await fetch(url, this.getRequestSettings(verb, isDownload, params));

      if (response.status === 500) {
        throw new Error(response.statusText);
      }

      const contentType = response.headers.get('Content-Type');
      if (contentType !== null && contentType.indexOf('application/json') > -1) {
        const responseData = await response.json();

        if (responseData.success === false) {
          throw responseData.errors;
        }

        if (responseData.data && useDataParam === true) {
          return { ...responseData.data, params };
        }

        return { ...responseData, params };
      }

      return { params };
    } catch (error) {
      throw new Error(error);
    }
  }
}

export default new ApiService();
