import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import SUB_NAVS from '@enums/SubNavs';
import Modal from '@components/shared/Modal';
import { t, Translate } from '@components/translations';
import { addEntity } from '@redux/actioncreators/grid';
import { closeSubNav } from '@redux/actioncreators/app';
import GRIDS from '@enums/Grids';
import BAR_EDIT_MODE from '@enums/BarEditMode';
import FILL_MODES from '@enums/FillModes';
import uuidv4 from 'uuid/v4';
import Types from 'js/enums/Types';
import GridService from 'js/grid/GridService';

const ProductSearch = ({ app, grid, addEntityInternal, closeSubNavInternal }) => {
  const [width, setWidth] = useState('');
  const [height, setHeight] = useState('');
  const [name, setName] = useState('');
  const [modalOpen, _setModalOpen] = useState(true);

  const editingPlaceholder = useMemo(() => {
    if (!grid.editingPlaceholder) {
      return undefined;
    }

    const entities = GridService.getEntitiesFromGrids(grid[GRIDS.WALLBAY][grid.editingPlaceholder.gridIndex], GRIDS.WALLBAY, grid);
    const placeholders = GridService.getAllPlaceholders(entities);

    const placeholderLookup = placeholders.find(x => x.entity.id === grid.editingPlaceholder.id);

    if (!placeholderLookup) {
      return undefined;
    }

    setWidth(placeholderLookup.placeholder.originalWidth);
    setHeight(placeholderLookup.placeholder.originalHeight);
    setName(placeholderLookup.placeholder.name);

    return placeholderLookup;
  }, [grid.editingPlaceholder]);

  const setModalOpen = value => {
    if (value === false) {
      closeSubNavInternal();
    }

    _setModalOpen(value);
  };

  useEffect(() => {
    if (app.subNav === SUB_NAVS.PLACEHOLDER) {
      setModalOpen(true);
    } else {
      setName('');
      setWidth('');
      setHeight('');
    }
  }, [app.subNav]);

  if (app.subNav !== SUB_NAVS.PLACEHOLDER) {
    return null;
  }

  const handleAdd = () => {
    const product = {
      width: parseInt(width, 10),
      height: parseInt(height, 10),
      name: name || `${t('profile-manager.placeholder')} ${width}x${height}`,
      articleNumber: `${uuidv4()}`,
    };

    let gridId = GRIDS.WALLBAY;

    if (grid.barEditMode !== BAR_EDIT_MODE.CLOSED) {
      gridId = GRIDS.BAR;
    }

    const entity = {
      id: uuidv4(),
      articleNumber: product.articleNumber,
      gridId,
      type: Types.PLACEHOLDER,
      gridIndex: grid.editingPlaceholder ? grid.editingPlaceholder.gridIndex : app.selectedBay,
    };

    const settings = {
      fillDirection: FILL_MODES.ROW,
    };

    const params = {
      entity,
      product,
      settings,
    };

    if (editingPlaceholder) {
      params.position = {
        x: editingPlaceholder.entity.x,
        y: editingPlaceholder.entity.y,
      };

      params.removeExisting = {
        ...editingPlaceholder.placeholder,
      };
    }

    addEntityInternal([params]);
    closeSubNavInternal();
  };

  return (
    <Modal showClose={true} isOpen={modalOpen} setIsOpen={setModalOpen}>
      <form className="min-400">
        <div className="form__row">
          <h4 className="form__title">
            {editingPlaceholder ? (
              <Translate resourceString="profile-manager.placeholder.edit" />
            ) : (
              <Translate resourceString="profile-manager.placeholder.create" />
            )}
          </h4>
        </div>
        <div className="form__row">
          <input
            id="txtName"
            type="text"
            placeholder={t('custom.name')}
            value={name}
            onChange={e => setName(e.currentTarget.value)}
          ></input>
          <label htmlFor="txtName">
            <Translate resourceString="custom.name" />:
          </label>
        </div>
        <div className="form__row">
          <input id="txtWidth" type="text" placeholder={t('width')} value={width} onChange={e => setWidth(e.currentTarget.value)}></input>
          <label htmlFor="txtWidth">
            <Translate resourceString="width" />:
          </label>
        </div>
        <div className="form__row">
          <input
            id="txtHeight"
            type="text"
            placeholder={t('height')}
            value={height}
            onChange={e => setHeight(e.currentTarget.value)}
          ></input>
          <label htmlFor="txtHeight">
            <Translate resourceString="height" />:
          </label>
        </div>
        <div className="form__footer">
          <button type="button" className="btn btn--primary" onClick={() => handleAdd()}>
            <Translate resourceString="create" />
          </button>
        </div>
      </form>
    </Modal>
  );
};

const mapStateToProps = state => ({
  app: state.app.present.app,
  grid: state.app.present.grid,
});

const mapDispatchToProps = dispatch => ({
  addEntityInternal: params => dispatch(addEntity(params)),
  closeSubNavInternal: () => dispatch(closeSubNav()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductSearch);
