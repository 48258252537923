export const PRODUCT_ACTIONS = {
  FETCH_SEARCH: 'PRODUCT/FETCH_SEARCH',
  FETCH_SEARCH_SUCCESS: 'PRODUCT/FETCH_SEARCH_SUCCESS',
  FETCH_SEARCH_ERROR: 'PRODUCT/FETCH_SEARCH_ERROR',

  FETCH_ARTICLE_NUMBERS: 'PRODUCT/FETCH_ARTICLE_NUMBERS',
  FETCH_ARTICLE_NUMBERS_SUCCESS: 'PRODUCT/FETCH_ARTICLE_NUMBERS_SUCCESS',
  FETCH_ARTICLE_NUMBERS_ERROR: 'PRODUCT/FETCH_ARTICLE_NUMBERS_ERROR',
  FETCH_ARTICLE_NUMBERS_RESET: 'PRODUCT/FETCH_ARTICLE_NUMBERS_RESET',

  FETCH_ALL_PEGS: 'PRODUCT/FECTH_ALL_PEGS',
  FETCH_ALL_PEGS_SUCCESS: 'PRODUCT/FETCH_ALL_PEGS_SUCCESS',
  FETCH_ALL_PEGS_ERROR: 'PRODUCT/FETCH_ALL_PEGS_ERROR',
};
