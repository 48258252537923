/* eslint-disable no-underscore-dangle */
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { browserHistory } from 'App';

export const appInsightsReactPlugin = new ReactPlugin();

class AppInsightsContext {
  current;

  constructor() {
    this.current = new ApplicationInsights({
      config: {
        instrumentationKey: process.env.REACT_APP_APPLICATION_INSIGHTS_KEY,
        enableAutoRouteTracking: false,
        extensions: [appInsightsReactPlugin],
        extensionConfig: {
          [appInsightsReactPlugin.identifier]: { history: browserHistory },
        },
      },
    });

    this.current.loadAppInsights();
  }
}

export default new AppInsightsContext();
