import React, { Fragment } from 'react';
import { Route, Switch } from 'react-router-dom';
import Toasts from '@components/toasts/Toasts';
import Build from '@js/pages/build/Build';
import Dashboard from '@js/pages/dashboards/Dashboard';
import CreateProject from '@js/pages/dashboards/CreateProject';
import SelectTemplate from '@js/pages/dashboards/SelectTemplate';
import Output from '@js/pages/build/Output';
import Error404 from '@js/pages/errors/Error404';
import ReactPdfPreview from './ReactPdf';

const AppRouter = () => {
  return (
    <Fragment>
      <Toasts />
      <Switch>
        {/* Dashboard Routes */}
        <Route exact path="/" component={Dashboard} />
        <Route exact path="/dashboard/" component={Dashboard} />
        <Route exact path="/dashboard/bays/" component={CreateProject} />
        <Route exact path="/dashboard/templates/:projectTypeGuid/" component={SelectTemplate} />

        {/* Build Routes */}
        <Route exact path="/build/:projectGuid?" component={Build} />
        <Route exact path="/build/output/:projectGuid?" component={Output} />
        <Route exact path="/build/react-pdf/:projectGuid" component={ReactPdfPreview} />

        {/* 404 Page */}
        <Route exact path="/404/" component={Error404} />

        {/* Catch all */}
        <Route component={Error404} />
      </Switch>
    </Fragment>
  );
};

export default AppRouter;
