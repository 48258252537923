import ACTIONS from '@redux/actions';

const pingMiddleware = store => next => action => {
  const { app } = store.getState().app.present;

  if (app.appLoading === true) {
    next({
      type: ACTIONS.APP.LOADED,
      payload: false,
    });
  }

  next(action);
};

export default pingMiddleware;
