import React from 'react';
import { connect } from 'react-redux';
import { undo } from '@redux/actioncreators/history';
import Tooltip from '@components/navigation/Tooltip';
import useKeyDown from '@hooks/useKeyDown';
import { t } from '@components/translations';

const Undo = ({ undoInternal, app }) => {
  useKeyDown({
    onKeyDown: undoInternal,
    ctrlMod: true,
    keyCode: 90,
  });

  return (
    <button
      type="button"
      onClick={undoInternal}
      className={`navigation__icon navigation__icon--undo ${app.past.length > 0 ? 'active' : ''}`}
    >
      <p>{`${t('undo')} (Ctrl + Z)`}</p>
    </button>
  );
};

const mapStateToProps = state => ({
  app: state.app,
});

const mapDispatchToProps = dispatch => ({
  undoInternal: () => dispatch(undo()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Undo);
