import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import querystring from 'querystring';

import { Translate } from '@components/translations';
import GridHeader from '@components/grid/GridHeader';
import ApiService from 'js/api/ApiService';

import { setPdfOption } from '@redux/actioncreators/project';

import PersonalDetails from './PersonalDetails';
import OrderDetails from './OrderDetails';
import Accordion from '../shared/Accordion';
import useRenderedPdf from '../client-pdf/useRenderedPdf';

const Inventory = ({ app, project, _setPdfOption }) => {
  const { startRender, isRendering, canRender } = useRenderedPdf();

  return (
    <div className="site-wide inventory">
      <GridHeader />
      <h3 className="center">
        <Translate resourceString="profile-manager.projectinventoryexport" />
      </h3>
      <form className="form form__border form__inventory">
        <div className="form__inventory-section">
          <PersonalDetails />
        </div>

        <div className="form__inventory-section">
          <h3 className="form__title">
            <Translate resourceString="profile-manager.wallbayinventory" />
          </h3>
          {project.projectBays.map((x, index) => (
            <Accordion key={x.wallbayBayGuid} title={`${index + 1}. ${x.bayName}`}>
              <OrderDetails index={index} />
            </Accordion>
          ))}
        </div>

        <div className="form__inventory-section">
          <h3 className="form__title">
            <Translate resourceString="profile-manager.exportoptions" />
          </h3>
          <a
            className="btn btn--inline btn--primary btn--download"
            href={`${process.env.REACT_APP_API_ENDPOINT_BASE_URL}/api/wallbay/export-project?${querystring.stringify({
              projectGUID: project.projectGuid,
              cultureCode: app.cultureCode,
              projectName: project.projectName,
            })}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Translate resourceString={isRendering ? 'generatingexcel' : 'downloadexcel'} />
          </a>
          <br />
          <hr />
          <p>
            <Translate resourceString="profile-manager.exportoptions.description" />
          </p>
          <input
            id="chkbox"
            type="checkbox"
            checked={project.pdfSettingAddPlanogram}
            onChange={e => _setPdfOption({ field: 'pdfSettingAddPlanogram', value: e.currentTarget.checked })}
          />
          <label htmlFor="chkbox">
            <Translate resourceString="profile-manager.exportoptions.planogram" />
          </label>

          <input
            id="chkbox2"
            type="checkbox"
            checked={project.pdfSettingUseProductImages}
            onChange={e => _setPdfOption({ field: 'pdfSettingUseProductImages', value: e.currentTarget.checked })}
          />
          <label htmlFor="chkbox2">
            <Translate resourceString="profile-manager.exportoptions.productimages" />
          </label>

          <input
            id="chkbox3"
            type="checkbox"
            checked={project.pdfSettingUseWhiteBorders}
            onChange={e => _setPdfOption({ field: 'pdfSettingUseWhiteBorders', value: e.currentTarget.checked })}
          />
          <label htmlFor="chkbox3">
            <Translate resourceString="profile-manager.exportoptions.outlines" />
          </label>

          <input
            id="chkbox4"
            type="checkbox"
            checked={project.pdfSettingAddProductDetailPages}
            onChange={e => _setPdfOption({ field: 'pdfSettingAddProductDetailPages', value: e.currentTarget.checked })}
          />
          <label htmlFor="chkbox4">
            <Translate resourceString="profile-manager.exportoptions.detailpages" />
          </label>

          <input
            id="chkbox5"
            type="checkbox"
            checked={project.pdfSettingAddInventoryPage}
            onChange={e => _setPdfOption({ field: 'pdfSettingAddInventoryPage', value: e.currentTarget.checked })}
          />
          <label htmlFor="chkbox5">
            <Translate resourceString="profile-manager.exportoptions.inventory" />
          </label>
        </div>

        <button
          className="btn btn--inline btn--primary btn--download"
          disabled={!canRender || isRendering}
          type="button"
          onClick={startRender}
        >
          <Translate resourceString={isRendering ? 'generatingpdf' : 'downloadpdf'} />
        </button>
      </form>
    </div>
  );
};

const mapStateToProps = state => ({
  project: state.app.present.project,
  app: state.app.present.app,
});

const mapDispatchToProps = dispatch => ({
  _setPdfOption: params => dispatch(setPdfOption(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Inventory));
