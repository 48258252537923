import React, { useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import GridService from '@js/grid/GridService';
import GRIDS from '@enums/Grids';
import { getAllPegs } from '@redux/actioncreators/grid';
import { updateProductQuantity } from '@redux/actioncreators/project';
import { Translate } from '@components/translations';
import NumberInput from '../shared/NumberInput';

const OrderDetails = props => {
  const { index, grid, project, getAllPegsInternal, updateProductQuantityInternal } = props;

  useEffect(() => {
    getAllPegsInternal();
  }, []);

  if (grid.pegsLoading === true || grid.pegs.length === 0 || grid.dataLoading === true) {
    return null;
  }

  if (grid[GRIDS.WALLBAY][index] === undefined) {
    return null;
  }

  const uniqueProducts = GridService.getUniqueProductsFromProjectBay(project.projectBays[index]);
  const entities = GridService.getEntitiesFromGrids(grid[GRIDS.WALLBAY][index], GRIDS.WALLBAY, grid);

  const totalPegs = GridService.getTotalPegs(grid.pegs, entities, grid.data, project.projectBays[index].quantities);
  const placeholders = GridService.getAllPlaceholders(entities);

  const handleChange = (product, value) => {
    updateProductQuantityInternal({ index, articleNumber: product.articleNumber, quantity: value });
  };

  const renderProduct = ({ articleNumber, quantity }) => {
    const product = grid.data.find(x => x.articleNumber === articleNumber);
    const quantityValue =
      project.projectBays[index].quantities && project.projectBays[index].quantities[product.articleNumber]
        ? project.projectBays[index].quantities[product.articleNumber]
        : quantity;

    return (
      <div key={product.articleNumber} className="form__product">
        <div className="form__product-name">
          <div className="form__bold">
            {product.articleNumber} - ({product.variantName})
          </div>
        </div>
        <div className="form__product-number">
          <NumberInput value={quantityValue} onChange={value => handleChange(product, value)} showControls={false} />
        </div>
      </div>
    );
  };

  const renderPlaceholder = placeholder => (
    <div key={placeholder.entity.articleNumber} className="form__product">
      <div className="form__product-name">
        <div className="form__bold">{placeholder.placeholder.name}</div>
      </div>
      <div className="form__product-number">
        {placeholder.placeholder.originalWidth} x {placeholder.placeholder.originalHeight}
      </div>
    </div>
  );

  const renderPeg = peg => (
    <div className="form__product" key={peg.articleNumber}>
      <div className="form__product-name">
        <div className="form__bold">{peg.title}</div>
      </div>
      <div className="form__product-number">
        <NumberInput value={peg.quantity} showControls={false} />
      </div>
    </div>
  );

  if (uniqueProducts.length === 0) {
    return (
      <div className="form__section">
        <h3 className="form__title">
          <Translate resourceString="profile-manager.bay.empty" />
        </h3>
      </div>
    );
  }

  return (
    <Fragment>
      <div className="form__section">
        <h3 className="form__title">
          <Translate resourceString="products" />
        </h3>
        <div className="form__product">
          <div className="form__product-name">
            <p className="form__sub">
              <Translate resourceString="products" />
            </p>
          </div>
          <div className="form__product-number form__product-number--label">
            <p className="form__sub">
              <Translate resourceString="quantity" />
            </p>
          </div>
        </div>
        {uniqueProducts.map(x => renderProduct(x))}
      </div>

      {totalPegs.length > 0 && (
        <div className="form__section">
          <h3 className="form__title">
            <Translate resourceString="profile-manager.output.pegtotals" />
          </h3>
          <div className="form__product">
            <div className="form__product-name">
              <p className="form__sub">
                <Translate resourceString="profile-manager.output.pegs" />
              </p>
            </div>
            <div className="form__product-number form__product-number--label">
              <p className="form__sub">
                <Translate resourceString="quantity" />
              </p>
            </div>
          </div>
          {totalPegs.map(x => renderPeg(x))}
        </div>
      )}

      {placeholders.length > 0 && (
        <div className="form__section">
          <h3 className="form__title">
            <Translate resourceString="profile-manager.placeholders" />
          </h3>
          <div className="form__product">
            <div className="form__product-name">
              <p className="form__sub">
                <Translate resourceString="profile-manager.output.name" />
              </p>
            </div>
            <div className="form__product-number form__product-number--label">
              <p className="form__sub">
                <Translate resourceString="profile-manager.output.size" />
              </p>
            </div>
          </div>
          {placeholders.map(x => renderPlaceholder(x))}
        </div>
      )}
    </Fragment>
  );
};

const mapStateToProps = state => ({
  grid: state.app.present.grid,
  project: state.app.present.project,
});

const mapDispatchToProps = dispatch => ({
  getAllPegsInternal: params => dispatch(getAllPegs(params)),
  updateProductQuantityInternal: params => dispatch(updateProductQuantity(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetails);
