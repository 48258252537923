import React, { useState, createContext, useContext } from 'react';
import debounce from 'lodash/debounce';
import GridService from '@js/grid/GridService';

const HoverContext = createContext();

export const HoverEntities = ({ children }) => {
  const [hoverEntities, setHoverEntitiesInternal] = useState([]);

  const setHoverEntities = debounce((hoverPosition, entities, origin = null) => {
    if (entities.length === 1) {
      const entity = entities[0];
      entity.x = hoverPosition.x;
      entity.y = hoverPosition.y;
      setHoverEntitiesInternal([entity]);
      return;
    }

    for (let i = 0; i < entities.length; i += 1) {
      const cell = entities[i];

      const position = GridService.getRelativePosition(origin, hoverPosition, cell);
      cell.x = position.x;
      cell.y = position.y;
    }

    setHoverEntitiesInternal(entities);
  }, 1);

  return <HoverContext.Provider value={{ hoverEntities, setHoverEntities }}>{children}</HoverContext.Provider>;
};

const useHoverEntity = () => {
  const { hoverEntities, setHoverEntities } = useContext(HoverContext);

  return { hoverEntities, setHoverEntities };
};

export default useHoverEntity;
