import ACTIONS from '@redux/actions';
import uuidv4 from 'uuid/v4';

export const addToast = (message, { type }) => (dispatch) => {
  const id = uuidv4();

  dispatch({
    type: ACTIONS.TOAST.ADD,
    payload: {
      id,
      type,
      message,
    },
  });

  setTimeout(() => dispatch(removeToast(id)), 3000);
};

export function removeToast(id) {
  return {
    type: ACTIONS.TOAST.REMOVE,
    payload: id,
  };
}
