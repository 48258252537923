import React, { createContext, useContext, useState, useEffect } from 'react';

const MousePositionContext = createContext();

export const MousePosition = ({ children }) => {
  const [position, setPosition] = useState({ X: null, Y: null });

  const setFromEvent = event => {
    setPosition({
      X: event.clientX + window.scrollX,
      Y: event.clientY + window.scrollY,
    });
  };

  useEffect(() => {
    window.addEventListener('mousemove', setFromEvent);

    return () => window.removeEventListener('mousemove', setFromEvent);
  }, []);

  return <MousePositionContext.Provider value={position}>{children}</MousePositionContext.Provider>;
};

const useMousePosition = () => {
  const position = useContext(MousePositionContext);
  return position;
};

export default useMousePosition;
