import { save } from 'js/redux/actioncreators/save';
import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import Modal from '../shared/Modal';
import { t } from '../translations/index';

const PopulateResults = ({ grid, _save }) => {
  const [isOpen, setIsOpen] = useState(grid.populateResults && grid.populateResults.length > 0);
  const copyRef = useRef(null);

  if (!grid.populateResults || grid.populateResults.length === 0) {
    return null;
  }

  const handleCopy = () => {
    if (!copyRef.current) {
      return;
    }

    /* Select the text field */
    copyRef.current.select();
    copyRef.current.setSelectionRange(0, 99999); /* For mobile devices */

    /* Copy the text inside the text field */
    navigator.clipboard.writeText(copyRef.current.value);
  };

  const handleClose = () => {
    _save();
    setIsOpen(false);
  };

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <div className="populate-results">
        <h3 className="populate-results__title">
          {t('profile-manager.error.productsizedchanged')} ({grid.populateResults.length})
        </h3>

        <ul className="populate-results__list">
          {grid.populateResults.map(x => (
            <li key={`${x.id}`}>{x.articleNumber}</li>
          ))}
        </ul>

        <textarea ref={copyRef} value={grid.populateResults.map(x => x.articleNumber).join('\r\n')}></textarea>

        <div className="populate-results__buttons">
          <button className="btn" onClick={handleCopy}>
            {t('profile-manager.copy')}
          </button>
          <button className="btn" onClick={handleClose}>
            {t('profile-manager.close')}
          </button>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = state => ({
  grid: state.app.present.grid,
});

const mapDispatchToProps = dispatch => ({
  _save: () => dispatch(save()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PopulateResults);
