import React from 'react';
import { View } from '@react-pdf/renderer';
import { brand } from './styles';
import Logo from './Logo';

const Header = () => (
  <View
    fixed
    style={{
      backgroundColor: brand.darkRed,
      color: 'white',
      display: 'block',
      paddingBottom: 23,
      paddingTop: 23,
      paddingLeft: brand.sideMargin,
    }}
  >
    <Logo />
  </View>
);

export default Header;
