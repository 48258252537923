import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { createProjectType } from '@redux/actioncreators/project';

import { t, Translate } from '@components/translations';

const CreateProjectTypeForm = ({ user, createProjectTypeInternal }) => {
  const [formFields, setFormFields] = useState({ displayName: '', height: 0, width: 0 });
  const [errors, setErrors] = useState({
    displayName: {
      value: false,
      condition: null,
    },
    width: {
      value: false,
      condition: /^([0-9]*)$/,
    },
    height: {
      value: false,
      condition: /^([0-9]*)$/,
    },
  });

  const handleCustomCreate = () => {
    const params = {
      userGuid: user.userGuid,
      ...formFields,
    };

    createProjectTypeInternal(params);
  };

  const updateFormField = (e, fieldName) => {
    const { value } = e.target;
    const { condition } = errors[fieldName];
    const newErrors = { ...errors };

    if (condition !== null && value.match(condition) === null) {
      newErrors[fieldName].value = true;
    } else {
      newErrors[fieldName].value = false;
      const newFormFields = { ...formFields };

      newFormFields[fieldName] = value;
      setFormFields(newFormFields);
    }

    setErrors(newErrors);
  };

  return (
    <form>
      <div className="form__row">
        <h4 className="form__title">
          <Translate resourceString="profile-manager.custombay" />
        </h4>
      </div>
      <div className="form__row">
        <input
          id="txtName"
          type="text"
          placeholder={t('profile-manager.custombay.nameplaceholder')}
          value={formFields.displayName}
          onChange={e => updateFormField(e, 'displayName')}
        ></input>
        <label htmlFor="txtName">
          <Translate resourceString="custom.name" />:
        </label>
      </div>
      <div className="form__row">
        <input id="txtWidth" type="text" placeholder="1000" value={formFields.width} onChange={e => updateFormField(e, 'width')}></input>
        <label htmlFor="txtWidth">
          <Translate resourceString="width" /> (mm):
        </label>
      </div>
      <div className="form__row">
        <input id="txtHeight" type="text" placeholder="1700" value={formFields.height} onChange={e => updateFormField(e, 'height')}></input>
        <label htmlFor="txtHeight">
          <Translate resourceString="height" /> (mm):
        </label>
      </div>
      <div className="form__footer">
        <button type="button" className="btn btn--primary" onClick={() => handleCustomCreate()}>
          <Translate resourceString="create" />
        </button>
      </div>
    </form>
  );
};

const mapStateToProps = state => ({
  user: state.app.present.user,
});

const mapDispatchToProps = dispatch => ({
  createProjectTypeInternal: params => dispatch(createProjectType(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CreateProjectTypeForm));
