import React, { useState } from 'react';
import AddBayOptions from 'js/components/grid/AddBayOptions';
import Modal from 'js/components/shared/Modal';
import { t } from 'js/components/translations/index';
import { connect } from 'react-redux';

const AddProject = ({ project }) => {
  const [showAddBayModal, setShowAddBayModal] = useState(false);

  return (
    <>
      <button
        type="button"
        disabled={project.projectBays.length === 4}
        className="navigation__icon navigation__icon--plus"
        onClick={() => setShowAddBayModal(true)}
      >
        <p>{t('profile-manager.addnewbay')}</p>
      </button>

      <Modal showClose isOpen={showAddBayModal} setIsOpen={setShowAddBayModal}>
        <AddBayOptions onComplete={() => setShowAddBayModal(false)} />
      </Modal>
    </>
  );
};

const mapStateToProps = state => ({
  project: state.app.present.project,
});

export default connect(mapStateToProps, null)(AddProject);
