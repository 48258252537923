export const APP_ACTIONS = {
  SAVE_FETCH: 'APP/SAVE_FETCH',
  SAVE: 'APP/SAVE',
  SAVE_FETCH_ERROR: 'APP/SAVE_FETCH_ERROR',
  IS_DIRTY: 'APP/IS_DIRTY',

  SET_CULTURE: 'APP/SET_CULTURE',

  OPEN_SUB_NAV: 'APP/OPEN_SUB_NAV',
  CLOSE_SUB_NAV: 'APP/CLOSE_SUB_NAV',
  OPEN_HOVER_NAV: 'APP/OPEN_HOVER_NAV',
  CLOSE_HOVER_NAV: 'APP/CLOSE_HOVER_NAV',
  CLEAR_NAVIGATION: 'APP/CLEAR_NAVIGATION',

  TOGGLE_PRODUCT_IMAGES: 'APP/TOGGLE_PRODUCT_IMAGES',
  TOGGLE_GRID_BACKGROUND: 'APP/TOGGLE_GRID_BACKGROUND',
  TOGGLE_BACKGROUND: 'APP/TOGGLE_BACKGROUND',

  SET_ZOOM: 'APP/SET_ZOOM',
  SET_ZOOM_TO_FIT: 'APP/SET_ZOOM_TO_FIT',

  FETCH_TRANSLATIONS: 'APP/FETCH_TRANSLATIONS',
  FETCH_TRANSLATIONS_SUCCESS: 'APP/FETCH_TRANSLATIONS_SUCCESS',
  FETCH_TRANSLATIONS_ERROR: 'APP/FETCH_TRANSLATIONS_ERROR',

  LOADED: 'APP/LOADED',
  SET_NETWORK_STATUS: 'APP/SET_NETWORK_STATUS',
  SET_SELECTED_BAY: 'APP/SET_SELECTED_BAY',

  SET_CUSTOM_BAR_NAME: 'APP/SET_CUSTOM_BAR_NAME',
  SET_CUSTOM_BAR_LEVEL: 'APP/SET_CUSTOM_BAR_LEVEL',

  TOGGLE_SHIFT_PRESSED: 'APP/TOGGLE_SHIFT_PRESSED',
  TOGGLE_CTRL_PRESSED: 'APP/TOGGLE_CTRL_PRESSED',
  DRAGSELECT_INITIALISED: 'APP/DRAGSELECT_INITIALISED',
};
