import actions from 'js/redux/actions/index';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Modal from '../shared/Modal';
import { t } from '../translations/index';

const GridErrors = ({ grid, _clearErrors }) => {
  const [isOpen, setIsOpen] = useState();

  useEffect(() => {
    setIsOpen(grid.errors.length > 0);
  }, [grid.errors]);

  const handleClose = () => {
    _clearErrors();
    setIsOpen(false);
  };

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <div className="populate-results">
        <h3 className="populate-results__title">{t('profile-manager.error')}</h3>

        <ul className="populate-results__list">
          {grid.errors.map(x => (
            <li key={`${x}`} dangerouslySetInnerHTML={{ __html: x }}></li>
          ))}
        </ul>

        <div className="populate-results__buttons">
          <button className="btn" onClick={handleClose}>
            {t('profile-manager.close')}
          </button>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = state => ({
  grid: state.app.present.grid,
});

const mapDispatchToProps = dispatch => ({
  _clearErrors: () => dispatch({ type: actions.GRID.CLEAR_ERRORS }),
});

export default connect(mapStateToProps, mapDispatchToProps)(GridErrors);
