import { Svg, G, Rect, Path } from '@react-pdf/renderer';
import React from 'react';

const DotSvg = ({ pegSizeInPt, x, y }) => {
  if (pegSizeInPt === undefined || x === undefined || y === undefined) return null;
  const top = y * pegSizeInPt;
  const left = x * pegSizeInPt;

  return (
    <Svg width={pegSizeInPt} height={pegSizeInPt} viewBox="0 0 25 25" style={{ position: 'absolute', top, left }}>
      <G id="Group_31660" data-name="Group 31660" transform="translate(-145 -41)">
        <Rect id="Rectangle_25230" data-name="Rectangle 25230" width="25" height="25" transform="translate(145 41)" fill="none" />
        <Path
          id="Path_3912"
          data-name="Path 3912"
          d="M3.5,0A3.5,3.5,0,1,1,0,3.5,3.5,3.5,0,0,1,3.5,0Z"
          transform="translate(154 50)"
          opacity="0.1"
          fill="black"
        />
      </G>
    </Svg>
  );
};

export default DotSvg;
