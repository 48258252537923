import React from 'react';
import Logo from '@img/svg/logo.svg';

const BayHeader = ({ showShadow = false }) => (
  <div className={`bay__header ${showShadow ? 'bay__header--shadow' : ''}`}>
    <img src={Logo} alt="TTI Milwaukee Wallbay" />
  </div>
);

export default BayHeader;
