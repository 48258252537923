import ACTIONS from '@redux/actions';

const initialState = {
  searchResults: [],
  noResults: false,
  isLoading: false,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.PRODUCT.FETCH_SEARCH: {
      const newState = JSON.parse(JSON.stringify(state));

      newState.isLoading = true;
      return newState;
    }

    case ACTIONS.PRODUCT.FETCH_SEARCH_SUCCESS: {
      const newState = JSON.parse(JSON.stringify(state));

      newState.searchResults = action.payload.productData;

      if (newState.searchResults.length === 0) {
        newState.noResults = true;
      } else {
        newState.noResults = false;
      }

      newState.isLoading = false;
      return newState;
    }

    default: {
      return state;
    }
  }
}
