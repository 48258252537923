import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import GRIDS from '@enums/Grids';
import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { clearNavigation, setSelectedBay } from '@redux/actioncreators/app';
import BAR_EDIT_MODE from '@enums/BarEditMode';
import { moveProductPosition } from '@redux/actioncreators/grid';

import CellContextMenu from '@components/grid/CellContextMenu';
import BayListContextMenu from '@components/grid/BayListContextMenu';
import Navigation from '@components/navigation/Navigation';
import GridHeader from '@components/grid/GridHeader';
import GridBarModal from '@components/grid/GridBarModal';
import ToggleGrid from '@components/navigation/buttons/ToggleGrid';
import ToggleBackground from '@components/navigation/buttons/ToggleBackground';
import ToggleImages from '@components/navigation/buttons/ToggleImages';
import Undo from '@components/navigation/buttons/Undo';
import Redo from '@components/navigation/buttons/Redo';
import Overflow from 'js/components/grid/Overflow';

import AddProductsOptions from '@components/navigation/buttons/AddProductsOptions';
import ProductSearch from '@components/navigation/sub-navigations/ProductSearch';
import ArticleNumberImport from '@components/navigation/sub-navigations/ArticleNumberImport';
import Placeholder from '@components/navigation/sub-navigations/Placeholder';
import BarList from '@components/navigation/sub-navigations/BarList';
import withProject from '@js/hocs/withProject';
import useGlobalCssVariable from '@hooks/useGlobalCssVariable';
import { MousePosition } from '@hooks/useMousePosition';
import { HoverEntities } from '@hooks/useHoverEntity';
import { SelectedEntities } from '@hooks/useSelectedEntities';
import ProductInfo from 'js/components/grid/ProductInfo';
import Loader from 'js/components/shared/Loader';
import PopulateResults from 'js/components/grid/PopulateResults';
import AddNewBay from 'js/components/navigation/buttons/AddNewBay';
import GridErrors from 'js/components/grid/GridErrors';
import BuildInner from './BuildInner';

const Build = props => {
  const { app, setSelectedBayInternal, moveProductPositionInternal, grid, project } = props;
  const mainRef = useRef(null);

  useEffect(() => {
    if (project.projectBays.length === 1) {
      setSelectedBayInternal(0);
    } else {
      setSelectedBayInternal(-1);
    }
  }, [project.projectBays.length]);

  useEffect(() => {
    if (mainRef.current === null || grid.scrollToLastBay === 0 || project.projectLoading || grid.isPopulated === false) {
      return;
    }

    mainRef.current.scrollTo(mainRef.current.scrollWidth, 0);
  }, [grid.scrollToLastBay, project.projectLoading, grid.isPopulated]);

  useGlobalCssVariable('--zoom', app.zoom);

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="body__bg"></div>

      <Navigation showSave>
        <AddProductsOptions />
        <AddNewBay />
        <ToggleGrid />
        <ToggleBackground />
        <ToggleImages />
        <Undo />
        <Redo />
      </Navigation>

      {grid.dataLoading || !grid.isPopulated ? (
        <Loader />
      ) : (
        <main ref={mainRef}>
          <ProductSearch />
          <BarList />
          <ArticleNumberImport />
          <Placeholder />
          <ProductInfo />
          <CellContextMenu />
          <BayListContextMenu />

          <Overflow />

          <PopulateResults />
          <GridErrors />

          <MousePosition>
            <HoverEntities>
              <SelectedEntities onMove={moveProductPositionInternal} gridId={GRIDS.WALLBAY}>
                {(grid.barEditMode === BAR_EDIT_MODE.ADD ||
                  grid.barEditMode === BAR_EDIT_MODE.EDIT ||
                  grid.barEditMode === BAR_EDIT_MODE.PREVIEW_EXISTING) && <GridBarModal />}
                <GridHeader />

                {project.projectBays.length > 0 && <BuildInner />}
              </SelectedEntities>
            </HoverEntities>
          </MousePosition>
        </main>
      )}
    </DndProvider>
  );
};

const mapStateToProps = state => ({
  app: state.app.present.app,
  grid: state.app.present.grid,
  project: state.app.present.project,
});

const mapDispatchToProps = dispatch => ({
  clearNavigationInternal: () => dispatch(clearNavigation()),
  setSelectedBayInternal: index => dispatch(setSelectedBay(index)),
  moveProductPositionInternal: params => dispatch(moveProductPosition(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withProject(Build));
