import React from 'react';
import { connect } from 'react-redux';
import { toggleBackground } from '@redux/actioncreators/app';
import Tooltip from '@components/navigation/Tooltip';
import { t } from '@components/translations';

const ToggleBackground = ({ app, toggleBackgroundInternal }) => {
  const { showBackgrounds } = app;

  const tooltipText = showBackgrounds ? t('profile-manager.backgrounds.hide') : t('profile-manager.backgrounds.show');

  return (
    <button
        type="button"
        onClick={toggleBackgroundInternal}
        className={`navigation__icon navigation__icon--background ${showBackgrounds ? 'active' : ''}`}
      ><p>{t(tooltipText)}</p></button>
  );
};

const mapStateToProps = state => ({
  app: state.app.present.app,
});

const mapDispatchToProps = dispatch => ({
  toggleBackgroundInternal: () => dispatch(toggleBackground()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ToggleBackground);
