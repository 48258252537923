import React from 'react';
import Navigation from '@components/navigation/Navigation';

import ProjectTemplateSection from '@components/projects/ProjectTemplateSection';
import { Translate } from '@components/translations';

const SelectTemplate = () => (
  <React.Fragment>
    <div className="body__bg"></div>
    <Navigation></Navigation>
    <main>
      <div className="site-wide">
        <div className="tabs">
          <button type="button" className="tab active">
            <Translate resourceString="profile-manager.templates" />
          </button>
        </div>
        <p className="tabs__title">
          <Translate resourceString="profile-manager.templates.description" />
        </p>

        <ProjectTemplateSection />
      </div>
    </main>
  </React.Fragment>
);

export default SelectTemplate;
