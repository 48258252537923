import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { fetchBars, deleteBar, previewExistingBar } from '@redux/actioncreators/grid';
import { usePassportContext, PassportEnums } from '@tti/passport';
import GRIDS from '@enums/Grids';
import SUB_NAVS from '@enums/SubNavs';
import Modal from '@components/shared/Modal';
import { closeSubNav, openSubNav } from 'js/redux/actioncreators/app';
import { t } from 'js/components/translations/index';

const BarList = props => {
  const [tab, setTab] = useState(0);
  const {
    app,
    grid,
    project,
    fetchBarsInternal,
    previewExistingBarInternal,
    deleteBarInternal,
    openSubNavInternal,
    closeSubNavInternal,
  } = props;
  const { passportContext, getClaims } = usePassportContext();
  const isGlobalAdmin =
    getClaims(PassportEnums.ClaimType.Role, passportContext.claims).find(
      x => x.value === PassportEnums.RoleType.ProfileManagerGlobalAdmin,
    ) !== undefined;

  const selectedBay = project.projectBays[app.selectedBay];

  useEffect(() => {
    if (app.subNav !== SUB_NAVS.BAR_LIST || selectedBay === undefined) {
      return;
    }

    fetchBarsInternal({ wallbayTypeGuid: selectedBay.wallbayTypeGuid });
  }, [app.subNav]);

  const handleDelete = (e, wallbayBarGuid) => {
    e.stopPropagation();

    deleteBarInternal({ wallbayBarGuid, wallbayTypeGuid: selectedBay.wallbayTypeGuid });
  };

  const handleClick = params => {
    openSubNavInternal(null);
    previewExistingBarInternal(params);
  };

  const renderBar = (bar, showDelete) => {
    const barData = JSON.parse(bar.jsonData);
    const params = {
      wallbayBarGuid: bar.wallbayBarGuid,
      articleNumbers: barData.entities.map(x => x.entity.articleNumber),
      gridSettings: grid.settings[GRIDS.WALLBAY],
    };

    return (
      <button type="button" key={bar.wallbayBarGuid} className="list-item" onClick={() => handleClick(params)}>
        <h3 className="list-item__title">{bar.barName}</h3>
        {showDelete && (
          <button type="button" className="list-item__remove" tabIndex="-1" onClick={e => handleDelete(e, bar.wallbayBarGuid)}></button>
        )}
      </button>
    );
  };

  const handleClose = () => {
    closeSubNavInternal();
  };

  return (
    <Modal showClose={true} isOpen={app.subNav === SUB_NAVS.BAR_LIST} setIsOpen={handleClose}>
      <div className="modal__min">
        <div className="tabs">
          <button
            type="button"
            className={`tab ${tab === 0 ? 'active' : ''}`}
            onClick={() => setTab(0)}
            dangerouslySetInnerHTML={{ __html: t('profile-manager.bars.user') }}
          ></button>
          <button
            type="button"
            className={`tab ${tab === 1 ? 'active' : ''}`}
            onClick={() => setTab(1)}
            dangerouslySetInnerHTML={{ __html: t('profile-manager.bars.global') }}
          ></button>
        </div>

        {grid.bars.isLoading ? (
          <div className="loader-container loader-container--300">
            <div className="loader"></div>
          </div>
        ) : (
          <>
            {tab === 0 ? <div className="list">{grid.bars.userBars.map(x => renderBar(x, true))}</div> : null}

            {tab === 1 ? <div className="list">{grid.bars.globalBars.map(x => renderBar(x, isGlobalAdmin))}</div> : null}
          </>
        )}
      </div>
    </Modal>
  );
};

const mapStateToProps = state => ({
  grid: state.app.present.grid,
  app: state.app.present.app,
  project: state.app.present.project,
});

const mapDispatchToProps = dispatch => ({
  fetchBarsInternal: params => dispatch(fetchBars(params)),
  deleteBarInternal: params => dispatch(deleteBar(params)),
  previewExistingBarInternal: params => dispatch(previewExistingBar(params)),
  closeSubNavInternal: () => dispatch(closeSubNav()),
  openSubNavInternal: params => dispatch(openSubNav(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BarList);
