import React, { Fragment, useState } from 'react';
import Tooltip from '@components/navigation/Tooltip';
import Modal from '@components/shared/Modal';
import CreateTemplateForm from '@components/projects/CreateTemplateForm';
import { t } from '@components/translations';

const SaveAsTemplate = () => {
  const [showTemplateModal, setShowTemplateModal] = useState(false);

  return (
    <Fragment>
      <button type="button" className="navigation__icon navigation__icon--save" onClick={() => setShowTemplateModal(true)}><p>{t('profile-manager.savetemplate')}</p></button>
      <Modal showClose isOpen={showTemplateModal} setIsOpen={setShowTemplateModal}>
        <CreateTemplateForm setIsOpen={setShowTemplateModal} />
      </Modal>
    </Fragment>
  );
};

// const mapStateToProps = state => ({
// });

// const mapDispatchToProps = dispatch => ({
//   goToOutput: projectGuid => dispatch(push(`/build/output/${projectGuid}`)),
// });

export default SaveAsTemplate;
