import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import uniqueId from 'lodash/uniqueId';
import { Translate } from '@components/translations';
import { setProjectField } from '@redux/actioncreators/project';
import { t } from '../translations/index';

const PersonalDetails = ({ project, _setProjectField }) => {
  const [fields, setFields] = useState({});

  useEffect(() => {
    setFields({
      customerAddressLine1: project.customerAddressLine1,
      customerAddressLine2: project.customerAddressLine2,
      customerEmailAddress: project.customerEmailAddress,
      customerFirstName: project.customerFirstName,
      customerLastName: project.customerLastName,
      customerNotes: project.customerNotes,
      customerOrderNumber: project.customerOrderNumber,
      customerPostcode: project.customerPostcode,
      customerTownCity: project.customerTownCity,
    });
  }, [project]);

  const handleFieldChange = (fieldName, value) => {
    const newState = { ...fields };

    newState[fieldName] = value;
    setFields(newState);
    _setProjectField({ fieldName, value });
  };

  const renderField = (fieldName, fieldLabel, multiLine = false) => {
    const inputId = uniqueId(`input_${fieldName}`);

    if (multiLine) {
      return (
        <div className="form__row">
          <textarea
            id={inputId}
            value={fields[fieldName]}
            placeholder={t(fieldLabel)}
            onChange={e => handleFieldChange(fieldName, e.currentTarget.value)}
          />
          <label id={uniqueId(`label_${fieldName}`)} htmlFor={inputId} className="align-start">
            <Translate resourceString={fieldLabel} />
          </label>
        </div>
      );
    }

    return (
      <div className="form__row">
        <input
          id={inputId}
          type="text"
          value={fields[fieldName]}
          placeholder={t(fieldLabel)}
          onChange={e => handleFieldChange(fieldName, e.currentTarget.value)}
        />
        <label id={uniqueId(`label_${fieldName}`)} htmlFor={inputId}>
          <Translate resourceString={fieldLabel} />
        </label>
      </div>
    );
  };

  return (
    <Fragment>
      <h3 className="form__title">
        <Translate resourceString="customerdetails" />
      </h3>
      <div className="form__split">
        {renderField('customerOrderNumber', 'ordernumber')}
        <div className="form__row"></div>
      </div>
      <div className="form__split">
        {renderField('customerFirstName', 'custom.firstname')}
        {renderField('customerLastName', 'custom.lastname')}
      </div>
      {renderField('customerEmailAddress', 'custom.emailaddress')}
      {renderField('customerAddressLine1', 'custom.reviews.addressline1')}
      {renderField('customerAddressLine2', 'custom.reviews.addressline2')}
      {renderField('customerTownCity', 'custom.reviews.towncity')}
      {renderField('customerPostcode', 'custom.reviews.postcode')}
      {renderField('customerNotes', 'notes', true)}
    </Fragment>
  );
};

const mapStateToProps = state => ({
  project: state.app.present.project,
});

const mapDispatchToProps = dispatch => ({
  _setProjectField: params => dispatch(setProjectField(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PersonalDetails);
