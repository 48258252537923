import uuidv4 from 'uuid/v4';
import uniq from 'lodash/uniq';

import ACTIONS from '@redux/actions';
import GRIDS from '@enums/Grids';
import ApiService from '@js/api/ApiService';
import GridService from '@js/grid/GridService';
import TOAST_TYPES from '@enums/ToastTypes';
import { SAVES } from '@js/data';
import TYPES from '@enums/Types';
import { t } from 'js/components/translations';
import { addToast } from './toast';
import { fetchProject } from './project';
import { save } from './save';

export const addNewBay = (params, shouldFetchProject) => async (dispatch, getState) => {
  // then populate the grid
  dispatch({
    type: ACTIONS.GRID.ADD_BAY_START,
    payload: params,
  });

  await ApiService.request({
    slug: 'create-bay',
    verb: 'POST',
    params: params.bays.map(x => ({
      wallbayProjectGuid: x.wallbayProjectGuid,
      wallbayTypeGuid: x.wallbayTypeGuid,
      wallbayBayGuid: x.wallbayBayGuid,
      bayName: x.bayName,
      jsonData: JSON.stringify(x.data ? x.data : SAVES.BAY),
    })),
  });

  const addBayAction = {
    type: ACTIONS.GRID.ADD_BAY,
    payload: params,
  };

  if (shouldFetchProject) {
    dispatch(addBayAction);

    return dispatch(fetchProject(params.wallbayProjectGuid, params.test));
  }

  return dispatch(addBayAction);
};

export const removeBay = params => async dispatch => {
  await ApiService.request({
    slug: 'delete-bay',
    verb: 'DELETE',
    params: {
      bayGuid: params.bayGuid,
    },
  });

  dispatch({
    type: ACTIONS.GRID.REMOVE_BAY,
    payload: params,
  });

  return dispatch(fetchProject(params.wallbayProjectGuid, params.test));
};

export function removeEntity(params) {
  return {
    type: ACTIONS.GRID.REMOVE,
    payload: params,
  };
}

export const populate = params => async (dispatch, getState) => {
  const state = getState();
  const { cultureCode } = state.app.present.app;
  const { project } = state.app.present;

  // get article numbers from the project data json
  const uniqueProducts = GridService.getUniqueProductsFromProject(project);
  const articleNumbers = uniqueProducts.map(x => x.articleNumber);

  if (articleNumbers.length > 0) {
    // then do request to get all products from article numbers
    dispatch({
      type: ACTIONS.PRODUCT.FETCH_ARTICLE_NUMBERS,
    });

    const response = await ApiService.request({ slug: 'get-products-by-article', verb: 'POST', params: { articleNumbers, cultureCode } });

    dispatch({
      type: ACTIONS.PRODUCT.FETCH_ARTICLE_NUMBERS_SUCCESS,
      payload: { ...response },
    });
  }

  // then populate the grid
  dispatch({
    type: ACTIONS.GRID.POPULATE,
    payload: { ...params, project },
  });
};

export const addOverflowEntities = () => async (dispatch, getState) => {
  let state = getState();

  if (state.app.present.grid.overflow.length === 0) {
    return;
  }

  const actions = [];
  const overflow = JSON.parse(JSON.stringify(state.app.present.grid.overflow));

  // If there's overflow but we can't add any more bays, repurpose the overflow into a modal instead //
  if (state.app.present.project.projectBays.length === 4) {
    dispatch(openOverflowModal());
  } else {
    dispatch({
      type: ACTIONS.GRID.CLEAR_OVERFLOW,
    });

    for (let index = 0; index < overflow.length; index += 1) {
      const overflowAction = overflow[index];

      overflowAction.entity.gridId = GRIDS.OVERFLOW;
      overflowAction.entity.gridIndex = 0;

      actions.push(overflowAction);
    }

    dispatch({
      type: ACTIONS.GRID.ADD,
      payload: actions,
    });

    state = getState();

    // Get the entities out of the overflow grid
    const entities = GridService.getEntitiesFrom2d(state.app.present.grid[GRIDS.OVERFLOW][0][0], GRIDS.OVERFLOW, state.app.present.grid);

    for (let index = 0; index < entities.length; index += 1) {
      const entity = entities[index];
      entity.entity.gridId = GRIDS.WALLBAY;
    }

    const lastBay = state.app.present.project.projectBays[state.app.present.project.projectBays.length - 1];

    if (!lastBay) {
      return;
    }

    const bayParams = {
      wallbayProjectGuid: state.app.present.project.projectGuid,
      bays: [
        {
          wallbayProjectGuid: state.app.present.project.projectGuid,
          wallbayTypeGuid: lastBay.wallbayTypeGuid,
          bayName: t('profile-manager.bay.defaultname'),
          data: [entities, [], []],
        },
      ],
    };

    dispatch({
      type: ACTIONS.GRID.CLEAR_OVERFLOW_GRID,
      payload: { project: state.app.present.project },
    });

    await dispatch(save());

    await dispatch(addNewBay(bayParams, false));

    await dispatch(fetchProject(bayParams.wallbayProjectGuid));

    dispatch(addOverflowEntities());
  }
};

export const addEntity = params => async dispatch => {
  dispatch({
    type: ACTIONS.GRID.ADD,
    payload: params,
  });

  return dispatch(addOverflowEntities());
};

export function moveProductPosition({ gridId, ids, direction, gridLevel, gridIndex, amount = 1 }) {
  return {
    type: ACTIONS.GRID.MOVE_PRODUCT_POSITION,
    payload: {
      gridId,
      ids,
      direction,
      amount,
      gridLevel,
      gridIndex,
    },
  };
}

export const getAllPegs = params => async (dispatch, getState) => {
  dispatch({
    type: ACTIONS.PRODUCT.FETCH_ALL_PEGS,
  });

  try {
    const state = getState();
    const { cultureCode } = state.app.present.app;

    const requestParams = {
      cultureCode,
      ...params,
    };

    const response = await ApiService.request({ slug: 'get-all-pegs', verb: 'GET', params: requestParams });

    dispatch({
      type: ACTIONS.PRODUCT.FETCH_ALL_PEGS_SUCCESS,
      payload: { ...response },
    });
  } catch (error) {
    dispatch({ type: ACTIONS.PRODUCT.FETCH_ALL_PEGS_ERROR, payload: error });
  }
};

export function updateProductPosition(params) {
  console.log(params);
  return {
    type: ACTIONS.GRID.UPDATE_POSITION,
    payload: params,
  };
}

export function updateProductsPositions(selectedIndexes, initialPosition, dropPosition) {
  return {
    type: ACTIONS.GRID.UPDATE_MULTIPLE_POSITIONS,
    payload: { selectedIndexes, initialPosition, dropPosition },
  };
}

export const addArticleNumbers = ({ articleNumbers, settings, gridId, gridIndex, logResults }) => async (dispatch, getState) => {
  const state = getState();
  const { cultureCode } = state.app.present.app;
  const gridSettings = state.app.present.grid.settings[gridIndex];

  // Reset the 'success' and 'failed' count //
  dispatch({
    type: ACTIONS.PRODUCT.FETCH_ARTICLE_NUMBERS_RESET,
  });

  dispatch({
    type: ACTIONS.PRODUCT.FETCH_ARTICLE_NUMBERS,
  });

  const entityAddActions = [];

  try {
    // Fetch the products using the article numbers
    const uniqueArticleNumbers = uniq(articleNumbers);
    const response = await ApiService.request({
      slug: 'get-products-by-article',
      verb: 'POST',
      params: { articleNumbers: uniqueArticleNumbers, cultureCode },
      includeErrors: true,
    });

    dispatch({
      type: ACTIONS.PRODUCT.FETCH_ARTICLE_NUMBERS_SUCCESS,
      payload: { ...response, logResults, gridSettings },
    });

    // now Add the entities to the grid
    for (let index = 0; index < articleNumbers.length; index += 1) {
      const articleNumber = articleNumbers[index];
      const product = response.productData.find(x => x.articleNumber === articleNumber);

      if (!product) {
        console.log(`Api didn't return a product for ${articleNumber}`);
        // eslint-disable-next-line no-continue
        continue;
      }

      const entity = {
        type: TYPES.PRODUCT,
        id: uuidv4(),
        articleNumber,
        gridId,
        gridIndex,
      };

      entityAddActions.push({
        product,
        entity,
        settings,
        gridSettings,
      });
    }

    dispatch(addEntity(entityAddActions));
  } catch (error) {
    console.log(error);
    console.log(gridSettings);
    console.log(gridIndex);

    dispatch({
      type: ACTIONS.PRODUCT.FETCH_ARTICLE_NUMBERS_ERROR,
      payload: error,
    });
  }
};

export const fetchBars = ({ wallbayTypeGuid }) => async dispatch => {
  dispatch({
    type: ACTIONS.GRID.FETCH_BARS,
  });

  try {
    const response = await ApiService.request({
      slug: 'get-bars-for-type',
      verb: 'GET',
      params: { wallbayTypeGuid },
      includeErrors: true,
    });

    dispatch({
      type: ACTIONS.GRID.FETCH_BARS_SUCCESS,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: ACTIONS.GRID.FETCH_BARS_ERROR,
      payload: error,
    });
  }
};

export const previewExistingBar = ({ wallbayBarGuid, articleNumbers, gridSettings }) => async (dispatch, getState) => {
  const state = getState();
  const { cultureCode } = state.app.present.app;

  if (articleNumbers.length > 0) {
    // then do request to get all products from article numbers
    dispatch({
      type: ACTIONS.PRODUCT.FETCH_ARTICLE_NUMBERS,
    });

    const response = await ApiService.request({ slug: 'get-products-by-article', verb: 'POST', params: { articleNumbers, cultureCode } });

    dispatch({
      type: ACTIONS.PRODUCT.FETCH_ARTICLE_NUMBERS_SUCCESS,
      payload: { ...response, gridSettings },
    });
  }

  return dispatch({
    type: ACTIONS.GRID.PREVIEW_EXISTING_BAR,
    payload: { wallbayBarGuid },
  });
};

// OPEN MODAL
export function openAddBar() {
  return {
    type: ACTIONS.GRID.ADD_BAR_OPEN,
  };
}

// CLOSE MODAL
export function closeAddBar() {
  return {
    type: ACTIONS.GRID.ADD_BAR_CLOSE,
  };
}

// ADD BAR TO BAY
export const addBar = ({ gridId, wallbayBarGuid, gridSettings, level, gridIndex }) => async (dispatch, getState) => {
  const state = getState();
  const { app, grid } = state.app.present;

  const articleNumbers = grid[GRIDS.BAR].map(x => x.entity.articleNumber);

  if (articleNumbers.length > 0) {
    // then do request to get all products from article numbers
    dispatch({
      type: ACTIONS.PRODUCT.FETCH_ARTICLE_NUMBERS,
    });

    const response = await ApiService.request({
      slug: 'get-products-by-article',
      verb: 'POST',
      params: { articleNumbers, cultureCode: app.cultureCode },
    });

    dispatch({
      type: ACTIONS.PRODUCT.FETCH_ARTICLE_NUMBERS_SUCCESS,
      payload: { ...response, gridSettings },
    });
  }

  return dispatch({
    type: ACTIONS.GRID.ADD_BAR,
    payload: { gridId, wallbayBarGuid, level, gridIndex, gridSettings },
  });
};

// CREATE BAR
export const createBar = ({ gridId, bar, wallbayTypeGuid, isGlobal, addToBay, gridSettings, gridIndex }) => async dispatch => {
  dispatch({
    type: ACTIONS.GRID.CREATE_BAR,
  });

  dispatch({
    type: ACTIONS.GRID.FETCH_BARS,
  });

  const requestParams = {
    wallbayTypeGuid,
    barName: bar.name,
    jsonData: JSON.stringify(bar),
    isGlobal,
  };

  try {
    const barResponse = await ApiService.request({ slug: 'create-bar', verb: 'POST', params: requestParams });

    // display success
    dispatch(addToast('Created Bar', { type: TOAST_TYPES.SUCCESS }));

    // wait for us to re-fetch
    const response = await ApiService.request({
      slug: 'get-bars-for-type',
      verb: 'GET',
      params: { wallbayTypeGuid },
      includeErrors: true,
    });

    dispatch({
      type: ACTIONS.GRID.FETCH_BARS_SUCCESS,
      payload: response,
    });

    // add bar to bay if told to
    if (addToBay === true) {
      const params = {
        gridId,
        wallbayBarGuid: barResponse.barGuid,
        articleNumbers: bar.entities.map(x => x.articleNumber),
        gridSettings,
        gridIndex,
        level: bar.level,
      };

      // gridId, wallbayBarGuid, articleNumbers, gridSettings
      dispatch(addBar(params));
    }

    dispatch(closeAddBar());
  } catch (error) {
    dispatch(addToast('Erroring creating bar', { type: TOAST_TYPES.ERROR }));

    dispatch({
      type: ACTIONS.GRID.CREATE_BAR_ERROR,
      payload: error,
    });
  }
};

// DELETE BAR
export const deleteBar = ({ wallbayBarGuid, wallbayTypeGuid }) => async dispatch => {
  dispatch({
    type: ACTIONS.GRID.DELETE_BAR,
  });

  try {
    await ApiService.request({
      slug: 'delete-bar',
      verb: 'DELETE',
      params: { wallbayBarGuid },
    });

    dispatch({
      type: ACTIONS.GRID.DELETE_BAR_SUCCESS,
    });

    dispatch(fetchBars({ wallbayTypeGuid }));
  } catch (error) {
    dispatch({
      type: ACTIONS.GRID.DELETE_BAR_ERROR,
    });
  }
};

// EDIT BAR
export function editBar(params) {
  return {
    type: ACTIONS.GRID.ADD_BAR_EDIT,
    payload: params,
  };
}

// UPDATE BAR
export function updateBar(params) {
  return {
    type: ACTIONS.GRID.UPDATE_BAR,
    payload: params,
  };
}

// SET HOVER PRODUCT
export function setHoverProduct(params) {
  return {
    type: ACTIONS.GRID.SET_HOVER_PRODUCT,
    payload: params,
  };
}

export function setEditingPlaceholder(params) {
  return {
    type: ACTIONS.GRID.SET_EDITING_PLACEHOLDER,
    payload: params,
  };
}

// SELECTED ENTITIES //
export function setSelectedEntities(params) {
  return {
    type: ACTIONS.GRID.SET_SELECTED_ENTITIES,
    payload: params,
  };
}

export function setSelectedEntitiesFromArray(params) {
  return {
    type: ACTIONS.GRID.SET_SELECTED_ENTITIES_FROM_ARRAY,
    payload: params,
  };
}

export function clearSelectedEntities() {
  return {
    type: ACTIONS.GRID.CLEAR_SELECTED_ENTITIES,
  };
}

export function alignEntities(params) {
  return {
    type: ACTIONS.GRID.ALIGN_ENTITIES,
    payload: params,
  };
}

export function openOverflowModal() {
  return {
    type: ACTIONS.GRID.OVERFLOW_OPEN,
  };
}

export function closeOverflowModal() {
  return {
    type: ACTIONS.GRID.OVERFLOW_CLOSE,
  };
}
