import { useEffect } from 'react';

/**
 * Hook to register event listeners for key press
 * @param Callbacks
 */
const useKeyUp = ({ onKeyUp, keyCode }) => {
  const handleInput = e => {
    if (e.keyCode === keyCode) {
      e.preventDefault();
      onKeyUp(e);
    }
  };

  useEffect(() => {
    document.addEventListener('keyup', handleInput);

    return () => document.removeEventListener('keyup', handleInput);
  }, []);
};

export default useKeyUp;
