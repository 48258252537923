import React from 'react';
import { connect } from 'react-redux';
import { removeEntity, setHoverProduct } from '@redux/actioncreators/grid';
import GRID_LEVELS from '@enums/GridLevels';

import GridService from '@js/grid/GridService';

import { ContextMenu, MenuItem, SubMenu } from 'react-contextmenu';
import { alignEntities, clearSelectedEntities, setEditingPlaceholder, updateProductPosition } from 'js/redux/actioncreators/grid';
import { useRefSelector } from 'js/hooks/useRefSelector';
import Grids from 'js/enums/Grids';
import { addToast } from 'js/redux/actioncreators/toast';
import ToastTypes from 'js/enums/ToastTypes';
import BarEditMode from 'js/enums/BarEditMode';
import { openSubNav } from 'js/redux/actioncreators/app';
import SubNavs from 'js/enums/SubNavs';
import Directions from 'js/enums/Directions';
import { t } from '../translations/index';

const CellContextMenu = ({
  project,
  grid,
  _removeEntity,
  _setHoverProduct,
  _updateProductPosition,
  _addToast,
  _openSubNav,
  _setEditingPlaceholder,
  _alignEntities,
  _clearSelectedEntities,
}) => {
  const selectedEntitiesRef = useRefSelector(rootState => rootState.app.present.grid.selectedEntities);

  const handleInspect = (e, data, target) => {
    const popup = JSON.parse(target.getAttribute('data-inspect'));

    _setHoverProduct(popup);
  };

  const handleDelete = (e, data, target) => {
    const entity = JSON.parse(target.getAttribute('data-entity'));
    _removeEntity([{ ...entity }]);
  };

  const handleSendToBay = (e, data, target, targetIndex) => {
    const entity = JSON.parse(target.getAttribute('data-entity'));
    const product = JSON.parse(target.getAttribute('data-product'));
    const bar = JSON.parse(target.getAttribute('data-bar'));
    const currentIndex = JSON.parse(target.getAttribute('data-current-index'));

    let sizeObject;
    if (bar) {
      sizeObject = bar;
    } else {
      sizeObject = product;
    }

    const position = GridService.getNextAvailableSpace(
      grid[Grids.WALLBAY][targetIndex][GRID_LEVELS.BACK],
      sizeObject.sizes[targetIndex].width,
      sizeObject.sizes[targetIndex].height,
      sizeObject.id,
      Grids.WALLBAY,
      grid.settings[targetIndex],
    );

    if (position === undefined) {
      _addToast(t('profile-manager.error.noroom'), { type: ToastTypes.ERROR });
      return;
    }

    _updateProductPosition({
      entity,
      product,
      bar,
      position,
      gridId: Grids.WALLBAY,
      gridLevel: GRID_LEVELS.BACK,
      targetGridIndex: targetIndex,
      currentGridIndex: currentIndex,
    });
  };

  const handleEdit = (e, data, target) => {
    const entity = JSON.parse(target.getAttribute('data-entity'));

    if (!entity) {
      return;
    }

    _setEditingPlaceholder({ id: entity.id, gridIndex: entity.gridIndex });
    _openSubNav(SubNavs.PLACEHOLDER);
  };

  const doAlignment = (direction, target) => {
    const product = JSON.parse(target.getAttribute('data-product'));
    const bar = JSON.parse(target.getAttribute('data-bar'));
    const entity = JSON.parse(target.getAttribute('data-entity'));

    _alignEntities({
      product,
      bar,
      placeholder: entity.placeholder,
      direction,
    });

    _clearSelectedEntities();
  };

  return (
    <>
      <ContextMenu id="product-context-menu">
        {grid.selectedEntities.length > 1 ? (
          <>
            <MenuItem onClick={(e, data, target) => doAlignment(Directions.UP, target)}>{t('profile-manager.align.align-to-top')}</MenuItem>
            <MenuItem onClick={(e, data, target) => doAlignment(Directions.LEFT, target)}>
              {t('profile-manager.align.align-to-left')}
            </MenuItem>
            <MenuItem onClick={(e, data, target) => doAlignment(Directions.RIGHT, target)}>
              {t('profile-manager.align.align-to-right')}
            </MenuItem>
            <MenuItem onClick={(e, data, target) => doAlignment(Directions.DOWN, target)}>
              {t('profile-manager.align.align-to-bottom')}
            </MenuItem>
          </>
        ) : (
          <>
            <MenuItem onClick={handleInspect}>Inspect</MenuItem>
            {project.projectBays.length > 1 && grid.barEditMode === BarEditMode.CLOSED && (
              <SubMenu title={t('profile-manager.options.send-to')}>
                {project.projectBays.map((x, index) => (
                  <MenuItem key={index} onClick={(e, data, target) => handleSendToBay(e, data, target, index)}>
                    {index + 1}. {x.bayName}
                  </MenuItem>
                ))}
              </SubMenu>
            )}
            <MenuItem onClick={handleDelete}>{t('profile-manager.options.delete')}</MenuItem>
          </>
        )}
      </ContextMenu>

      <ContextMenu id="placeholder-context-menu">
        <MenuItem onClick={handleInspect}>{t('profile-manager.options.inspect')}</MenuItem>

        <MenuItem onClick={(e, data, target) => handleEdit(e, data, target)}>{t('profile-manager.options.edit')}</MenuItem>

        {project.projectBays.length > 1 && grid.barEditMode === BarEditMode.CLOSED && (
          <SubMenu title={t('profile-manager.options.send-to')}>
            {project.projectBays.map((x, index) => (
              <MenuItem key={index} onClick={(e, data, target) => handleSendToBay(e, data, target, index)}>
                {index + 1}. {x.bayName}
              </MenuItem>
            ))}
          </SubMenu>
        )}
        <MenuItem onClick={handleDelete}>{t('profile-manager.options.delete')}</MenuItem>
      </ContextMenu>

      <ContextMenu id="bar-context-menu">
        {project.projectBays.length > 1 && grid.barEditMode === BarEditMode.CLOSED && (
          <SubMenu title={t('profile-manager.options.send-to')}>
            {project.projectBays.map((x, index) => (
              <MenuItem key={index} onClick={(e, data, target) => handleSendToBay(e, data, target, index)}>
                {index + 1}. {x.bayName}
              </MenuItem>
            ))}
          </SubMenu>
        )}
        <MenuItem onClick={handleDelete}>{t('profile-manager.options.delete')}</MenuItem>
      </ContextMenu>
    </>
  );
};

const mapStateToProps = state => ({
  project: state.app.present.project,
  grid: state.app.present.grid,
});

const mapDispatchToProps = dispatch => ({
  _removeEntity: params => dispatch(removeEntity(params)),
  _setHoverProduct: params => dispatch(setHoverProduct(params)),
  _updateProductPosition: params => dispatch(updateProductPosition(params)),
  _addToast: (message, settings) => dispatch(addToast(message, settings)),
  _openSubNav: id => dispatch(openSubNav(id)),
  _setEditingPlaceholder: params => dispatch(setEditingPlaceholder(params)),
  _alignEntities: params => dispatch(alignEntities(params)),
  _clearSelectedEntities: params => dispatch(clearSelectedEntities(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CellContextMenu);
