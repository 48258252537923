import ACTIONS from '@redux/actions';
import { SAVES } from '@js/data';
import GridService from '@js/grid/GridService';
import uuidv4 from 'uuid/v4';
import GRID from '@enums/Grids';
import GRID_LEVELS from '@enums/GridLevels';
import DIRECTIONS from '@enums/Directions';
import BAR_EDIT_MODE from '@enums/BarEditMode';
import AbsoluteGridService from 'js/grid/AbsoluteGridService';
import Grids from 'js/enums/Grids';
import Types from 'js/enums/Types';
import ApiService from 'js/api/ApiService';
import AppInsightsContext from 'js/appInsights/AppInsightsContext';
import omit from 'lodash/omit';
import Directions from 'js/enums/Directions';
import { updateProductPosition } from '../actioncreators/grid';

const initialState = {
  data: [],
  dataLoading: false,
  quantities: {},
  overflow: [],
  pegs: [],
  pegsLoading: false,
  isPopulated: false,
  personalDetails: {
    orderNumber: '',
    firstName: '',
    lastName: '',
    emailAddress: '',
    addressLine1: '',
    addressLine2: '',
    townCity: '',
    postCode: '',
    notes: '',
  },
  settings: [],
  errors: [],
  hiddenErrors: [],
  importResults: {
    errors: [],
    success: [],
  },
  populateResults: [],
  [GRID.OVERFLOW]: JSON.parse(JSON.stringify(SAVES.EMPTY)),
  [GRID.WALLBAY]: JSON.parse(JSON.stringify(SAVES.EMPTY)),
  [GRID.BAR]: [],
  barEditMode: BAR_EDIT_MODE.CLOSED,
  editedBarGuid: null,
  editedBarMetaData: null,
  barErrors: [],
  bars: {
    isLoading: false,
    userBars: [],
    globalBars: [],
  },
  hoverProduct: null,
  scrollToLastBay: 0,
  editingPlaceholder: null,
  selectedEntities: [],
  overflowModalOpen: false,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.GRID.POPULATE: {
      const { project } = action.payload;
      const newState = JSON.parse(JSON.stringify(state));
      let intersections = [];

      // Clear out the wallbay
      newState[Grids.WALLBAY] = [];

      // Clear the settings
      newState.settings = [];

      for (let bayIndex = 0; bayIndex < project.projectBays.length; bayIndex += 1) {
        // Get the bay
        const bay = project.projectBays[bayIndex];

        // Skip if we have no json data
        if (bay.jsonData === '') {
          // eslint-disable-next-line no-continue
          continue;
        }

        // Get the settings
        const settings = GridService.getSettingsFromType(bay.wallbayType);

        // Update our state with them so we can use later
        newState.settings.push(settings);
      }

      // Create a grid
      for (let bayIndex = 0; bayIndex < project.projectBays.length; bayIndex += 1) {
        // Get the bay
        const bay = project.projectBays[bayIndex];

        // Skip if we have no json data
        if (bay.jsonData === '') {
          // eslint-disable-next-line no-continue
          continue;
        }

        const settings = newState.settings[bayIndex];

        // Get the JSON data so we know how many levels deep to create?
        const bayLevels = JSON.parse(bay.jsonData);

        // Create an array
        newState[Grids.WALLBAY][bayIndex] = [];
        newState[Grids.OVERFLOW][0] = [];

        newState[Grids.OVERFLOW][0][0] = GridService.createGrid(settings.gridStepsX, settings.gridStepsY);

        for (let bayLevel = 0; bayLevel < bayLevels.length; bayLevel += 1) {
          if (newState[Grids.WALLBAY][bayIndex] === undefined) {
            newState[Grids.WALLBAY][bayIndex] = [];
            newState[Grids.OVERFLOW][bayIndex] = [];
          }

          // Create a grid per bay level
          newState[Grids.WALLBAY][bayIndex][bayLevel] = GridService.createGrid(settings.gridStepsX, settings.gridStepsY);

          const result = GridService.set2dFromEntities(
            newState[Grids.WALLBAY][bayIndex][bayLevel],
            bayIndex,
            bayLevels[bayLevel],
            newState.data,
            settings,
            newState.settings,
          );

          if (result.length > 0) {
            intersections = intersections.concat(result);
          }
        }
      }

      newState.populateResults = intersections;
      newState.isPopulated = true;
      AppInsightsContext.current.trackEvent({ name: 'Populated grid from Project' });

      return newState;
    }

    case ACTIONS.PROJECT.SET_PROJECT_TEST:
    case ACTIONS.PROJECT.SET_PROJECT: {
      const newState = JSON.parse(JSON.stringify(state));
      const { project } = action.payload;
      newState.isPopulated = false;

      if (project === undefined) {
        newState[GRID.WALLBAY] = JSON.parse(JSON.stringify(SAVES.EMPTY));
        newState.settings = [...initialState.settings];
        return newState;
      }

      // Clear the settings
      newState.settings = [];

      // Get settings from the newly set project
      for (let index = 0; index < project.projectBays.length; index += 1) {
        newState.settings.push(GridService.getSettingsFromType(project.projectBays[index].wallbayType));
      }

      return newState;
    }

    case ACTIONS.PRODUCT.FETCH_ARTICLE_NUMBERS_RESET: {
      const newState = JSON.parse(JSON.stringify(state));

      newState.importResults.errors = [];
      newState.importResults.success = [];

      return newState;
    }

    case ACTIONS.PRODUCT.FETCH_ARTICLE_NUMBERS: {
      const newState = JSON.parse(JSON.stringify(state));

      newState.populateResults = [];
      newState.dataLoading = true;
      AppInsightsContext.current.trackEvent({ name: 'Fetching article numbers' });

      return newState;
    }

    case ACTIONS.PRODUCT.FETCH_ARTICLE_NUMBERS_SUCCESS: {
      const newState = JSON.parse(JSON.stringify(state));
      const { params, productData, logResults } = action.payload;
      const normalisedProducts = [...newState.data];

      AppInsightsContext.current.trackEvent({ name: 'Fetched article numbers' });

      for (let index = 0; index < productData.length; index += 1) {
        const product = productData[index];

        // Check to see if the product already exists
        const existingIndex = normalisedProducts.findIndex(x => x.articleNumber === product.articleNumber);

        // If it does we need to remove it so we can make sure it's updated with the newest sizes
        if (existingIndex > -1) {
          normalisedProducts.splice(existingIndex, 1);
        }

        // Add in the normalised product
        const normalisedProduct = GridService.normaliseProductSize(product, newState.settings);

        normalisedProducts.push(normalisedProduct);
      }

      if (logResults) {
        // Calculate errors
        for (let i = 0; i < params.articleNumbers.length; i += 1) {
          const articleNumber = params.articleNumbers[i];
          if (normalisedProducts.findIndex(x => x.articleNumber === articleNumber) > -1) {
            newState.importResults.success.push(articleNumber);
          } else {
            newState.importResults.errors.push(articleNumber);
          }
        }
      }

      newState.data = normalisedProducts;
      newState.dataLoading = false;

      return newState;
    }

    case ACTIONS.PRODUCT.FETCH_ALL_PEGS: {
      const newState = JSON.parse(JSON.stringify(state));

      newState.pegsLoading = true;

      return newState;
    }

    case ACTIONS.PRODUCT.FETCH_ALL_PEGS_SUCCESS: {
      const newState = JSON.parse(JSON.stringify(state));

      newState.pegs = action.payload.pegs;
      newState.pegsLoading = false;

      return newState;
    }

    case ACTIONS.GRID.ADD: {
      const newState = JSON.parse(JSON.stringify(state));

      for (let index = 0; index < action.payload.length; index += 1) {
        const currentPayload = action.payload[index];
        const { product, entity, settings, position: targetPosition, removeExisting } = currentPayload;
        let fitsInsideGrid = false;
        let position = targetPosition;
        let appInsightsType;

        if (!product) {
          newState.hiddenErrors.push({ message: 'No Product', object: action.payload });
          return newState;
        }

        if (!entity) {
          newState.hiddenErrors.push({ message: 'No Entity', object: action.payload });
          return newState;
        }

        const { gridId, articleNumber, id, gridIndex } = entity;
        let targetGridIndex = gridIndex;

        const normalizedProduct = GridService.normaliseProductSize({ ...product }, newState.settings);
        let widthObject;
        let dataObject;

        switch (entity.type) {
          default: {
            break;
          }

          case 'PRODUCT': {
            widthObject = newState.data.find(x => x.articleNumber === articleNumber);
            if (widthObject === undefined) {
              newState.hiddenErrors.push({ message: 'Cant find product in data lookup', object: action.payload });

              // Add into the data for for the grid
              newState.data.push(normalizedProduct);
              widthObject = normalizedProduct;
              appInsightsType = 'Product';
            }
            break;
          }

          case 'PLACEHOLDER': {
            widthObject = normalizedProduct;
            appInsightsType = 'Placeholder';

            // Add the placeholder data into the data object which is stored in the DB
            dataObject = {
              sizes: normalizedProduct.sizes,
              originalWidth: normalizedProduct.originalWidth,
              originalHeight: normalizedProduct.originalHeight,
              name: normalizedProduct.name,
            };
            break;
          }
        }

        if (!widthObject) {
          newState.hiddenErrors.push({ message: 'Cant find any width object', object: action.payload });
          return newState;
        }

        if (!widthObject.sizes || !widthObject.sizes[gridIndex]) {
          newState.hiddenErrors.push({ message: 'Cant find width object sizes', object: action.payload });
          return newState;
        }

        if (gridId === GRID.BAR) {
          position = AbsoluteGridService.getNextAvailableSpace(
            newState[gridId],
            newState.data,
            widthObject,
            id,
            gridId,
            newState.settings[gridIndex],
          );

          if (position !== undefined) {
            entity.x = position.x;
            entity.y = position.y;

            newState[gridId].push({
              entity,
              placeholder: {
                sizes: widthObject.sizes,
                width: normalizedProduct.width,
                height: normalizedProduct.height,
                originalWidth: normalizedProduct.originalWidth,
                originalHeight: normalizedProduct.originalHeight,
                name: normalizedProduct.name,
              },
            });
          } else if (newState.barErrors.indexOf('The bar is full') === -1) {
            newState.barErrors.push('The bar is full');
          }
        } else {
          fitsInsideGrid = GridService.fitsInsideGrid(
            newState[gridId][gridIndex][GRID_LEVELS.BACK],
            widthObject.sizes[gridIndex].width,
            widthObject.sizes[gridIndex].height,
          );

          if (!fitsInsideGrid) {
            const existingIndex = newState.overflow.findIndex(x => x.entity.id === entity.id);
            newState.overflow.splice(existingIndex, 1);

            // eslint-disable-next-line no-continue
            continue;
          }

          if (!position) {
            position = GridService.getNextAvailableSpace(
              newState[gridId][gridIndex][GRID_LEVELS.BACK],
              widthObject.sizes[gridIndex].width,
              widthObject.sizes[gridIndex].height,
              id,
              gridId,
              settings,
            );
          } else if (removeExisting) {
            console.log(removeExisting);
            // This means a position was passed in so we want to remove the existing entity
            GridService.fillSubArray(
              newState[entity.gridId][entity.gridIndex][GRID_LEVELS.BACK],
              position.x,
              position.y,
              removeExisting.sizes[entity.gridIndex].width,
              removeExisting.sizes[entity.gridIndex].height,
              -1,
              -1,
              'EMPTY',
            );

            // Then we need to make sure the position they passed in fits the new entity
            const isEmpty = GridService.isEmpty(
              newState[entity.gridId][entity.gridIndex][GRID_LEVELS.BACK],
              position,
              widthObject.sizes[entity.gridIndex].width,
              widthObject.sizes[entity.gridIndex].height,
              entity.id,
            );

            // If the new entity wont fit in the existing space we created then we return the original state which hasn't been modified yet
            if (!isEmpty) {
              return state;
            }
          }

          // Find the next free spot AFTER the bay you are trying to import it into
          if (position === undefined && gridId === Grids.WALLBAY) {
            const baysToLoop = newState[gridId].length - gridIndex;

            for (let bayLoopIndex = 0; bayLoopIndex < baysToLoop; bayLoopIndex += 1) {
              position = GridService.getNextAvailableSpace(
                newState[gridId][bayLoopIndex][GRID_LEVELS.BACK],
                widthObject.sizes[bayLoopIndex].width,
                widthObject.sizes[bayLoopIndex].height,
                id,
                gridId,
                settings,
              );

              if (position !== undefined) {
                targetGridIndex = bayLoopIndex;

                // eslint-disable-next-line no-continue
                continue;
              }
            }
          }

          if (position !== undefined) {
            console.log(`Adding ${entity.articleNumber} to ${targetGridIndex}`);

            GridService.fillSubArray(
              newState[gridId][targetGridIndex][GRID_LEVELS.BACK],
              position.x,
              position.y,
              widthObject.sizes[targetGridIndex].width,
              widthObject.sizes[targetGridIndex].height,
              id,
              articleNumber,
              entity.type,
              dataObject,
            );
          } else {
            newState.overflow.push(currentPayload);
          }
        }

        AppInsightsContext.current.trackEvent({ name: `Added a new ${appInsightsType}` });
      }

      newState.populateResults = [];
      return newState;
    }

    case ACTIONS.GRID.CLEAR_OVERFLOW: {
      const newState = JSON.parse(JSON.stringify(state));

      newState.overflow = JSON.parse(JSON.stringify([]));

      return newState;
    }

    case ACTIONS.GRID.CLEAR_OVERFLOW_GRID: {
      const { project } = action.payload;
      const newState = JSON.parse(JSON.stringify(state));

      // Create a grid
      for (let bayIndex = 0; bayIndex < project.projectBays.length; bayIndex += 1) {
        // Get the bay
        const bay = project.projectBays[bayIndex];

        // Skip if we have no json data
        if (bay.jsonData === '') {
          // eslint-disable-next-line no-continue
          continue;
        }

        // Get the settings
        const settings = state.settings[0];

        // Create an array
        newState[Grids.OVERFLOW][0] = [];

        newState[Grids.OVERFLOW][0][0] = GridService.createGrid(settings.gridStepsX, settings.gridStepsY);
      }

      newState.isPopulated = true;

      return newState;
    }

    case ACTIONS.GRID.REMOVE: {
      const newState = JSON.parse(JSON.stringify(state));

      const entities = action.payload;

      for (let index = 0; index < entities.length; index += 1) {
        const entity = entities[index];

        let level = 0;

        let sizeObject;

        if (entity.type === Types.BAR) {
          sizeObject = entity.bar;
          level = entity.bar.level;

          AppInsightsContext.current.trackEvent({ name: 'Removed a bar' });
        } else if (entity.type === Types.PLACEHOLDER) {
          sizeObject = entity.placeholder;

          AppInsightsContext.current.trackEvent({ name: 'Removed a placeholder' });
        } else {
          sizeObject = state.data.find(x => x.articleNumber === entity.articleNumber);

          AppInsightsContext.current.trackEvent({ name: 'Removed a product' });
        }

        if (sizeObject !== undefined) {
          if (entity.gridId === GRID.BAR) {
            const existingIndex = newState[GRID.BAR].findIndex(
              x => x.entity.articleNumber === entity.articleNumber && x.entity.x === entity.x && x.entity.y === entity.y,
            );
            newState[GRID.BAR].splice(existingIndex, 1);
          } else {
            GridService.fillSubArray(
              newState[entity.gridId][entity.gridIndex][level],
              entity.x,
              entity.y,
              sizeObject.sizes[entity.gridIndex].width,
              sizeObject.sizes[entity.gridIndex].height,
              -1,
              -1,
              'EMPTY',
            );
          }
        }
      }

      return newState;
    }

    case ACTIONS.GRID.PREVIEW_EXISTING_BAR: {
      const newState = JSON.parse(JSON.stringify(state));
      const { wallbayBarGuid } = action.payload;

      let barData;

      // Lookup the bar data in the user bars
      barData = newState.bars.userBars.find(x => x.wallbayBarGuid === wallbayBarGuid);

      // then try look up in the global bars
      if (barData === undefined) {
        barData = newState.bars.globalBars.find(x => x.wallbayBarGuid === wallbayBarGuid);
      }

      if (barData === undefined) {
        return newState;
      }

      const bar = JSON.parse(barData.jsonData);

      newState.barEditMode = BAR_EDIT_MODE.PREVIEW_EXISTING;

      newState.editedBarGuid = wallbayBarGuid;

      // deep clone 🤮 (faster than lodash/deepclone)
      // bear in my any custom prototype methods will probably
      // be lost but i don't need them atm
      newState[GRID.BAR] = JSON.parse(JSON.stringify(bar.entities));

      return newState;
    }

    case ACTIONS.GRID.ADD_BAR: {
      const { wallbayBarGuid, level, gridIndex, gridSettings } = action.payload;
      const newState = JSON.parse(JSON.stringify(state));

      let barData;

      // Lookup the bar data in the user bars
      barData = newState.bars.userBars.find(x => x.wallbayBarGuid === wallbayBarGuid);

      // then try look up in the global bars
      if (barData === undefined) {
        barData = newState.bars.globalBars.find(x => x.wallbayBarGuid === wallbayBarGuid);
      }

      if (barData === undefined) {
        return newState;
      }

      const bar = JSON.parse(barData.jsonData);

      bar.entities = newState[Grids.BAR];
      // bar.height = GridService.getBarHeight(newState[Grids.BAR], newState.data, false);
      // bar.width = gridSettings.gridStepsX * gridSettings.gridPitch;
      bar.id = uuidv4();
      bar.level = level;

      bar.sizes = [];
      for (let index = 0; index < newState.settings.length; index += 1) {
        const settings = newState.settings[index];

        bar.sizes[index] = {
          width: Math.ceil((settings.gridStepsX * settings.gridPitch) / settings.gridPitch),
          height: GridService.getBarHeight(newState[Grids.BAR], newState.data, index),
        };
      }

      // if (bar.originalWidth === undefined) {
      //   const normalisedWidth = Math.ceil(bar.width / gridSettings.gridPitch);
      //   bar.originalWidth = bar.width;
      //   bar.width = normalisedWidth;
      // }

      // if (bar.originalHeight === undefined) {
      //   const normalisedHeight = Math.ceil(bar.height / gridSettings.gridPitch);
      //   bar.originalHeight = bar.height;
      //   bar.height = normalisedHeight;
      // }

      // Check all products have an X axis of 0 otherwise we won't accept em
      if (bar.entities.map(x => x.entity.y).reduce((accumulator, currentValue) => accumulator + currentValue) > 0) {
        newState.barErrors.push('Products must be aligned along the top');
        return newState;
      }

      newState.barEditMode = BAR_EDIT_MODE.CLOSED;

      // Check if the default bar position is empty
      let position;
      let defaultPosition;

      switch (bar.level) {
        default:
        case GRID_LEVELS.BACK: {
          defaultPosition = {
            x: 0,
            y: 1,
          };

          const isEmpty = GridService.isEmpty(
            newState[GRID.WALLBAY][gridIndex][bar.level],
            defaultPosition,
            bar.sizes[gridIndex].width,
            bar.sizes[gridIndex].height,
            bar.id,
          );

          if (isEmpty) {
            position = defaultPosition;
          }

          break;
        }
        case GRID_LEVELS.MIDDLE: {
          defaultPosition = {
            x: 0,
            y: 5,
          };

          const isEmpty = GridService.isEmpty(
            newState[GRID.WALLBAY][gridIndex][bar.level],
            defaultPosition,
            bar.sizes[gridIndex].width,
            bar.sizes[gridIndex].height,
            bar.id,
          );

          if (isEmpty) {
            position = defaultPosition;
          }

          break;
        }
        case GRID_LEVELS.FRONT: {
          defaultPosition = {
            x: 0,
            y: 9,
          };

          const isEmpty = GridService.isEmpty(
            newState[GRID.WALLBAY][gridIndex][bar.level],
            defaultPosition,
            bar.sizes[gridIndex].width,
            bar.sizes[gridIndex].height,
            bar.id,
          );

          if (isEmpty) {
            position = defaultPosition;
          }

          break;
        }
      }

      if (position === undefined) {
        // Get an initial position
        position = GridService.getNextAvailableSpace(
          newState[GRID.WALLBAY][gridIndex][bar.level],
          bar.sizes[gridIndex].width,
          bar.sizes[gridIndex].height,
          bar.id,
          GRID.WALLBAY,
          gridSettings,
        );
      }

      if (position !== undefined) {
        console.log(bar);
        GridService.fillSubArray(
          newState[GRID.WALLBAY][gridIndex][bar.level],
          position.x,
          position.y,
          bar.sizes[gridIndex].width,
          bar.sizes[gridIndex].height,
          bar.id,
          bar.id,
          'BAR',
          bar,
        );
      }

      AppInsightsContext.current.trackEvent({ name: 'Added a new bar' });

      return newState;
    }

    case ACTIONS.GRID.UPDATE_POSITION: {
      const newState = JSON.parse(JSON.stringify(state));
      const { position, entity, product, bar, placeholder, gridId, gridLevel, targetGridIndex, currentGridIndex } = action.payload;

      // generate a size object to use for a bar or a product
      let sizeObject;
      let dataObject;

      if (entity.type === Types.BAR) {
        sizeObject = bar;
        dataObject = bar;
      } else if (entity.type === Types.PLACEHOLDER) {
        sizeObject = placeholder;
        dataObject = placeholder;
      } else {
        sizeObject = product;
      }

      if (gridId === GRID.BAR || gridId === GRID.BAR_EDIT) {
        const isEmpty = AbsoluteGridService.isEmpty(
          newState[gridId],
          newState.data,
          position,
          entity.id,
          sizeObject.sizes[targetGridIndex].width,
          sizeObject.sizes[targetGridIndex].height,
          newState.settings[targetGridIndex],
        );

        if (!isEmpty) {
          return newState;
        }

        const toUpdate = newState[gridId].find(x => x.entity.id === entity.id);

        toUpdate.entity.x = position.x;
        toUpdate.entity.y = position.y;
      } else {
        // check its empty
        const isEmpty = GridService.isEmpty(
          newState[gridId][targetGridIndex][gridLevel],
          position,
          sizeObject.sizes[targetGridIndex].width,
          sizeObject.sizes[targetGridIndex].height,
          entity.id,
        );

        if (!isEmpty) {
          return newState;
        }

        // empty the current space
        // This needs to pass in the grid index of the original grid, not the target grid
        GridService.fillSubArray(
          newState[entity.gridId][currentGridIndex][gridLevel],
          entity.x,
          entity.y,
          sizeObject.sizes[currentGridIndex].width,
          sizeObject.sizes[currentGridIndex].height,
          -1,
          -1,
        );

        if (entity.type === Types.BAR) {
          sizeObject = bar;
        } else if (entity.type === Types.PLACEHOLDER) {
          sizeObject = placeholder;
        } else {
          sizeObject = product;
        }

        // fill the new space
        GridService.fillSubArray(
          newState[gridId][targetGridIndex][gridLevel],
          position.x,
          position.y,
          sizeObject.sizes[targetGridIndex].width,
          sizeObject.sizes[targetGridIndex].height,
          entity.id,
          entity.articleNumber,
          entity.type,
          dataObject,
        );
      }

      AppInsightsContext.current.trackEvent({ name: 'Drag and Drop Product' });

      return newState;
    }

    case ACTIONS.GRID.MOVE_PRODUCT_POSITION: {
      const newState = JSON.parse(JSON.stringify(state));
      const { gridId, ids, direction, amount, gridLevel, gridIndex } = action.payload;

      let currentGrid = [];

      try {
        currentGrid = newState[gridId][gridIndex][gridLevel];
      } catch (e) {
        // Display an error to the user asking them to try again
        newState.errors.push(
          'An error has occured whilst moving the product, this information has been sent to NetConstruct. <br /><br /> Please try again.',
        );

        // Data to log in the event
        const descriptionData = {
          action,
          state,
        };

        // Event log request params
        const request = {
          source: 'Wallbay',
          code: action.type,
          description: JSON.stringify(descriptionData),
        };

        // Log something in the Kentico event log
        ApiService.request({ slug: 'logevent', controller: 'analytics', verb: 'POST', params: request });

        // Ensure we return the existing state so nothing has changed
        return newState;
      }

      const products = GridService.getEntitiesFrom2d(currentGrid || [], gridId, newState);
      const lookups = products.filter(x => ids.indexOf(x.entity.id) > -1);

      if (lookups.length === 0) {
        // eslint-disable-next-line no-continue
        return newState;
      }

      // Order products based on direction
      switch (direction) {
        case DIRECTIONS.LEFT: {
          lookups.sort((a, b) => a.entity.x - b.entity.x);
          break;
        }
        case DIRECTIONS.UP: {
          lookups.sort((a, b) => a.entity.y - b.entity.y);
          break;
        }
        case DIRECTIONS.DOWN: {
          lookups.sort((a, b) => b.entity.y - a.entity.y);
          break;
        }
        case DIRECTIONS.RIGHT: {
          lookups.sort((a, b) => b.entity.x - a.entity.x);
          break;
        }
        default: {
          break;
        }
      }

      for (let i = 0; i < lookups.length; i += 1) {
        const lookup = lookups[i];

        // Get entity and product
        const { entity } = lookup;

        const position = {
          x: entity.x,
          y: entity.y,
        };

        // update the products position
        switch (direction) {
          case DIRECTIONS.LEFT: {
            position.x -= amount;
            break;
          }
          case DIRECTIONS.UP: {
            position.y -= amount;
            break;
          }
          case DIRECTIONS.DOWN: {
            position.y += amount;
            break;
          }
          case DIRECTIONS.RIGHT: {
            position.x += amount;
            break;
          }
          default: {
            break;
          }
        }

        if (entity.type === Types.BAR) {
          const isEmpty = GridService.isEmpty(
            currentGrid,
            position,
            lookup.bar.sizes[gridIndex].width,
            lookup.bar.sizes[gridIndex].height,
            entity.id,
          );

          if (isEmpty) {
            GridService.fillSubArray(
              currentGrid,
              entity.x,
              entity.y,
              lookup.bar.sizes[gridIndex].width,
              lookup.bar.sizes[gridIndex].height,
              -1,
              -1,
            );

            // Fill the new position
            GridService.fillSubArray(
              currentGrid,
              position.x,
              position.y,
              lookup.bar.sizes[gridIndex].width,
              lookup.bar.sizes[gridIndex].height,
              entity.id,
              entity.id,
              entity.type,
              lookup.bar,
            );
          }
        } else if (entity.type === Types.PLACEHOLDER) {
          const { placeholder } = lookup;

          const isEmpty = GridService.isEmpty(
            currentGrid,
            position,
            placeholder.sizes[gridIndex].width,
            placeholder.sizes[gridIndex].height,
            entity.id,
          );

          if (isEmpty) {
            GridService.fillSubArray(
              currentGrid,
              entity.x,
              entity.y,
              placeholder.sizes[gridIndex].width,
              placeholder.sizes[gridIndex].height,
              -1,
              -1,
            );

            // Fill the new position
            GridService.fillSubArray(
              currentGrid,
              position.x,
              position.y,
              placeholder.sizes[gridIndex].width,
              placeholder.sizes[gridIndex].height,
              entity.id,
              entity.articleNumber,
              entity.type,
              placeholder,
            );
          }
        } else {
          const product = newState.data.find(x => x.articleNumber === entity.articleNumber);
          const isEmpty = GridService.isEmpty(
            currentGrid,
            position,
            product.sizes[gridIndex].width,
            product.sizes[gridIndex].height,
            entity.id,
          );

          if (isEmpty) {
            GridService.fillSubArray(
              currentGrid,
              entity.x,
              entity.y,
              product.sizes[gridIndex].width,
              product.sizes[gridIndex].height,
              -1,
              -1,
            );

            // Fill the new position
            GridService.fillSubArray(
              currentGrid,
              position.x,
              position.y,
              product.sizes[gridIndex].width,
              product.sizes[gridIndex].height,
              lookup.entity.id,
              entity.articleNumber,
              entity.type,
            );
          }
        }
      }

      AppInsightsContext.current.trackEvent({ name: 'Moved product with arrow keys' });

      return newState;
    }

    case ACTIONS.HISTORY.PROPAGATE_PRESENT: {
      if (action.payload === undefined) {
        return state;
      }

      const { grid } = action.payload;
      const newState = JSON.parse(JSON.stringify(state));

      if (!grid) {
        return state;
      }

      return {
        ...newState,
        ...grid,
      };
    }

    case ACTIONS.GRID.ADD_BAR_OPEN: {
      const newState = JSON.parse(JSON.stringify(state));

      newState.barEditMode = BAR_EDIT_MODE.ADD;
      newState.editedBarGuid = null;
      newState.editedBarMetaData = null;

      newState[GRID.BAR] = [];
      newState.barErrors = [];

      return newState;
    }

    case ACTIONS.GRID.ADD_BAR_CLOSE: {
      const newState = JSON.parse(JSON.stringify(state));

      newState.barEditMode = BAR_EDIT_MODE.CLOSED;
      newState.editedBarGuid = null;
      newState.editedBarMetaData = null;
      newState.barErrors = [];

      return newState;
    }

    case ACTIONS.GRID.ADD_BAR_EDIT: {
      const newState = JSON.parse(JSON.stringify(state));
      const { bar } = action.payload;

      newState.barEditMode = BAR_EDIT_MODE.EDIT;
      newState.editedBarMetaData = { ...action.payload };

      // deep clone 🤮 (faster than lodash/deepclone)
      // bear in my any custom prototype methods will probably
      // be lost but i don't need them atm
      // const t0 = performance.now();
      newState[GRID.BAR] = JSON.parse(JSON.stringify(bar.entities));
      // const t1 = performance.now();
      // console.log(`${t1 - t0} cloned in milliseconds.`);

      // newState[GRID.BAR] = JSON.parse(JSON.stringify(bar.entities));
      // newState[GRID.BAR] = JSON.parse(JSON.stringify(bar.entities));

      return newState;
    }

    case ACTIONS.GRID.UPDATE_BAR: {
      const newState = JSON.parse(JSON.stringify(state));
      const { gridId, name, level } = action.payload;
      const cell = { ...newState.editedBarMetaData };

      // Clear the errors before we start
      newState.barErrors = [];

      const newHeight = GridService.getBarHeight(newState[gridId], newState.data, cell.gridIndex);

      // Check all products have a y axis of 0 otherwise we won't accept em
      const errors = GridService.isBarValid(cell.bar.name, state[gridId]);

      if (errors.length > 0) {
        newState.barErrors = newState.barErrors.concat(errors);
        return newState;
      }

      // If the heights have changed we need to reconsider the position
      if (cell.bar.sizes[cell.gridIndex].height !== newHeight) {
        const position = { x: cell.entity.x, y: cell.entity.y };
        const isEmpty = GridService.isEmpty(
          newState[GRID.WALLBAY][cell.gridIndex][cell.bar.level],
          position,
          cell.bar.sizes[cell.gridIndex].width,
          newHeight,
          cell.entity.id,
        );

        if (!isEmpty) {
          newState.barErrors.push('This bar cannot fit in its original location, please make some space');
          return newState;
        }
      }

      cell.bar.entities = newState[gridId];

      // Else we can just swap it out
      GridService.fillSubArray(
        newState[GRID.WALLBAY][cell.gridIndex][cell.bar.level],
        cell.entity.x,
        cell.entity.y,
        cell.bar.sizes[cell.gridIndex].width,
        cell.bar.sizes[cell.gridIndex].height,
        -1,
        -1,
      );

      cell.bar.name = name;
      cell.bar.level = Number(level);

      // Update the height
      cell.bar.sizes[cell.gridIndex].height = newHeight;

      // Fill the new position
      GridService.fillSubArray(
        newState[GRID.WALLBAY][cell.gridIndex][cell.bar.level],
        cell.entity.x,
        cell.entity.y,
        cell.bar.sizes[cell.gridIndex].width,
        cell.bar.sizes[cell.gridIndex].height,
        cell.bar.id,
        cell.bar.id,
        cell.entity.type,
        cell.bar,
      );

      // Close the modal
      newState.barEditMode = BAR_EDIT_MODE.CLOSED;

      // Clear the metadata
      newState.editedBarGuid = null;
      newState.editedBarMetaData = null;

      return newState;
    }

    case ACTIONS.GRID.FETCH_BARS: {
      const newState = JSON.parse(JSON.stringify(state));

      newState.bars = {
        ...state.bars,
        isLoading: true,
        userBars: [],
        globalBars: [],
      };

      return newState;
    }

    case ACTIONS.GRID.FETCH_BARS_SUCCESS: {
      const newState = JSON.parse(JSON.stringify(state));
      const { wallbayBars } = action.payload;

      const globalBars = [];
      const userBars = [];

      for (let index = 0; index < wallbayBars.length; index += 1) {
        const bar = wallbayBars[index];

        if (bar.isGlobal === true) {
          globalBars.push(bar);
        } else {
          userBars.push(bar);
        }
      }

      newState.bars = {
        ...state.bars,
        globalBars,
        userBars,
        isLoading: false,
      };

      return newState;
    }

    case ACTIONS.GRID.ADD_BAR_ERROR: {
      const newState = JSON.parse(JSON.stringify(state));

      newState.barErrors.push(action.payload);

      return newState;
    }

    case ACTIONS.GRID.CLEAR_BAR_ERRORS: {
      const newState = JSON.parse(JSON.stringify(state));

      newState.barErrors = [];

      return newState;
    }

    case ACTIONS.GRID.CLEAR_ERRORS: {
      const newState = JSON.parse(JSON.stringify(state));

      newState.errors = [];

      return newState;
    }

    case ACTIONS.GRID.SET_HOVER_PRODUCT: {
      const newState = JSON.parse(JSON.stringify(state));

      if (action.payload) {
        newState.hoverProduct = action.payload;
      } else {
        newState.hoverProduct = null;
      }

      return newState;
    }

    case ACTIONS.GRID.ADD_BAY_START: {
      const newState = JSON.parse(JSON.stringify(state));

      return newState;
    }

    case ACTIONS.GRID.ADD_BAY: {
      const newState = JSON.parse(JSON.stringify(state));

      newState.scrollToLastBay = new Date().getTime();

      AppInsightsContext.current.trackEvent({ name: 'Added a new bay' });

      return newState;
    }

    case ACTIONS.APP.CLOSE_SUB_NAV: {
      const newState = JSON.parse(JSON.stringify(state));

      newState.editingPlaceholder = null;

      return newState;
    }

    case ACTIONS.GRID.SET_EDITING_PLACEHOLDER: {
      const newState = JSON.parse(JSON.stringify(state));

      if (action.payload) {
        newState.editingPlaceholder = {
          ...action.payload,
        };
      } else {
        newState.editingPlaceholder = initialState.editingPlaceholder;
      }

      return newState;
    }

    case ACTIONS.GRID.SET_SELECTED_ENTITIES: {
      const { cell } = action.payload;

      const newState = JSON.parse(JSON.stringify(state));

      if (newState.selectedEntities.findIndex(x => x.entity.id === cell.entity.id) > -1) {
        newState.selectedEntities = [];
      } else {
        newState.selectedEntities = [cell];
      }

      return newState;
    }

    case ACTIONS.GRID.SET_SELECTED_ENTITIES_FROM_ARRAY: {
      const newState = JSON.parse(JSON.stringify(state));

      newState.selectedEntities = action.payload;

      return newState;
    }

    case ACTIONS.GRID.CLEAR_SELECTED_ENTITIES: {
      const newState = JSON.parse(JSON.stringify(state));

      newState.selectedEntities = [];

      return newState;
    }

    case ACTIONS.GRID.ALIGN_ENTITIES: {
      const newState = JSON.parse(JSON.stringify(state));
      const { bar, placeholder } = action.payload; // TODO: Find a way to actually implement these //
      const { direction } = action.payload;

      const entities = newState.selectedEntities.sort((a, b) => {
        switch (direction) {
          case Directions.UP:
            return a.entity.y - b.entity.y;
          case Directions.DOWN:
            return b.entity.y - a.entity.y;
          case Directions.LEFT:
            return a.entity.x - b.entity.x;
          case Directions.RIGHT:
            return b.entity.x - a.entity.x;
          default:
            return 0;
        }
      });

      const lowestXValue = entities.map(x => x.entity.x).sort((a, b) => a - b)[0];
      const lowestYValue = entities.map(x => x.entity.y).sort((a, b) => a - b)[0];
      const highestXValue = entities.map(x => x.entity.x + x.product.sizes[0].width - 1).sort((a, b) => b - a)[0];
      const highestYValue = entities.map(x => x.entity.y + x.product.sizes[0].height - 1).sort((a, b) => b - a)[0];

      const checkAlignment = (newPos, e) => {
        switch (direction) {
          case Directions.UP:
            return newPos.y > lowestYValue;
          case Directions.LEFT:
            return newPos.x > lowestXValue;
          case Directions.RIGHT:
            return newPos.x + e.product.sizes[0].width - 1 < highestXValue;
          case Directions.DOWN:
            return newPos.y + e.product.sizes[0].height - 1 < highestYValue;
          default:
            break;
        }
        return undefined;
      };

      const doAlignment = newPos => {
        switch (direction) {
          case Directions.UP:
            return { ...newPos, y: newPos.y - 1 };
          case Directions.LEFT:
            return { ...newPos, x: newPos.x - 1 };
          case Directions.RIGHT:
            return { ...newPos, x: newPos.x + 1 };
          case Directions.DOWN:
            return { ...newPos, y: newPos.y + 1 };
          default:
            break;
        }
        return newPos;
      };

      const positions = [];

      entities.forEach(e => {
        let newPos = { x: e.entity.x, y: e.entity.y };

        if (checkAlignment(newPos, e)) {
          while (
            GridService.isEmpty(
              newState[e.entity.gridId][e.gridIndex][e.gridLevel],
              newPos,
              e.product.sizes[0].width,
              e.product.sizes[0].height,
              e.entity.id,
            ) &&
            checkAlignment(newPos, e)
          ) {
            newPos = doAlignment(newPos);
          }

          // The while loop runs one too many times if there is a product blocking the current product from moving any further up //
          // Need to figure out a way to do this more elegantly, but if the current position //
          // it's about to try move to is occupied, move it back down one //
          if (
            !GridService.isEmpty(
              newState[e.entity.gridId][e.gridIndex][e.gridLevel],
              newPos,
              e.product.sizes[0].width,
              e.product.sizes[0].height,
              e.entity.id,
            )
          ) {
            switch (direction) {
              case Directions.UP:
                newPos = { ...newPos, y: newPos.y + 1 };
                break;
              case Directions.LEFT:
                newPos = { ...newPos, x: newPos.x + 1 };
                break;
              case Directions.RIGHT:
                newPos = { ...newPos, x: newPos.x - 1 };
                break;
              case Directions.DOWN:
                newPos = { ...newPos, y: newPos.y - 1 };
                break;
              default:
                break;
            }
          }

          // Update the position //
          const sizeObject = e.product;

          // check its empty
          const isEmpty = GridService.isEmpty(
            newState[e.entity.gridId][e.gridIndex][e.gridLevel],
            newPos,
            sizeObject.sizes[e.gridIndex].width,
            sizeObject.sizes[e.gridIndex].height,
            e.entity.id,
          );

          if (isEmpty) {
            // empty the current space
            GridService.fillSubArray(
              newState[e.entity.gridId][e.gridIndex][e.gridLevel],
              e.entity.x,
              e.entity.y,
              sizeObject.sizes[e.gridIndex].width,
              sizeObject.sizes[e.gridIndex].height,
              -1,
              -1,
            );

            // fill the new space
            GridService.fillSubArray(
              newState[e.entity.gridId][e.gridIndex][e.gridLevel],
              newPos.x,
              newPos.y,
              sizeObject.sizes[e.gridIndex].width,
              sizeObject.sizes[e.gridIndex].height,
              e.entity.id,
              e.entity.articleNumber,
              e.entity.type,
            );
          }
        }

        positions.push(newPos);
      });

      return newState;
    }

    case ACTIONS.GRID.OVERFLOW_OPEN: {
      const newState = JSON.parse(JSON.stringify(state));
      newState.overflowModalOpen = true;

      return newState;
    }

    case ACTIONS.GRID.OVERFLOW_CLOSE: {
      const newState = JSON.parse(JSON.stringify(state));

      newState.overflowModalOpen = false;
      newState.overflow = JSON.parse(JSON.stringify([]));

      return newState;
    }

    default:
      return state;
  }
}
