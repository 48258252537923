import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

export function useRefSelector(selector, equalityFn) {
  const state = useSelector(selector, equalityFn);
  const stateRef = useRef(state);

  useEffect(() => {
    stateRef.current = state;
  }, [JSON.stringify(state)]);

  return stateRef;
}
