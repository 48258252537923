import React, { useState, Fragment } from 'react';
import Navigation from '@components/navigation/Navigation';

import ProjectTypeSection from '@components/projects/ProjectTypeSection';
import { Translate } from '@components/translations';
import ProjectTemplateSection from '@components/projects/ProjectTemplateSection';

const CreateProject = () => {
  const [tab, setTab] = useState(0);

  return (
    <React.Fragment>
      <div className="body__bg"></div>
      <Navigation></Navigation>
      <main>
        <div className="site-wide">
          <div className="tabs">
            <button type="button" onClick={() => setTab(0)} className={`tab ${tab === 0 ? 'active' : ''}`}>
              <Translate resourceString="profile-manager.milwaukeebays" />
            </button>
            <button type="button" onClick={() => setTab(1)} className={`tab ${tab === 1 ? 'active' : ''}`}>
              <Translate resourceString="profile-manager.custombays" />
            </button>
            <button type="button" onClick={() => setTab(2)} className={`tab ${tab === 2 ? 'active' : ''}`}>
              <Translate resourceString="profile-manager.templates" />
            </button>
          </div>

          {tab === 0 && (
            <Fragment>
              <p className="tabs__title">
                <Translate resourceString="profile-manager.milwaukeebays.description" />
              </p>
              <ProjectTypeSection listCustom={false} />
            </Fragment>
          )}
          {tab === 1 && (
            <Fragment>
              <p className="tabs__title">
                <Translate resourceString="profile-manager.custombays.description" />
              </p>
              <ProjectTypeSection listCustom={true} />
            </Fragment>
          )}
          {tab === 2 && (
            <Fragment>
              <p className="tabs__title">
                <Translate resourceString="profile-manager.templates.description" />
              </p>
              <ProjectTemplateSection />
            </Fragment>
          )}
        </div>
      </main>
    </React.Fragment>
  );
};

export default CreateProject;
