import React, { Fragment, useRef, useEffect } from 'react';
import { useDrop } from 'react-dnd';
import { connect } from 'react-redux';
import propTypes from 'prop-types';
import classNames from 'classnames';
import BAR_EDIT_MODE from '@enums/BarEditMode';

import { moveProductPosition, updateProductPosition, updateProductsPositions, removeEntity } from '@redux/actioncreators/grid';
import AbsoluteGridService from '@js/grid/AbsoluteGridService';
import TYPES from '@enums/Types';
import useHoverEntity from '@hooks/useHoverEntity';

import Cell from '@components/grid/Cell';
import HoverIndicator from '@components/grid/HoverIndicator';
import GridBar from '@components/grid/GridBar';
import GRIDS from '@enums/Grids';

const AbsoluteGrid = props => {
  const { gridId, app, grid, gridIndex, updateProductPositionInternal, removeEntityInternal, showScrollBar } = props;

  // Setup
  const containerRef = useRef(null);

  // Custom Hooks
  const { hoverEntities, setHoverEntities } = useHoverEntity();

  const settings = grid.settings[gridIndex];

  // React hooks
  useEffect(() => {
    AbsoluteGridService.setAbsoluteCssVariables(containerRef.current, settings);
    // populateInternal({ gridId, settings });
  }, []);

  const handleRightClick = (e, { entity }) => {
    e.preventDefault();

    removeEntityInternal([{ ...entity }]);
  };

  // DND Hooks
  const [{ isHovering }, dropRef] = useDrop({
    accept: [TYPES.PRODUCT, TYPES.SELECTION],
    canDrop: ({ type, cell }, monitor) => {
      const hoveringGridPosition = AbsoluteGridService.getGridPositionFromPosition(containerRef.current, monitor, settings, true);

      switch (type) {
        case TYPES.PRODUCT: {
          const canMove = AbsoluteGridService.isEmpty(
            grid[gridId],
            grid.data,
            hoveringGridPosition,
            cell.entity.id,
            cell.product.originalWidth,
            cell.product.originalHeight,
            settings,
          );

          if (!canMove) {
            setHoverEntities({}, []);
            return false;
          }

          setHoverEntities(hoveringGridPosition, [
            {
              x: hoveringGridPosition.x,
              y: hoveringGridPosition.y,
              width: cell.product.originalWidth,
              height: cell.product.originalHeight,
            },
          ]);

          return canMove;
        }

        case TYPES.PLACEHOLDER: {
          const canMove = AbsoluteGridService.isEmpty(
            grid[gridId],
            grid.data,
            hoveringGridPosition,
            cell.entity.id,
            cell.placeholder.originalWidth,
            cell.placeholder.originalHeight,
            settings,
          );

          if (!canMove) {
            setHoverEntities({}, []);
            return false;
          }

          setHoverEntities(hoveringGridPosition, [
            {
              x: hoveringGridPosition.x,
              y: hoveringGridPosition.y,
              width: cell.placeholder.originalWidth,
              height: cell.placeholder.originalHeight,
            },
          ]);

          return canMove;
        }

        default:
          return true;
      }
    },
    drop: ({ type, cell }, monitor) => {
      const dropPosition = AbsoluteGridService.getGridPositionFromPosition(containerRef.current, monitor, settings, true);

      switch (type) {
        case TYPES.PRODUCT:
          updateProductPositionInternal({
            entity: cell.entity,
            product: cell.product,
            position: dropPosition,
            gridId,
            targetGridIndex: gridIndex,
            currentGridIndex: cell.gridIndex,
          });
          break;

        case TYPES.PLACEHOLDER:
          updateProductPositionInternal({
            entity: cell.entity,
            placeholder: cell.placeholder,
            position: dropPosition,
            gridId,
            targetGridIndex: gridIndex,
            currentGridIndex: cell.gridIndex,
          });
          break;

        default:
          break;
      }
    },
    collect: monitor => ({
      isHovering: monitor.isOver(),
      hasItem: monitor.didDrop(),
    }),
  });

  const renderCell = (cell, index) => {
    if (cell.entity.type === 'BAR') {
      return <GridBar entity={cell.entity} bar={cell.bar} />;
    }

    const product = grid.data.find(x => x.articleNumber === cell.entity.articleNumber);

    return (
      <Cell
        key={cell.entity.id}
        index={index}
        entity={cell.entity}
        product={product}
        placeholder={cell.placeholder}
        onClick={() => {}}
        onRightClick={handleRightClick}
        selectedEntities={[]}
        clearSelectedEntities={() => {}}
        showProductImages={app.showProductImages}
        isAbsolute={true}
        gridIndex={gridIndex}
        barEditMode={grid.barEditMode}
      />
    );
  };

  const cellClassNames = classNames({
    grid__cells: true,
    'grid__cells--absolute': true,
    'grid__cells--overflow': showScrollBar,
  });

  const productsToRender = grid[gridId];

  return (
    <Fragment>
      <div className="grid">
        <div className="grid__wrapper">
          <div ref={containerRef} className="grid__container">
            <div ref={dropRef} className={cellClassNames} style={{ height: '95%' }}>
              {productsToRender.map((x, index) => renderCell(x, index))}

              {isHovering && <HoverIndicator entities={hoverEntities} isAbsolute={true} />}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

AbsoluteGrid.propTypes = {
  gridId: propTypes.string.isRequired,
  showDots: propTypes.bool,
};

AbsoluteGrid.defaultProps = {
  showDots: true,
};

const mapStateToProps = state => ({
  grid: state.app.present.grid,
  app: state.app.present.app,
});

const mapDispatchToProps = dispatch => ({
  removeEntityInternal: params => dispatch(removeEntity(params)),
  moveProductPositionInternal: params => dispatch(moveProductPosition(params)),
  updateProductPositionInternal: params => dispatch(updateProductPosition(params)),
  updateProductsPositionsInternal: (selectedIndexes, initialPosition, dropPosition) =>
    dispatch(updateProductsPositions(selectedIndexes, initialPosition, dropPosition)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AbsoluteGrid);
