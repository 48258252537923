import React, { useRef, useEffect } from 'react';
import { useDrop } from 'react-dnd';
import { connect } from 'react-redux';
import propTypes from 'prop-types';
import DragSelect from 'dragselect';

import { setSelectedBay } from '@redux/actioncreators/app';
import { editBar, moveProductPosition, updateProductPosition, updateProductsPositions } from '@redux/actioncreators/grid';
import GridService from '@js/grid/GridService';
import TYPES from '@enums/Types';
import DIRECTIONS from '@enums/Directions';
import GRID_LEVELS from '@enums/GridLevels';

// import useSelectedEntities from '@hooks/useSelectedEntities';
import useHoverEntity from '@hooks/useHoverEntity';

import Cell from '@components/grid/Cell';
import HoverIndicator from '@components/grid/HoverIndicator';

import GridBarHeader from '@components/grid/GridBarHeader';
import BayHeader from '@components/bay/BayHeader';
import BayFooter from '@components/bay/BayFooter';
import { useInView } from 'js/hooks/useInView';
import useKeyDown from 'js/hooks/useKeyDown';
import useKeyUp from 'js/hooks/useKeyUp';
import { toggleShift } from 'js/redux/actioncreators/app';
import useHasMounted from 'js/hooks/useHasMounted';
import useArrowKeys from 'js/hooks/useArrowKeys';
import Directions from 'js/enums/Directions';
import debounce from 'lodash/debounce';
import { clearSelectedEntities, setSelectedEntities, setSelectedEntitiesFromArray } from 'js/redux/actioncreators/grid';
import { useRefSelector } from 'js/hooks/useRefSelector';
import GridName from './GridName';
import Loader from '../shared/Loader';

const Grid = props => {
  const {
    gridId,
    gridIndex,
    app,
    grid,
    showDots,
    showBarHeader,
    showBay,
    showShadow,
    moveProductPositionInternal,
    updateProductPositionInternal,
    setSelectedBayInternal,
    editBarInternal,
    toggleShiftPressedInternal,
    setSelectedEntitiesInternal,
    setSelectedEntitiesFromArrayInternal,
    clearSelectedEntitiesInternal,
  } = props;
  // Setup
  const containerRef = useRef(null);
  const [gridRef, gridInView] = useInView({ threshold: 0, reverse: true });
  const selectedEntitiesRef = useRefSelector(rootState => rootState.app.present.grid.selectedEntities);
  const shiftPressedRef = useRefSelector(rootState => rootState.app.present.app.shiftPressed);
  const ctrlPressedRef = useRefSelector(rootState => rootState.app.present.app.ctrlPressed);
  const hideGrid = grid.dataLoading === true || grid.isPopulated === false || gridInView === false;
  const dragSelectRef = useRef(null);
  const hasMounted = useHasMounted();

  // Custom Hooks
  const { hoverEntities, setHoverEntities } = useHoverEntity();

  const settings = grid.settings[gridIndex];

  // React hooks
  useEffect(() => {
    GridService.setCssVariables(containerRef.current, settings);
  }, []);

  useArrowKeys({
    onLeft: () => {
      // TODO: This needs to be done properly
      const { gridLevel } = selectedEntitiesRef.current[0];

      moveProductPositionInternal({
        gridId,
        ids: selectedEntitiesRef.current.map(x => x.entity.id),
        direction: Directions.LEFT,
        gridLevel,
        gridIndex,
      });
    },
    onUp: () => {
      // TODO: This needs to be done properly
      const { gridLevel } = selectedEntitiesRef.current[0];

      moveProductPositionInternal({
        gridId,
        ids: selectedEntitiesRef.current.map(x => x.entity.id),
        direction: Directions.UP,
        gridLevel,
        gridIndex,
      });
    },
    onRight: () => {
      // TODO: This needs to be done properly
      const { gridLevel } = selectedEntitiesRef.current[0];

      moveProductPositionInternal({
        gridId,
        ids: selectedEntitiesRef.current.map(x => x.entity.id),
        direction: Directions.RIGHT,
        gridLevel,
        gridIndex,
      });
    },
    onDown: () => {
      // TODO: This needs to be done properly
      const { gridLevel } = selectedEntitiesRef.current[0];

      moveProductPositionInternal({
        gridId,
        ids: selectedEntitiesRef.current.map(x => x.entity.id),
        direction: Directions.DOWN,
        gridLevel,
        gridIndex,
      });
    },
  });

  // DND Hooks
  const [{ isHovering }, dropRef] = useDrop({
    accept: [TYPES.PRODUCT, TYPES.SELECTION],
    canDrop: ({ type, cell, origin }, monitor) => {
      const hoveringGridPosition = GridService.getGridPositionFromPosition(containerRef.current, monitor, settings, props.zoomTransform);

      switch (type) {
        case TYPES.PRODUCT: {
          const canMove = GridService.isEmpty(
            grid[gridId][gridIndex][GRID_LEVELS.BACK],
            hoveringGridPosition,
            cell.product.sizes[gridIndex].width,
            cell.product.sizes[gridIndex].height,
            cell.entity.id,
          );

          if (!canMove) {
            setHoverEntities({}, []);
            return false;
          }

          setHoverEntities(hoveringGridPosition, [
            {
              x: hoveringGridPosition.x,
              y: hoveringGridPosition.y,
              width: cell.product.sizes[gridIndex].width,
              height: cell.product.sizes[gridIndex].height,
            },
          ]);

          return canMove;
        }

        case TYPES.PLACEHOLDER: {
          const canMove = GridService.isEmpty(
            grid[gridId][gridIndex][GRID_LEVELS.BACK],
            hoveringGridPosition,
            cell.placeholder.sizes[gridIndex].width,
            cell.placeholder.sizes[gridIndex].height,
            cell.entity.id,
          );

          if (!canMove) {
            setHoverEntities({}, []);
            return false;
          }

          setHoverEntities(hoveringGridPosition, [
            {
              x: hoveringGridPosition.x,
              y: hoveringGridPosition.y,
              width: cell.placeholder.sizes[gridIndex].width,
              height: cell.placeholder.sizes[gridIndex].height,
            },
          ]);

          return canMove;
        }

        case TYPES.BAR: {
          if (cell.bar.sizes[gridIndex] === undefined) {
            return false;
          }

          const dropPosition = {
            x: 0,
            y: hoveringGridPosition.y,
          };

          const canMove = GridService.isEmpty(
            grid[gridId][gridIndex][cell.bar.level],
            dropPosition,
            cell.bar.sizes[gridIndex].width,
            cell.bar.sizes[gridIndex].height,
            cell.entity.id,
          );

          if (!canMove) {
            setHoverEntities({}, []);
            return false;
          }

          setHoverEntities(dropPosition, [
            {
              x: dropPosition.x,
              y: dropPosition.y,
              width: cell.bar.sizes[gridIndex].width,
              height: cell.bar.sizes[gridIndex].height,
            },
          ]);

          return canMove;
        }

        case TYPES.SELECTION: {
          let canMove = true;

          for (let i = 0; i < grid.selectedEntities.length; i += 1) {
            const c = grid.selectedEntities[i];

            // If we can still move, continue to check to make sure there isn't any scenario where we can't
            if (canMove) {
              canMove = GridService.isEmpty(
                grid[gridId][gridIndex][GRID_LEVELS.BACK],
                GridService.getRelativePosition(origin, hoveringGridPosition, c.entity),
                c.product.sizes[gridIndex].width,
                c.product.sizes[gridIndex].height,
                c.entity.id,
                grid.selectedEntities.map(x => x.entity.id),
              );
            }
          }

          if (!canMove) {
            setHoverEntities({}, []);
            return false;
          }

          setHoverEntities(
            hoveringGridPosition,
            grid.selectedEntities.map(c => ({
              x: c.entity.x,
              y: c.entity.y,
              width: c.product.sizes[gridIndex].width,
              height: c.product.sizes[gridIndex].height,
            })),
            origin,
          );

          return canMove;
        }

        default:
          return true;
      }
    },
    drop: ({ type, cell, entities, origin }, monitor) => {
      const dropPosition = GridService.getGridPositionFromPosition(containerRef.current, monitor, settings, props.zoomTransform);

      switch (type) {
        case TYPES.PRODUCT:
          updateProductPositionInternal({
            entity: cell.entity,
            product: cell.product,
            position: dropPosition,
            gridId,
            gridLevel: GRID_LEVELS.BACK,
            targetGridIndex: gridIndex,
            currentGridIndex: cell.gridIndex,
          });
          break;

        case TYPES.BAR: {
          updateProductPositionInternal({
            entity: cell.entity,
            bar: cell.bar,
            position: { x: 0, y: dropPosition.y },
            gridId,
            gridLevel: cell.bar.level,
            targetGridIndex: gridIndex,
            currentGridIndex: cell.gridIndex,
          });
          break;
        }

        case TYPES.PLACEHOLDER: {
          updateProductPositionInternal({
            entity: cell.entity,
            placeholder: cell.placeholder,
            position: dropPosition,
            gridId,
            gridLevel: GRID_LEVELS.BACK,
            targetGridIndex: gridIndex,
            currentGridIndex: cell.gridIndex,
          });
          break;
        }

        case TYPES.SELECTION: {
          // Get the direction from the origin point to the new drop point
          const direction = GridService.getDirectionBetweenPoints(origin, dropPosition);

          // Order based on the direction
          switch (direction) {
            case DIRECTIONS.UP: {
              entities.sort((a, b) => a.entity.y - b.entity.y);
              break;
            }
            case DIRECTIONS.RIGHT: {
              entities.sort((a, b) => b.entity.x - a.entity.x);
              break;
            }
            case DIRECTIONS.DOWN: {
              entities.sort((a, b) => b.entity.y - a.entity.y);
              break;
            }
            case DIRECTIONS.LEFT: {
              entities.sort((a, b) => a.entity.x - b.entity.x);
              break;
            }
            case DIRECTIONS.UP_RIGHT: {
              entities.sort((a, b) => b.entity.x - a.entity.x || a.entity.y - b.entity.y);
              break;
            }
            case DIRECTIONS.DOWN_RIGHT: {
              entities.sort((a, b) => b.entity.x - a.entity.x || b.entity.y - a.entity.y);
              break;
            }
            case DIRECTIONS.DOWN_LEFT: {
              entities.sort((a, b) => a.entity.x - b.entity.x || b.entity.y - a.entity.y);
              break;
            }
            case DIRECTIONS.UP_LEFT: {
              entities.sort((a, b) => a.entity.x - b.entity.x || a.entity.y - b.entity.y);
              break;
            }
            default:
              break;
          }

          const toUpdate = entities.map(e => ({
            entity: e.entity,
            product: e.product,
            position: GridService.getRelativePosition(origin, dropPosition, e.entity),
            gridId,
            gridLevel: GRID_LEVELS.BACK,
            targetGridIndex: gridIndex,
            currentGridIndex: e.gridIndex,
          }));

          toUpdate.forEach(product => {
            updateProductPositionInternal(product);
          });

          clearSelectedEntitiesInternal();
          break;
        }

        default:
          break;
      }

      clearSelectedEntitiesInternal();
    },
    collect: monitor => ({
      isHovering: monitor.isOver(),
      hasItem: monitor.didDrop(),
    }),
  });

  const handleEditBar = ({ bar, entity }) => {
    setSelectedBayInternal(gridIndex);
    editBarInternal({ bar, entity, gridIndex });
  };

  const renderCell = (cell, index, gridLevel) => {
    let product;
    let bar;
    let placeholder;

    if (cell.entity.type === TYPES.BAR) {
      bar = cell.bar;
    } else if (cell.entity.type === TYPES.PLACEHOLDER) {
      placeholder = cell.placeholder;
    } else {
      product = grid.data.find(x => x.articleNumber === cell.entity.articleNumber);
    }

    return (
      <Cell
        key={cell.entity.id}
        index={index}
        gridLevel={gridLevel}
        gridIndex={gridIndex}
        entity={cell.entity}
        selectedEntities={grid.selectedEntities}
        product={product}
        bar={bar}
        placeholder={placeholder}
        onClick={c => {
          if (ctrlPressedRef.current) {
            setSelectedEntitiesFromArrayInternal([...selectedEntitiesRef.current, c]);
          } else {
            setSelectedEntitiesInternal({ cell: c });
          }
        }}
        onRightClick={() => {}}
        showProductImages={app.showProductImages}
        noBg={app.showBackgrounds === false}
        editBar={handleEditBar}
        gridSettings={grid.settings[gridIndex]}
        barEditMode={grid.barEditMode}
        dragSelectRef={dragSelectRef}
      />
    );
  };

  const debouncedSetSelectedEntitiesFromArray = useRef(debounce(setSelectedEntitiesFromArrayInternal, 100));

  useEffect(() => {
    const ds = new DragSelect({
      selectables: document.querySelectorAll("div[role='button'].wb-cell"),
      area: dropRef.current,
      keyboardDrag: false,
      multiSelectKeys: [],
    });

    // This is a bit of a hack to run "stop" on load as there is
    // no load event and we don't want it running unless shift is being pressed
    ds.subscribe('Area:modified', () => {
      ds.stop();
    });

    ds.subscribe('elementselect', e => {
      const items = [e.item, ...e.items];
      const cells = items.map(x => JSON.parse(x.dataset.cell));

      debouncedSetSelectedEntitiesFromArray.current(cells);
    });

    ds.subscribe('elementunselect', e => {
      if (!shiftPressedRef.current) {
        return;
      }

      const items = [e.item, ...e.items];
      const cells = items.map(x => JSON.parse(x.dataset.cell));
      const ids = cells.map(x => x.entity.id);

      const updatedCells = selectedEntitiesRef.current.filter(x => ids.indexOf(x.entity.id) > -1);

      debouncedSetSelectedEntitiesFromArray.current(updatedCells);
    });

    dragSelectRef.current = ds;
  }, []);

  useEffect(() => {
    if (!dragSelectRef.current || !hasMounted) {
      return;
    }
    if (app.shiftPressed) {
      dragSelectRef.current.start();
    } else {
      dragSelectRef.current.stop(false);
    }
  }, [app.shiftPressed]);

  const renderLevels = () => {
    if (hideGrid === true) {
      return null;
    }

    let renderCells = [];

    for (let i = 0; i < grid[gridId][gridIndex].length; i += 1) {
      const g = grid[gridId][gridIndex][i];

      const toRender = GridService.getEntitiesFrom2d(g || [], gridId, grid);
      renderCells = renderCells.concat(toRender.map((cell, index) => renderCell(cell, index, i)));
    }

    return renderCells;
  };

  let productsToRender = [];

  for (let index = 0; index < grid[gridId][gridIndex].length; index += 1) {
    const g = grid[gridId][gridIndex][index];

    productsToRender = productsToRender.concat(GridService.getEntitiesFrom2d(g || [], gridId, grid));
  }

  return (
    <div className="grid-outer" ref={gridRef}>
      <div className={`grid ${showShadow ? 'grid grid--shadow' : ''}`}>
        <GridName gridIndex={gridIndex} />
        {showBay && <BayHeader showShadow={true} />}
        {showBarHeader && <GridBarHeader />}
        <div className="grid__wrapper">
          <div ref={containerRef} className={`grid__container ${showDots ? 'show-dots' : ''}`}>
            <div ref={dropRef} className="grid__cells">
              {hideGrid && <Loader />}

              {renderLevels()}

              {isHovering && <HoverIndicator entities={hoverEntities} />}
            </div>
          </div>
        </div>
        {showBay && <BayFooter showShadow={true} />}
      </div>
    </div>
  );
};

Grid.propTypes = {
  gridId: propTypes.string.isRequired,
  showDots: propTypes.bool,
};

Grid.defaultProps = {
  showDots: true,
};

const mapStateToProps = state => ({
  grid: state.app.present.grid,
  overflow: state.app.present.grid.overflow,
  app: state.app.present.app,
});

const mapDispatchToProps = dispatch => ({
  moveProductPositionInternal: params => dispatch(moveProductPosition(params)),
  updateProductPositionInternal: params => dispatch(updateProductPosition(params)),
  updateProductsPositionsInternal: (selectedIndexes, initialPosition, dropPosition) =>
    dispatch(updateProductsPositions(selectedIndexes, initialPosition, dropPosition)),
  editBarInternal: params => dispatch(editBar(params)),
  setSelectedBayInternal: params => dispatch(setSelectedBay(params)),
  toggleShiftPressedInternal: params => dispatch(toggleShift(params)),
  setSelectedEntitiesInternal: params => dispatch(setSelectedEntities(params)),
  setSelectedEntitiesFromArrayInternal: params => dispatch(setSelectedEntitiesFromArray(params)),
  clearSelectedEntitiesInternal: () => dispatch(clearSelectedEntities()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Grid);
