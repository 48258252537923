import React from 'react';
import { connect } from 'react-redux';
import { toggleGridBackground } from '@redux/actioncreators/app';
import Tooltip from '@components/navigation/Tooltip';
import { t } from '@components/translations';

const ToggleGrid = ({ app, toggleGridBackgroundInternal }) => {
  const { showGridBackground } = app;

  const tooltipText = showGridBackground ? t('profile-manager.grid.hide') : t('profile-manager.grid.show');

  return (
    <button
        type="button"
        onClick={toggleGridBackgroundInternal}
        className={`navigation__icon navigation__icon--grid ${showGridBackground ? '' : 'active'}`}
    ><p>{t(tooltipText)}</p></button>
  );
};

const mapStateToProps = state => ({
  app: state.app.present.app,
});

const mapDispatchToProps = dispatch => ({
  toggleGridBackgroundInternal: () => dispatch(toggleGridBackground()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ToggleGrid);
