import React from 'react';
import { View, Text } from '@react-pdf/renderer';
import { brand } from '../styles';
import Logo from '../Logo';
import Entity from './Entity';

const Bar = ({ entity, bar, gridPitch, index, pegSizeInPt, productsLookup, showProductImages, showWhiteBorders }) => {
  const { x, y } = entity;
  const { name: barName, entities: entitiesOnBar } = bar;
  const { width: barWidth, height: barHeight } = bar.sizes[0];
  return (
    <React.Fragment>
      <BarTitle x={x} y={y} name={barName} width={barWidth} pegSizeInPt={pegSizeInPt} />
      <BarBackground x={x} y={y + 2} height={barHeight - 2} width={barWidth} pegSizeInPt={pegSizeInPt} />
      {entitiesOnBar.map(item => {
        return (
          <Entity
            gridPitch={gridPitch}
            offsetX={x}
            offsetY={y + 2}
            entity={item}
            index={index}
            key={item.entity.articleNumber}
            pegSizeInPt={pegSizeInPt}
            placeholder={item.placeholder}
            productsLookup={productsLookup}
            showProductImages={showProductImages}
            showWhiteBorders={showWhiteBorders}
          />
        );
      })}
    </React.Fragment>
  );
};

function BarTitle({ x, y, name, width, pegSizeInPt }) {
  const LOGO_PROPORTION = 0.75;
  return (
    <View
      style={{
        position: 'absolute',
        left: x * pegSizeInPt,
        top: y * pegSizeInPt,

        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',

        width: width * pegSizeInPt,
        height: pegSizeInPt * 2,
        backgroundColor: brand.darkRed,
        borderWidth: 2,
        borderStyle: 'solid',
        borderColor: brand.reallyDarkRed,
      }}
    >
      <Logo height={LOGO_PROPORTION * pegSizeInPt} width={(LOGO_PROPORTION * (pegSizeInPt * 86)) / 40} />
      <Text style={{ fontSize: 6, color: 'white', fontFamily: 'helvetica53' }}>{name}</Text>
      <View />
    </View>
  );
}

function BarBackground({ pegSizeInPt, x, y, width, height }) {
  return (
    <View
      style={{
        border: `2 solid ${brand.reallyDarkRed}`,
        backgroundColor: brand.darkRed,
        position: 'absolute',
        top: y * pegSizeInPt,
        left: x * pegSizeInPt,
        width: width * pegSizeInPt,
        height: height * pegSizeInPt,
      }}
    />
  );
}

export default Bar;
