/* eslint-disable no-param-reassign */
import FILL_MODES from '@enums/FillModes';
import DIRECTIONS from '@enums/Directions';
import GRIDS from '@enums/Grids';
import Types from '@enums/Types';
import { SAVES } from 'js/data';
import { getOffset } from '@utils';
import { Direction } from '../../../node_modules/react-range/lib/types';

class GridService {
  gridHeaderCellHeight = 2;

  /**
   * Create a grid using the given Width and Height
   * @param {number} width
   * @param {number} height
   */
  createGrid(width, height) {
    // Fill grid with a 2d array based on grid steps.
    const grid = Array(width);

    for (let x = 0; x < width; x += 1) {
      grid[x] = Array(height).fill({ id: -1, articleNumber: -1 });
    }

    return grid;
  }

  /**
   * Converts products and their entities into a 2D Array
   */
  set2dFromEntities(grid, gridIndex, cells, products, settings, allSettings) {
    const results = [];

    for (let index = 0; index < cells.length; index += 1) {
      const cell = cells[index];

      switch (cell.entity.type) {
        case 'BAR': {
          const { entity, bar } = cell;

          if (allSettings) {
            for (let settingIndex = 0; settingIndex < allSettings.length; settingIndex += 1) {
              const s = allSettings[settingIndex];

              bar.sizes[settingIndex] = {
                width: Math.ceil((s.gridStepsX * s.gridPitch) / s.gridPitch),
                height: this.getBarHeight(bar.entities, products, gridIndex),
              };
            }
          }

          const isEmpty = this.isEmpty(grid, { x: entity.x, y: entity.y }, bar.sizes[gridIndex].width, bar.sizes[gridIndex].height, bar.id);

          if (!isEmpty) {
            results.push({ ...entity, gridIndex });
            break;
          }

          this.fillSubArray(
            grid,
            entity.x,
            entity.y,
            bar.sizes[gridIndex].width,
            bar.sizes[gridIndex].height,
            entity.id,
            entity.articleNumber,
            cell.entity.type,
            bar,
          );
          break;
        }

        case 'PLACEHOLDER': {
          const { entity, placeholder } = cell;

          const isEmpty = this.isEmpty(
            grid,
            { x: entity.x, y: entity.y },
            placeholder.sizes[gridIndex].width,
            placeholder.sizes[gridIndex].height,
            placeholder.id,
          );

          if (!isEmpty) {
            results.push({ ...entity, gridIndex });
            break;
          }

          this.fillSubArray(
            grid,
            entity.x,
            entity.y,
            placeholder.sizes[gridIndex].width,
            placeholder.sizes[gridIndex].height,
            entity.id,
            entity.articleNumber,
            cell.entity.type,
            placeholder,
          );
          break;
        }

        default: {
          const { entity } = cell;
          const product = products.find(x => x.articleNumber === cell.entity.articleNumber);

          if (!product) {
            console.log(`Missing product ${cell.entity.articleNumber} in grid ${gridIndex}`);
            break;
          }

          if (product.sizes[gridIndex] === undefined) {
            console.log(`missing sizes for grid index ${gridIndex}`);
            break;
          }

          const isEmpty = this.isEmpty(
            grid,
            { x: entity.x, y: entity.y },
            product.sizes[gridIndex].width,
            product.sizes[gridIndex].height,
            product.id,
          );

          if (!isEmpty) {
            results.push({ ...entity, gridIndex });
            break;
          }

          this.fillSubArray(
            grid,
            entity.x,
            entity.y,
            product.sizes[gridIndex].width,
            product.sizes[gridIndex].height,
            entity.id,
            entity.articleNumber,
            cell.entity.type,
          );
          break;
        }
      }
    }

    return results;
  }

  /**
   * Returns a flat renderable 2D list of Product and Entities
   * @param {*} grid
   * @param {*} products
   */
  getEntitiesFrom2d(grid, gridId, gridState) {
    const emptyCell = -1;
    const edges = {};
    const entitiesToReturn = [];

    const rows = grid.length;
    let columns;
    if (rows > 0) {
      columns = grid[0].length;
    } else {
      columns = 0;
    }

    // Loop through 2D Array
    for (let y = 0; y < columns; y += 1) {
      for (let x = 0; x < rows; x += 1) {
        // Check for an empty cell
        if (grid[x][y].id !== emptyCell) {
          // Use the value as a key in our edges
          const cellData = grid[x][y];

          if (edges[cellData.id] === undefined) {
            edges[cellData.id] = {
              type: cellData.type,
              id: cellData.id,
              articleNumber: cellData.articleNumber,
              edges: [],
              bar: cellData.bar,
              placeholder: cellData.placeholder,
            };
          }
          edges[cellData.id].edges.push({ x, y });
        }
      }
    }

    const keys = Object.keys(edges);
    // Now we have a list of all the edges, we can get the root position (0, 0 coord) from them.
    for (let index = 0; index < keys.length; index += 1) {
      const key = keys[index];
      const entity = edges[`${key}`];
      let rootX = Infinity;
      let rootY = Infinity;
      let offsetX = -1;
      let offetY = -1;

      // Find Top Left (RootX, RootY) and Bottom Right (offsetX, offsetY) cells
      for (let i = 0; i < entity.edges.length; i += 1) {
        const edge = entity.edges[i];
        if (edge.x < rootX) {
          rootX = edge.x;
        }

        if (edge.y < rootY) {
          rootY = edge.y;
        }

        if (edge.x > offsetX) {
          offsetX = edge.x;
        }

        if (edge.y > offetY) {
          offetY = edge.y;
        }
      }

      switch (entity.type) {
        case 'BAR': {
          entitiesToReturn.push({
            entity: {
              type: entity.type,
              gridId,
              id: entity.id,
              articleNumber: entity.articleNumber,
              x: rootX,
              y: rootY,
            },
            bar: entity.bar,
          });
          break;
        }

        case 'PLACEHOLDER': {
          entitiesToReturn.push({
            entity: {
              type: entity.type,
              gridId,
              id: entity.id,
              articleNumber: entity.articleNumber,
              x: rootX,
              y: rootY,
            },
            placeholder: entity.placeholder,
          });
          break;
        }

        default: {
          // Lookup product
          const product = gridState.data.find(x => x.articleNumber === entity.articleNumber);

          if (product !== undefined) {
            // Push into the response model
            entitiesToReturn.push({
              entity: {
                type: 'PRODUCT',
                gridId,
                id: entity.id,
                articleNumber: entity.articleNumber,
                x: rootX,
                y: rootY,
              },
            });
          }
          break;
        }
      }
    }

    return entitiesToReturn;
  }

  getEntitiesFromGrids(grids, gridId, gridState) {
    const entities = [];

    for (let index = 0; index < grids.length; index += 1) {
      const grid = grids[index];

      entities[index] = this.getEntitiesFrom2d(grid, gridId, gridState);
    }

    return entities;
  }

  /**
   * Fills a 2D Array inside of the given 2D Array
   * @param {*} grid
   * @param {number} x
   * @param {number} y
   * @param {number} width
   * @param {number} height
   * @param {number} id
   */
  fillSubArray(grid, x, y, width, height, id, articleNumber, type = '', data = undefined) {
    for (let i = x; i < x + width; i += 1) {
      for (let j = y; j < y + height; j += 1) {
        const cell = { id, articleNumber };

        if (type.length > 0) {
          cell.type = type;
        }

        switch (type) {
          default:
            break;

          case 'BAR': {
            cell.bar = data;
            break;
          }
          case 'PLACEHOLDER': {
            cell.placeholder = data;
            break;
          }
        }

        if (grid[i] === undefined || grid[i][j] === undefined) {
          // todo: this should probably do something
        } else {
          grid[i][j] = cell;
        }
      }
    }
  }

  /**
   * Determines if the position is available in the given grid
   * @param {*} grid
   * @param {*} position
   * @param {*} params
   */
  isEmpty(grid, position, width, height, id, group = []) {
    let isEmpty = true;
    const { x, y } = position;

    if (x === undefined || y === undefined) {
      return false;
    }

    if (x < 0 || y < 0) {
      return false;
    }

    if (x + width > grid.length) {
      return false;
    }

    if (y + height > grid[0].length) {
      return false;
    }

    for (let i = x; i < x + width; i += 1) {
      for (let j = y; j < y + height; j += 1) {
        const gridValue = grid[i][j];

        if (group.length === 0) {
          // If the value in the grid spot is NOT the same as the current product
          if (gridValue.id !== -1 && `${gridValue.id}` !== `${id}`) {
            isEmpty = false;
          }
        } else if (gridValue.id !== -1 && `${gridValue.id}` !== `${id}` && group.indexOf(gridValue.id) === -1) {
          isEmpty = false;
        }
      }
    }
    return isEmpty;
  }

  /**
   * Returns the next available space in the grid using the given settings
   * @param {*} grid
   * @param {*} entity
   * @param {*} settings
   */
  getNextAvailableSpace(grid, width, height, id, gridId, settings = {}) {
    // const t0 = performance.now();
    const rows = grid.length;
    let columns = grid[0].length;

    if (gridId === GRIDS.BAR) {
      columns = 2;
    }

    const { fillDirection } = settings;

    switch (fillDirection) {
      default:
      case FILL_MODES.ROWS: {
        for (let y = 0; y < columns; y += 1) {
          for (let x = 0; x < rows; x += 1) {
            const isEmpty = this.isEmpty(grid, { x, y }, width, height, id);
            if (isEmpty) {
              // const t1 = performance.now();
              // console.log(`Found empty grid position in ${t1 - t0} milliseconds.`);
              return { x, y };
            }
          }
        }
        break;
      }

      case FILL_MODES.COLUMNS: {
        for (let x = 0; x < rows; x += 1) {
          for (let y = 0; y < columns; y += 1) {
            const isEmpty = this.isEmpty(grid, { x, y }, width, height, id);
            if (isEmpty) {
              // var t1 = performance.now();
              // console.log("Found empty grid position in " + (t1 - t0) + " milliseconds.")
              return { x, y };
            }
          }
        }
        break;
      }
    }

    return undefined;
  }

  /**
   * Returns true if the sizes fit inside the given grid
   */
  fitsInsideGrid(grid, width, height) {
    const rows = grid.length;
    const columns = grid[0].length;

    if (width > rows || height > columns) {
      return false;
    }

    return true;
  }

  /**
   * Returns the direction between the two given points
   * @param {Point (X, Y)} p1
   * @param {Point (X, Y)} p2
   */
  getDirectionBetweenPoints(p1, p2) {
    if (p2.x > p1.x && p2.y === p1.y) {
      return DIRECTIONS.RIGHT;
    }

    if (p2.x < p1.x && p2.y === p1.y) {
      return DIRECTIONS.LEFT;
    }

    if (p2.y < p1.y && p2.x === p1.x) {
      return DIRECTIONS.UP;
    }

    if (p2.y > p1.y && p2.x === p1.x) {
      return DIRECTIONS.DOWN;
    }

    if (p2.x > p1.x && p2.y > p1.y) {
      return DIRECTIONS.DOWN_RIGHT;
    }

    if (p2.x < p1.x && p2.y > p1.y) {
      return DIRECTIONS.DOWN_LEFT;
    }

    if (p2.x > p1.x && p2.y < p1.y) {
      return DIRECTIONS.UP_RIGHT;
    }

    if (p2.x < p1.x && p2.y < p1.y) {
      return DIRECTIONS.UP_LEFT;
    }

    return '';
  }

  /**
   * Rebases the X and Y coords onto the given absolute position using a new origin
   * @param {Point (X, Y)} origin
   * @param {Point (X, Y)} absolute
   * @param {Point (X, Y)} position
   */
  getRelativePosition(origin, absolute, { x, y }) {
    if (absolute === undefined || origin === undefined) {
      return undefined;
    }

    return {
      x: absolute.x + x - origin.x,
      y: absolute.y + y - origin.y,
    };
  }

  /**
   * Returns the unique list of products from the product/entity pairs.
   * @param {Array} products
   */
  getUniqueProductsFromProject(project) {
    const uniqueProducts = [];
    let allArticleNumbers = [];

    for (let index = 0; index < project.projectBays.length; index += 1) {
      const bay = project.projectBays[index];

      if (bay.jsonData === '') {
        // eslint-disable-next-line no-continue
        continue;
      }

      const grids = JSON.parse(bay.jsonData);

      for (let level = 0; level < grids.length; level += 1) {
        const grid = grids[level];

        for (let j = 0; j < grid.length; j += 1) {
          const cell = grid[j];

          switch (cell.entity.type) {
            case Types.BAR: {
              const allEntities = cell.bar.entities.map(x => x.entity).filter(x => x.type === Types.PRODUCT);
              allArticleNumbers = allArticleNumbers.concat(allEntities.map(x => x.articleNumber));
              break;
            }

            case Types.PLACEHOLDER: {
              break;
            }

            default: {
              allArticleNumbers.push(cell.entity.articleNumber);
              break;
            }
          }
        }
      }
    }

    for (let index = 0; index < allArticleNumbers.length; index += 1) {
      const articleNumber = allArticleNumbers[index];

      const existingIndex = uniqueProducts.findIndex(x => x.articleNumber === articleNumber);

      if (existingIndex === -1) {
        uniqueProducts.push({ articleNumber, quantity: 1 });
      } else {
        uniqueProducts[existingIndex].quantity += 1;
      }
    }

    return uniqueProducts;
  }

  /**
   * Returns the unique list of products from the product/entity pairs.
   * @param {Array} products
   */
  getUniqueProductsFromProjectBay(bay) {
    const uniqueProducts = [];
    let allArticleNumbers = [];

    if (bay.jsonData === '') {
      return uniqueProducts;
    }

    const grids = JSON.parse(bay.jsonData);

    for (let level = 0; level < grids.length; level += 1) {
      const grid = grids[level];

      for (let j = 0; j < grid.length; j += 1) {
        const cell = grid[j];

        switch (cell.entity.type) {
          case Types.BAR: {
            const allEntities = cell.bar.entities.map(x => x.entity).filter(x => x.type === Types.PRODUCT);
            allArticleNumbers = allArticleNumbers.concat(allEntities.map(x => x.articleNumber));
            break;
          }

          case Types.PLACEHOLDER: {
            break;
          }

          default: {
            allArticleNumbers.push(cell.entity.articleNumber);
            break;
          }
        }
      }
    }

    for (let index = 0; index < allArticleNumbers.length; index += 1) {
      const articleNumber = allArticleNumbers[index];

      const existingIndex = uniqueProducts.findIndex(x => x.articleNumber === articleNumber);

      if (existingIndex === -1) {
        uniqueProducts.push({ articleNumber, quantity: 1 });
      } else {
        uniqueProducts[existingIndex].quantity += 1;
      }
    }

    return uniqueProducts;
  }

  /**
   * Fleshes out an array of article numbers into products.
   * @param {Array} articleNumbers
   * @param {Array} products
   */
  getProductsFromArticleNumbers(articleNumbers, products) {
    const productsForThisBay = articleNumbers.flatMap(articleNumber => {
      const possibleProduct = products.find(x => x.articleNumber === articleNumber);
      if (possibleProduct) {
        return [possibleProduct];
      }
      return [];
    });
    return productsForThisBay;
  }

  /**
   * Returns the placeholders from the grids
   * @param {Array} products
   */
  getAllPlaceholders(allEntities) {
    let placeholders = [];

    for (let i = 0; i < allEntities.length; i += 1) {
      const grid = allEntities[i];

      for (let j = 0; j < grid.length; j += 1) {
        const cell = grid[j];

        switch (cell.entity.type) {
          case Types.BAR: {
            const barPlaceholders = cell.bar.entities.flat().filter(x => x.type === Types.PLACEHOLDER);
            placeholders = placeholders.concat(barPlaceholders);
            break;
          }

          case Types.PLACEHOLDER: {
            placeholders.push(cell);
            break;
          }

          default:
            break;
        }
      }
    }

    return placeholders;
  }

  /**
   * Converts the width and height of the product using the grid settings
   * @param product
   * @param gridSettings
   */
  normaliseProductSize(product, gridSettings) {
    const normalisedProduct = { ...product };

    // Set original sizes
    normalisedProduct.originalWidth = normalisedProduct.width;
    normalisedProduct.originalHeight = normalisedProduct.height;

    if (gridSettings.length === 0) {
      return normalisedProduct;
    }

    // Create a sizes array
    normalisedProduct.sizes = [];

    for (let index = 0; index < gridSettings.length; index += 1) {
      const settings = gridSettings[index];

      // Set the dimensions
      normalisedProduct.sizes[index] = {
        width: this.getDimension(product.width, settings.gridPitch, settings.gridThreshold),
        height: this.getDimension(product.height, settings.gridPitch, settings.gridThreshold),
      };
    }

    return normalisedProduct;
  }

  getDimension(value, divisor, threshold) {
    let normalised = 0;

    const mod = value % divisor;

    if (mod > threshold) {
      normalised = Math.ceil(value / divisor);
    } else {
      normalised = Math.floor(value / divisor);
    }

    return normalised;
  }

  /**
   * Returns the peg counts for the given products
   * @param products
   */
  getTotalPegs(allPegs, grids, allProducts, quantities) {
    const pegs = [];
    let articleNumbers = [];
    const products = [];

    // Get all the products from bars + cells
    for (let i = 0; i < grids.length; i += 1) {
      const cells = grids[i];

      for (let j = 0; j < cells.length; j += 1) {
        const cell = cells[j];

        if (cell.entity.type === 'BAR') {
          const allEntities = cell.bar.entities.flat().filter(x => x.entity.type === Types.PRODUCT);
          articleNumbers = articleNumbers.concat(allEntities.map(x => x.entity.articleNumber));
        } else if (cell.entity.type === Types.PRODUCT) {
          articleNumbers.push(cell.entity.articleNumber);
        }
      }
    }

    // Get the product models
    for (let index = 0; index < articleNumbers.length; index += 1) {
      const articleNumber = articleNumbers[index];
      products.push(allProducts.find(x => x.articleNumber === articleNumber));
    }

    // Calculate the peg counts
    for (let i = 0; i < products.length; i += 1) {
      const product = products[i];
      const productPeg = allPegs.find(x => x.articleNumber === product.pegArticleNumber);

      if (productPeg) {
        let productQuantity = quantities ? parseInt(quantities[product.articleNumber], 10) : 1;
        if (productQuantity === undefined || productQuantity < 1 || Number.isNaN(productQuantity)) {
          productQuantity = 1;
        }

        const existingIndex = pegs.findIndex(x => x.articleNumber === productPeg.articleNumber);
        if (existingIndex === -1) {
          const newPeg = { ...productPeg };
          newPeg.quantity = product.pegQuantity * productQuantity;

          pegs.push(newPeg);
        } else {
          pegs[existingIndex].quantity += product.pegQuantity * productQuantity;
        }
      }
    }

    return pegs;
  }

  /**
   * Sets the appropriate grid settings on the given element
   */
  setCssVariables(element, settings) {
    if (settings === undefined || element === null) {
      return;
    }

    // Create CSS Variables
    element.style.setProperty('--grid-steps-x', settings.gridStepsX);
    element.style.setProperty('--grid-steps-y', settings.gridStepsY);
    element.style.setProperty('--grid-gap', settings.gridGap);
    element.style.setProperty('--grid-pitch', settings.gridPitch);

    const width = settings.gridStepsX * settings.gridPitch;
    // eslint-disable-next-line no-param-reassign
    element.style.width = `${width}px`;

    const height = settings.gridStepsY * settings.gridPitch;
    // eslint-disable-next-line no-param-reassign
    element.style.height = `${height}px`;
  }

  /**
   * returns the grid position for the given grid
   */
  getGridPositionFromPosition(container, monitor, settings, zoomTransform) {
    const mousePosition = monitor.getClientOffset();

    const sourceOffset = monitor.getInitialSourceClientOffset();
    const clientOffset = monitor.getInitialClientOffset();

    const position = {
      x: mousePosition.x - (clientOffset.x - sourceOffset.x),
      y: mousePosition.y - (clientOffset.y - sourceOffset.y),
    };

    const offset = getOffset(container);
    const relativePosition = {
      x: position.x - offset.left + window.scrollX,
      y: position.y - offset.top + window.scrollY,
    };

    if (zoomTransform) {
      relativePosition.x = Math.round(relativePosition.x / zoomTransform.scale);
      relativePosition.y = Math.round(relativePosition.y / zoomTransform.scale);
    }

    const newPosition = {};

    if (relativePosition === null || relativePosition === undefined) {
      return null;
    }

    for (let x = 0; x <= settings.gridStepsX; x += 1) {
      if (relativePosition.x >= x && relativePosition.x <= x * (settings.gridPitch + settings.gridGap)) {
        newPosition.x = x - 1;
        // its gone out the bounds, we don't want this
        if (newPosition.x < 0) {
          newPosition.x = 0;
        }
        break;
      }
    }

    for (let y = 0; y <= settings.gridStepsY; y += 1) {
      if (relativePosition.y > y && relativePosition.y <= y * (settings.gridPitch + settings.gridGap)) {
        newPosition.y = y - 1;
        // its gone out the bounds, we don't want this
        if (newPosition.yx < 0) {
          newPosition.y = 0;
        }
        break;
      }
    }
    return newPosition;
  }

  /**
   * Returns the grid settings from the given wallbay type
   */
  getSettingsFromType(wallbayType) {
    return {
      gridStepsX: Math.ceil(wallbayType.wallbayWidth / 25),
      gridStepsY: Math.ceil(wallbayType.wallbayHeight / 25),
      gridGap: 0,
      gridPitch: 25,
      gridThreshold: 3,
    };
  }

  /**
   * Returns the tallest product from the bar
   */
  getBarHeight(cells, products, gridIndex) {
    let barHeight = 0;

    for (let y = 0; y < cells.length; y += 1) {
      const cell = cells[y];

      if (cell.entity.type === Types.PRODUCT) {
        const product = products.find(x => x.articleNumber === cell.entity.articleNumber);

        if (product && product.sizes[gridIndex].height > barHeight) {
          barHeight = product.sizes[gridIndex].height;
        }
      } else if (cell.entity.type === Types.PLACEHOLDER) {
        if (cell.placeholder.sizes[gridIndex].height > barHeight) {
          barHeight = cell.placeholder.sizes[gridIndex].height;
        }
      }
    }

    return barHeight + this.gridHeaderCellHeight;
  }

  isBarValid(name, entities) {
    const errors = [];

    if (name.length === 0) {
      errors.push('Your bar must have a name');
    }

    if (entities.length === 0) {
      errors.push('You must add atleast one product');
    }

    // Check all products have a y axis of 0 otherwise we won't accept em
    if (entities.length && entities.map(x => x.entity.y).reduce((accumulator, currentValue) => accumulator + currentValue) > 0) {
      errors.push('Products must be aligned along the top');
    }

    return errors;
  }

  getGridsFromEntities(grid) {
    const gridData = {
      [GRIDS.WALLBAY]: JSON.parse(JSON.stringify(SAVES.EMPTY)),
    };

    for (let gridIndex = 0; gridIndex < grid[GRIDS.WALLBAY].length; gridIndex += 1) {
      for (let index = 0; index < grid[GRIDS.WALLBAY][gridIndex].length; index += 1) {
        const currentGrid = grid[GRIDS.WALLBAY][gridIndex][index];
        let entities = this.tryGetEntities(currentGrid);

        if (entities === undefined) {
          entities = this.getEntitiesFrom2d(currentGrid || [], GRIDS.WALLBAY, grid);
        }

        if (!gridData[GRIDS.WALLBAY][gridIndex]) {
          gridData[GRIDS.WALLBAY][gridIndex] = [];
        }

        gridData[GRIDS.WALLBAY][gridIndex][index] = entities;
      }
    }

    return gridData;
  }

  tryGetEntities(grid) {
    if (grid === undefined || grid.length === 0) {
      return undefined;
    }

    if (grid[0].entity === undefined) {
      return undefined;
    }

    return grid;
  }

  formatProductImage = (assetUrl, width) => {
    let formattedAssetUrl = assetUrl;

    if (formattedAssetUrl.startsWith('http://')) {
      formattedAssetUrl = formattedAssetUrl.substring(7);
    } else if (formattedAssetUrl.startsWith('https://')) {
      formattedAssetUrl = formattedAssetUrl.substring(8);
    }

    if (width) {
      const seperator = assetUrl.indexOf('?') > -1 ? '&' : '?';
      formattedAssetUrl = `${formattedAssetUrl}${seperator}width=${width}`;
    }

    return `${process.env.REACT_APP_REMOTE_HANDLER_URL}/${formattedAssetUrl}`;
  };

  getAlignedPositions = (selectedEntities, grid) => {
    const entities = selectedEntities.sort((a, b) => a.entity.x - b.entity.x);
    const lowestYValue = entities.map(x => x.entity.y).sort((a, b) => a - b)[0];
    const positions = [];

    entities.forEach(e => {
      let newPos = { x: e.entity.x, y: e.entity.y };

      if (newPos.y > lowestYValue) {
        while (
          GridService.isEmpty(
            grid[e.entity.gridId][e.gridIndex][e.gridLevel],
            newPos,
            e.product.sizes[0].width,
            e.product.sizes[0].height,
            e.entity.id,
          ) &&
          newPos.y > lowestYValue
        ) {
          newPos = { ...newPos, y: newPos.y - 1 };
        }

        // The while loop runs one too many times if there is a product blocking the current product from moving any further up //
        // Need to figure out a way to do this more elegantly, but if the current position //
        // it's about to try move to is occupied, move it back down one //
        if (
          !GridService.isEmpty(
            grid[e.entity.gridId][e.gridIndex][e.gridLevel],
            newPos,
            e.product.sizes[0].width,
            e.product.sizes[0].height,
            e.entity.id,
          )
        ) {
          newPos = { ...newPos, y: newPos.y + 1 };
        }
      }

      positions.push(newPos);
    });

    return positions;
  };
}

export default new GridService();
