import React, { Fragment } from 'react';
import Inventory from '@components/inventory/Inventory';
import Navigation from '@components/navigation/Navigation';
import withProject from '@js/hocs/withProject';

const Output = () => {
  return (
    <Fragment>
      <div className="body__bg"></div>
      <Navigation />
      <main className="inventory">
        <Inventory />
      </main>
    </Fragment>
  );
};

export default withProject(Output);
