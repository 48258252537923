import React from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import store, { history } from '@redux/store';
import { PassportContextProvider, PassportEnums } from '@tti/passport';
import Router from '@js/pages/Router';
import { appInsightsReactPlugin } from 'js/appInsights/AppInsightsContext';
import { withAITracking } from '@microsoft/applicationinsights-react-js';

const App = () => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <PassportContextProvider siteID={PassportEnums.Site.ProfileManager}>
        <Router />
      </PassportContextProvider>
    </ConnectedRouter>
  </Provider>
);

export const browserHistory = history;

export default withAITracking(appInsightsReactPlugin, App);
