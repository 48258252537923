import { View } from '@react-pdf/renderer';
import React from 'react';
import { brand } from '../styles';

const PlaceHolder = ({ entity: { x, y }, placeholder: { sizes }, pegSizeInPt }) => {
  const { width, height } = sizes[0];
  return (
    <View
      style={{
        position: 'absolute',
        top: y * pegSizeInPt,
        left: x * pegSizeInPt,
        width: width * pegSizeInPt,
        height: height * pegSizeInPt,
        border: '1 solid black',
        backgroundColor: brand.reallyDarkRed,
        borderRadius: '2',
      }}
    />
  );
};

export default PlaceHolder;
