import { closeOverflowModal } from 'js/redux/actioncreators/grid';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import Modal from '../shared/Modal';
import { t } from '../translations/index';

const Overflow = ({ grid, closeModal }) => {
  return (
    <Fragment>
      <Modal showClose={true} setIsOpen={() => closeModal()} isOpen={grid.overflowModalOpen}>
        <h3>{t('profile-manager.overflow.title')}</h3>
        <hr />
        {grid.overflow.length > 0 && (
          <ul>
            {grid.overflow
              .reduce((acc, cur) => {
                // Remove duplicates //
                if (!acc.find(x => x.product.modelName === cur.product.modelName)) {
                  acc.push(cur);
                }

                return acc;
              }, [])
              .map((m, i) => (
                <li key={`Overflow_${i}`}>
                  <strong>{m.product.articleNumber}</strong> - {m.product.variantName}
                </li>
              ))}
          </ul>
        )}
      </Modal>
    </Fragment>
  );
};

const mapStateToProps = state => ({
  project: state.app.present.project,
  grid: state.app.present.grid,
});

const mapDispatchToProps = dispatch => ({
  closeModal: params => dispatch(closeOverflowModal(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Overflow);
