import React from 'react';
import { Link } from 'react-router-dom';

import Navigation from '@components/navigation/Navigation';
import { Translate } from '@components/translations';

const Error404 = () => (
  <div className="site-wide error-404">
    <div className="body__bg"></div>
    <Navigation />
    <main>
      <div className="site-wide">
        <p>
          <Translate resourceString="profile-manager.404.message" />
          <br />
          <br />
          <Link to="/">
            <Translate resourceString="profile-manager.404.backhome" />
          </Link>
        </p>
      </div>
    </main>
  </div>
);

export default Error404;
