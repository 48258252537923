import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import Logo from '@img/svg/logo.svg';
import OutputLink from './buttons/OutputLink';
import SaveAsTemplate from './buttons/SaveAsTemplate';
import LanguageSelector from './buttons/LanguageSelector';
import SignOut from './buttons/SignOut';
import AlignmentModal from './buttons/AlignmentModal';

const Navigation = props => {
  const { showSave, children } = props;

  return (
    <nav className="navigation">
      <Link to="/">
        <div className="navigation-logo">
          <img src={Logo} alt="TTI Milwaukee Wallbay" />
        </div>
      </Link>
      <div className="navigation__content">{children}</div>
      <div className="navigation__content navigation__content--bottom">
        {showSave && (
          <Fragment>
            <SaveAsTemplate />
            <OutputLink />
          </Fragment>
        )}
        <AlignmentModal />
        <LanguageSelector />
        <SignOut />
      </div>
    </nav>
  );
};

export default Navigation;
