import React, { useState } from 'react';
import classNames from 'classnames';

const Accordion = ({ title, children }) => {
  const [isActive, setIsActive] = useState(false);

  const handleClick = () => {
    if (isActive) {
      setIsActive(false);
    } else {
      setIsActive(true);
    }
  };

  const accordionClassNames = classNames({
    accordion: true,
    'accordion--active': isActive,
  });

  return (
    <div className={accordionClassNames}>
      <div className="accordion__title" onClick={() => handleClick()}>
        {title}
      </div>
      <div className="accordion__content">{children}</div>
    </div>
  );
};

export default Accordion;
