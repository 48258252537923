import React, { useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { fetchProject, clearProject } from '@redux/actioncreators/project';
import { populate } from '@redux/actioncreators/grid';
import { clearHistory } from '@redux/actioncreators/history';
import { clearNavigation } from '@redux/actioncreators/app';
import GRIDS from '@enums/Grids';
import Loader from '@components/shared/Loader';

const withProject = BaseComponent => props => {
  const { match, project, grid, fetchProjectInternal, clearNavigationInternal, clearHistoryInternal, clearProjectInternal } = props;
  const { projectGuid } = match.params;

  useEffect(() => {
    fetchProjectInternal(projectGuid);

    // On Unmount, clear project
    return () => {
      clearProjectInternal();
      clearHistoryInternal();
      clearNavigationInternal();
    };
  }, []);

  if ((project.projectLoading === true && project.projectGuid === undefined) || project.projectGuid === undefined) {
    return (
      <div className="full-loader-container">
        <Loader />
      </div>
    );
  }

  const baseComponent = <BaseComponent {...props} />;
  return baseComponent;
};

const mapStateToProps = state => ({
  project: state.app.present.project,
  grid: state.app.present.grid,
});

const mapDispatchToProps = dispatch => ({
  fetchProjectInternal: projectGuid => dispatch(fetchProject(projectGuid)),
  clearProjectInternal: () => dispatch(clearProject()),
  clearNavigationInternal: () => dispatch(clearNavigation()),
  clearHistoryInternal: () => dispatch(clearHistory()),
  populateInternal: params => dispatch(populate(params)),
});

const composedWithProject = compose(connect(mapStateToProps, mapDispatchToProps), withProject);

export default composedWithProject;
