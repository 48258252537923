import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { PDFViewer } from '@react-pdf/renderer';
import InventoryPdfDocument from 'js/components/client-pdf/InventoryPdfDocument';
import composedWithProject from 'js/hocs/withProject';
import { getAllPegs } from 'js/redux/actioncreators/grid';

const ReactPdfPreview = ({ match }) => {
  const store = useSelector(state => state);
  const dispatch = useDispatch();
  const { grid } = store.app.present;

  const { projectGuid } = match.params;

  useEffect(() => {
    dispatch(getAllPegs());
  }, []);

  if (!projectGuid) {
    throw new Error('where the guid?');
  }

  if (grid.pegsLoading === true || grid.pegs.length === 0 || grid.dataLoading === true) {
    return null;
  }

  return (
    <div>
      <PDFViewer style={{ width: '100vw', height: '100vh' }}>
        <InventoryPdfDocument store={store} />
      </PDFViewer>
    </div>
  );
};

export default composedWithProject(ReactPdfPreview);
