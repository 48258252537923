import ACTIONS from '@redux/actions';
import LocalStorage, { KEYS } from '@js/storage/localStorage';
import { getScaleFactor } from '@utils';
import AppInsightsContext from 'js/appInsights/AppInsightsContext';

const initialState = {
  uiCultureCode: LocalStorage.get(KEYS.UI_CULTURE) || 'en-TT',
  // current culture of the app
  cultureCode: 'en-TT',
  // translations
  translations: {},
  // cultures to display
  cultures: [
    {
      cultureCode: 'en-TT',
      resourceString: 'lang_en-tt',
    },
    {
      cultureCode: 'en-GB',
      resourceString: 'lang_en-gb',
    },
    {
      cultureCode: 'fr-FR',
      resourceString: 'lang_fr-fr',
    },
    {
      cultureCode: 'de-DE',
      resourceString: 'lang_de-de',
    },
    {
      cultureCode: 'es-ES',
      resourceString: 'lang_es-es',
    },
    {
      cultureCode: 'pt-PT',
      resourceString: 'lang_pt-pt',
    },
    {
      cultureCode: 'fr-BE',
      resourceString: 'lang_fr-be',
    },
    {
      cultureCode: 'hu-HU',
      resourceString: 'lang_hu-hu',
    },
    {
      cultureCode: 'fr-LU',
      resourceString: 'lang_fr-lu',
    },
    {
      cultureCode: 'nl-BE',
      resourceString: 'lang_nl-be',
    },
    {
      cultureCode: 'nl-NL',
      resourceString: 'lang_nl-nl',
    },
    {
      cultureCode: 'ro-RO',
      resourceString: 'lang_ro-ro',
    },
    {
      cultureCode: 'sk-SK',
      resourceString: 'lang_sk-sk',
    },
    {
      cultureCode: 'pl-PL',
      resourceString: 'lang_pl-pl',
    },
    {
      cultureCode: 'cs-CZ',
      resourceString: 'lang_cs-cz',
    },
  ],
  // translations loading
  translationsLoading: false,
  // To show if the app NEEDS saving
  isDirty: false,
  // To show if the app is saving
  isSaving: false,
  // used to see if the app is initally loading (api is down / slow)
  appLoading: true,
  // Current user guid
  userGuid: undefined,
  // if the app is online
  online: false,
  // the current active sub-nav
  subNav: undefined,
  // the current active hover nav
  hoverNav: undefined,
  // current active bay to next add products to
  selectedBay: -1,
  // if product images should be shown
  showProductImages: true,
  // if the grid background should be shown
  showGridBackground: true,
  // if grid item backgrounds should be shown
  showBackgrounds: true,
  // Magnify / Zoom
  zoom: 1.0,
  // Shift being pressed //
  shiftPressed: false,
  // Dragselect Initialised //
  dragSelectInitialised: false,
  // Ctrl being pressed //
  ctrlPressed: false,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.APP.IS_DIRTY: {
      const newState = JSON.parse(JSON.stringify(state));

      newState.isDirty = true;
      return newState;
    }

    case ACTIONS.APP.SAVE_FETCH: {
      const newState = JSON.parse(JSON.stringify(state));

      newState.isSaving = true;
      return newState;
    }

    case ACTIONS.APP.SAVE: {
      const newState = JSON.parse(JSON.stringify(state));

      newState.isSaving = false;
      newState.isDirty = false;

      AppInsightsContext.current.trackEvent({ name: 'Saving project' });

      return newState;
    }

    case ACTIONS.APP.SET_NETWORK_STATUS: {
      const newState = JSON.parse(JSON.stringify(state));

      newState.online = action.payload;
      return newState;
    }

    case ACTIONS.APP.OPEN_SUB_NAV: {
      const newState = JSON.parse(JSON.stringify(state));

      // Set the sub nav
      newState.subNav = action.payload;
      // close  the hover nav
      newState.hoverNav = initialState.hoverNav;

      AppInsightsContext.current.trackEvent({ name: `Open nav ${action.payload}` });

      return newState;
    }

    case ACTIONS.APP.CLOSE_SUB_NAV: {
      const newState = JSON.parse(JSON.stringify(state));

      newState.subNav = initialState.subNav;

      if (action.payload.resetSelectedBay) {
        newState.selectedBay = initialState.selectedBay;
      }

      AppInsightsContext.current.trackEvent({ name: 'Close nav' });

      return newState;
    }

    case ACTIONS.PROJECT.SET_PROJECT: {
      const newState = JSON.parse(JSON.stringify(state));

      newState.selectedBay = initialState.selectedBay;

      return newState;
    }

    case ACTIONS.APP.OPEN_HOVER_NAV: {
      const newState = JSON.parse(JSON.stringify(state));

      newState.hoverNav = action.payload;
      // Set the hover nav
      newState.hoverNav = action.payload;
      // close  the sub nav
      newState.subNav = initialState.hoverNav;

      AppInsightsContext.current.trackEvent({ name: `Open hover nav ${action.payload}` });

      return newState;
    }

    case ACTIONS.APP.CLOSE_HOVER_NAV: {
      const newState = JSON.parse(JSON.stringify(state));

      newState.hoverNav = initialState.hoverNav;

      if (action.payload.resetSelectedBay) {
        newState.selectedBay = initialState.selectedBay;
      }

      AppInsightsContext.current.trackEvent({ name: 'Close hover nav' });

      return newState;
    }

    case ACTIONS.APP.CLEAR_NAVIGATION: {
      const newState = JSON.parse(JSON.stringify(state));

      newState.hoverNav = initialState.hoverNav;
      newState.subNav = initialState.subNav;
      newState.selectedBay = initialState.selectedBay;

      AppInsightsContext.current.trackEvent({ name: 'Close all navs' });

      return newState;
    }

    case ACTIONS.APP.TOGGLE_PRODUCT_IMAGES: {
      const newState = JSON.parse(JSON.stringify(state));

      newState.showProductImages = !state.showProductImages;

      AppInsightsContext.current.trackEvent({ name: 'Toggling product images' });

      return newState;
    }

    case ACTIONS.APP.TOGGLE_GRID_BACKGROUND: {
      const newState = JSON.parse(JSON.stringify(state));

      newState.showGridBackground = !state.showGridBackground;

      AppInsightsContext.current.trackEvent({ name: 'Toggling grid background' });

      return newState;
    }

    case ACTIONS.APP.TOGGLE_BACKGROUND: {
      const newState = JSON.parse(JSON.stringify(state));

      newState.showBackgrounds = !state.showBackgrounds;

      AppInsightsContext.current.trackEvent({ name: 'Toggling backgrounds' });

      return newState;
    }

    case ACTIONS.APP.LOADED: {
      const newState = JSON.parse(JSON.stringify(state));

      newState.appLoading = action.payload;

      return newState;
    }

    case ACTIONS.APP.ZOOM_IN: {
      const newState = JSON.parse(JSON.stringify(state));

      newState.zoom = 1;

      return newState;
    }

    case ACTIONS.APP.SET_ZOOM: {
      const newState = JSON.parse(JSON.stringify(state));

      newState.zoom = action.payload;

      return newState;
    }

    case ACTIONS.APP.SET_ZOOM_TO_FIT: {
      const newState = JSON.parse(JSON.stringify(state));

      const main = document.querySelector('main');
      const target = document.querySelector('.grids-wrapper');

      const windowSize = {
        width: main.clientWidth,
        height: main.clientHeight,
      };

      const targetSize = {
        width: target.clientWidth,
        height: target.clientHeight,
      };

      // calculate the zoom factor based on size of the body relative to the viewport (so everything fits in)
      const scale = getScaleFactor(windowSize, targetSize, 10);

      // Update the scale
      newState.zoom = scale;

      return newState;
    }

    case ACTIONS.APP.ZOOM_RESET: {
      const newState = JSON.parse(JSON.stringify(state));

      newState.zoom = initialState.zoom;

      return newState;
    }

    case ACTIONS.APP.FETCH_TRANSLATIONS: {
      const newState = JSON.parse(JSON.stringify(state));

      newState.translationsLoading = true;

      AppInsightsContext.current.trackEvent({ name: 'Fetching translations' });

      return newState;
    }

    case ACTIONS.APP.FETCH_TRANSLATIONS_SUCCESS: {
      const newState = JSON.parse(JSON.stringify(state));

      newState.translations = { ...action.payload };
      newState.translationsLoading = false;

      AppInsightsContext.current.trackEvent({ name: 'Fetched translations' });

      return newState;
    }

    case ACTIONS.APP.SET_CULTURE: {
      const newState = JSON.parse(JSON.stringify(state));

      LocalStorage.set(KEYS.UI_CULTURE, action.payload);
      newState.uiCultureCode = action.payload;

      AppInsightsContext.current.trackEvent({ name: 'Setting UI culture' });

      return newState;
    }

    case ACTIONS.APP.SET_SELECTED_BAY: {
      const newState = JSON.parse(JSON.stringify(state));

      newState.selectedBay = action.payload;

      AppInsightsContext.current.trackEvent({ name: 'Setting selected bay' });

      return newState;
    }

    case ACTIONS.GRID.ADD_BAR: {
      const newState = JSON.parse(JSON.stringify(state));

      newState.selectedBay = initialState.selectedBay;

      return newState;
    }

    case ACTIONS.APP.SET_CUSTOM_BAR_NAME: {
      const newState = { ...state };

      if (action.payload) {
        newState.customBarName = action.payload;
      }

      AppInsightsContext.current.trackEvent({ name: 'Setting custom bar name' });

      return newState;
    }

    case ACTIONS.APP.SET_CUSTOM_BAR_LEVEL: {
      const newState = { ...state };

      newState.customBarLevel = action.payload;

      AppInsightsContext.current.trackEvent({ name: 'Setting custom bar level' });

      return newState;
    }

    case ACTIONS.APP.TOGGLE_SHIFT_PRESSED: {
      const newState = JSON.parse(JSON.stringify(state));

      newState.shiftPressed = action.payload;

      AppInsightsContext.current.trackEvent({ name: 'Toggling shift pressed' });

      return newState;
    }

    case ACTIONS.APP.TOGGLE_CTRL_PRESSED: {
      const newState = JSON.parse(JSON.stringify(state));

      newState.ctrlPressed = action.payload;

      AppInsightsContext.current.trackEvent({ name: 'Toggling ctrl pressed' });

      return newState;
    }

    case ACTIONS.APP.DRAGSELECT_INITIALISED: {
      const newState = JSON.parse(JSON.stringify(state));

      newState.dragSelectInitialised = true;
      newState.imLosingMyMind += 1;

      AppInsightsContext.current.trackEvent({ name: 'Dragselect initialised' });

      console.log("I've initialised dragselect", newState.imLosingMyMind);
      return newState;
    }

    default:
      return state;
  }
}
