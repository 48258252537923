import React from 'react';
import Navigation from '@components/navigation/Navigation';
import ProjectSection from '@components/projects/ProjectSection';
import { Translate } from '@components/translations';

const Dashboard = () => (
  <React.Fragment>
    <div className="body__bg"></div>
    <Navigation></Navigation>
    <main className="homepage">
      <div className="site-wide">
        <div className="tabs">
          <button type="button" className="tab active">
            <Translate resourceString="profile-manager.recent-projects" />
          </button>
        </div>
        <p className="tabs__title">
          <Translate resourceString="profile-manager.recent-projects.description" />
        </p>

        <ProjectSection />
      </div>
    </main>
  </React.Fragment>
);

export default Dashboard;
