import React, { useEffect } from 'react';
import { usePassportContext, LoginRouter } from '@tti/passport';
import Loader from '@components/shared/Loader';
import { fetchTranslations } from '@redux/actioncreators/app';
import { connect } from 'react-redux';
import AppRouter from './AppRouter';

/**
 * If the cookie exists
 * we don't want to authenticate
 * we go straight to "landing"
 * 401 responses means the cookie is invalid, go to authentication
 */
const Router = ({ app, fetchTranslationsInternal }) => {
  const { passportContext } = usePassportContext();

  useEffect(() => {
    fetchTranslationsInternal({ cultureCode: app.uiCultureCode });
  }, [app.uiCultureCode]);

  // Check if we have a bearer token
  if (passportContext.bearerToken && passportContext.bearerToken.length > 0) {
    // Check if the user is still being validated
    if (passportContext.isValidating) {
      return (
        <div className="full-loader-container">
          <Loader />
        </div>
      );
    }

    // Else we show them the App
    return <AppRouter />;
  }

  // We show them the default passport login router
  return <LoginRouter successPath={'/'} />;
};

const mapStateToProps = state => ({
  app: state.app.present.app,
});

const mapDispatchToProps = dispatch => ({
  fetchTranslationsInternal: ({ cultureCode }) => dispatch(fetchTranslations({ cultureCode })),
});

export default connect(mapStateToProps, mapDispatchToProps)(Router);
