import React from 'react';
import { usePassportContext } from '@tti/passport';
// import Tooltip from 'js/components/navigation/Tooltip';
// import { t } from '@components/translations';

const SignOut = () => {
  const { logOut } = usePassportContext();

  return (
    // <Tooltip text={t('signout')}>
    <button type="button" className="navigation__icon navigation__icon--sign-out" onClick={logOut} />
    // </Tooltip>
  );
};

export default SignOut;
