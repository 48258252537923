import ACTIONS from '@redux/actions';
import ApiService from '@js/api/ApiService';
import GridService from '@js/grid/GridService';
import Grids from 'js/enums/Grids';

export const save = () => async (dispatch, getState) => {
  const state = getState();
  const { grid, project } = state.app.present;

  dispatch({ type: ACTIONS.APP.SAVE_FETCH });

  // Get the populated grids
  const populatedGrids = GridService.getGridsFromEntities(grid);

  if (project.projectGuid === undefined) {
    return undefined;
  }

  const baysToUpdate = [];

  // Populate the json data from the grid
  for (let index = 0; index < project.projectBays.length; index += 1) {
    // Get the bay
    const bay = project.projectBays[index];

    // Populate the jsonData
    bay.jsonData = JSON.stringify(populatedGrids[Grids.WALLBAY][index]);

    // populate the bays to update
    baysToUpdate[index] = {
      ...bay,
    };
  }

  // Request model
  const params = {
    projectData: {
      projectGuid: project.projectGuid,
      projectUserGuid: project.projectUserGuid,
      projectName: project.projectName,
      createdWhen: project.createdWhen,
      modifiedWhen: project.modifiedWhen,
      createdWhenFormatted: project.createdWhenFormatted,
      modifiedWhenFormatted: project.modifiedWhenFormatted,

      customerAddressLine1: project.customerAddressLine1,
      customerAddressLine2: project.customerAddressLine2,
      customerEmailAddress: project.customerEmailAddress,
      customerFirstName: project.customerFirstName,
      customerLastName: project.customerLastName,
      customerNotes: project.customerNotes,
      customerOrderNumber: project.customerOrderNumber,
      customerPostcode: project.customerPostcode,
      customerTownCity: project.customerTownCity,
      customerQuantities: project.customerQuantities,

      pdfSettingAddPlanogram: project.pdfSettingAddPlanogram,
      pdfSettingUseProductImages: project.pdfSettingUseProductImages,
      pdfSettingUseWhiteBorders: project.pdfSettingUseWhiteBorders,
      pdfSettingAddProductDetailPages: project.pdfSettingAddProductDetailPages,
      pdfSettingAddInventoryPage: project.pdfSettingAddInventoryPage,

      projectBays: [...baysToUpdate],
    },
  };

  try {
    await ApiService.request({ slug: 'update-project', verb: 'PUT', params });

    return dispatch({
      type: ACTIONS.APP.SAVE,
    });
  } catch (error) {
    return dispatch({
      type: ACTIONS.APP.SAVE_FETCH_ERROR,
      payload: error,
    });
  }
};
