import React from 'react';
import { View, Text, Image } from '@react-pdf/renderer';
import styles, { brand } from './styles';

const ProductDetails = ({ modelName, articleNumber, features, heroImage, applicationImage }) => {
  return (
    <View id="pageSplit" style={{ display: 'flex', flexDirection: 'row', height: '100%' }}>
      <View id="left" style={styles.halfSection}>
        <View>
          <Text style={[styles.heading, { marginBottom: 3 }]}>{modelName}</Text>
          <Text style={{ color: brand.darkRed }}>{articleNumber}</Text>
        </View>
        {heroImage ? <Image src={heroImage} /> : null}
        {applicationImage ? <Image src={applicationImage} /> : null}
      </View>
      <View
        id="right"
        style={[
          styles.halfSection,
          {
            alignSelf: 'stretch',
            backgroundColor: brand.lightGrey,
            // marginTop: -20,
          },
        ]}
      >
        <Text style={styles.heading}>Features</Text>
        {features.map(feature => (
          <Text style={{ marginBottom: 18 }} key={feature}>
            {feature}
          </Text>
        ))}
      </View>
    </View>
  );
};

export default ProductDetails;
