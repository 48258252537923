import React, { useState, Fragment, useRef, useEffect } from 'react';
import { useDrag, DragPreviewImage } from 'react-dnd';
import { ContextMenuTrigger } from 'react-contextmenu';
import classNames from 'classnames';
import TYPES from '@enums/Types';
import { Translate } from '@components/translations';
import Logo from '@img/svg/logo.svg';
import Types from 'js/enums/Types';
import GridService from 'js/grid/GridService';
import BAR_EDIT_MODE from '@enums/BarEditMode';
import Grids from 'js/enums/Grids';
import omit from 'lodash/omit';
import { connect } from 'react-redux';
import { clearSelectedEntities } from 'js/redux/actioncreators/grid';
import AbsoluteGridView from './AbsoluteGridView';
import { t } from '../translations/index';

const Cell = props => {
  const propsWithoutRefs = omit({ ...props }, ['dragSelectRef', 'clearSelectedEntitiesInternal']);
  const [image404, setImage404] = useState(false);
  // const [showTooltip, setShowTooltip] = useState(false);
  const {
    product,
    selectedEntities,
    bar,
    placeholder,
    entity,
    showProductImages,
    editBar,
    dragSelectRef,
    clearSelectedEntitiesInternal,
  } = props;
  const [isFaded, setIsFaded] = useState(false);
  const elementRef = useRef(null);

  const isBarModalOpen = props.barEditMode === BAR_EDIT_MODE.ADD || props.barEditMode === BAR_EDIT_MODE.EDIT;
  let disableContextMenu = false;
  if (entity.gridId === Grids.BAR) {
    // We're on a bar but are we on the main view or a modal view? //
    disableContextMenu = !isBarModalOpen;
  }

  const isSelected = selectedEntities.findIndex(x => x.entity.id === entity.id) > -1;

  const getDndModel = () => {
    if (entity.type === TYPES.BAR) {
      return {
        type: TYPES.BAR,
        cell: {
          entity,
          bar,
          gridIndex: props.gridIndex,
        },
      };
    }

    if (entity.type === TYPES.PLACEHOLDER) {
      return {
        type: TYPES.PLACEHOLDER,
        cell: {
          entity,
          placeholder,
          gridIndex: props.gridIndex,
        },
      };
    }

    // If there are any selected entities and this current product is a selected entity
    if (selectedEntities.length > 0 && selectedEntities.findIndex(x => x.entity.id === entity.id) > -1) {
      const entities = selectedEntities.map(x => ({ type: TYPES.PRODUCT, ...x }));
      return {
        type: TYPES.SELECTION,
        entities,
        origin: { x: entity.x, y: entity.y },
      };
    }

    // otherwise we return a single product
    return { type: TYPES.PRODUCT, cell: { ...propsWithoutRefs } };
  };

  const [{ isDragging }, drag, preview] = useDrag({
    item: { type: TYPES.PRODUCT, ...propsWithoutRefs },
    begin: () => {
      // setShowTooltip(false);

      // If we start dragging something that isn't the selected one, clear selected
      if (selectedEntities.findIndex(x => x.entity.id === entity.id) === -1) {
        clearSelectedEntitiesInternal();
      }

      return getDndModel();
    },
    end: () => getDndModel(),
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  useEffect(() => {
    if (elementRef?.current && dragSelectRef?.current) {
      dragSelectRef.current.addSelectables(elementRef.current);
    }
  }, [elementRef]);

  // + 1 due to CSS grid not being 0 indexed
  const getStyleFromPosition = () => {
    if (props.isAbsolute === true) {
      let widthObject;

      if (entity.type === Types.PRODUCT) {
        widthObject = product;
      } else if (entity.type === Types.PLACEHOLDER) {
        widthObject = placeholder;
      }

      return {
        position: 'absolute',
        top: '0px',
        left: '0px',
        transform: `translate(${entity.x}px, ${entity.y}px)`,
        width: `${widthObject.originalWidth}px`,
        height: `${widthObject.originalHeight}px`,
        zIndex: props.gridLevel > 0 ? props.gridLevel : undefined,
      };
    }

    if (entity.type === 'BAR') {
      return {
        gridColumnStart: entity.x + 1,
        gridColumnEnd: entity.x + bar.sizes[props.gridIndex].width + 1,
        gridRowStart: entity.y + 1,
        gridRowEnd: entity.y + bar.sizes[props.gridIndex].height + 1,
        zIndex: props.gridLevel > 0 ? props.gridLevel : undefined,
      };
    }

    if (entity.type === 'PLACEHOLDER') {
      return {
        gridColumnStart: entity.x + 1,
        gridColumnEnd: entity.x + placeholder.sizes[props.gridIndex].width + 1,
        gridRowStart: entity.y + 1,
        gridRowEnd: entity.y + placeholder.sizes[props.gridIndex].height + 1,
        zIndex: props.gridLevel > 0 ? props.gridLevel : undefined,
      };
    }

    return {
      gridColumnStart: entity.x + 1,
      gridColumnEnd: entity.x + product.sizes[props.gridIndex].width + 1,
      gridRowStart: entity.y + 1,
      gridRowEnd: entity.y + product.sizes[props.gridIndex].height + 1,
      zIndex: props.gridLevel > 0 ? props.gridLevel : undefined,
    };
  };

  const productImage = () => {
    if (entity.type !== 'PRODUCT') {
      return null;
    }

    let imageWidth;

    if (props.isAbsolute) {
      imageWidth = product.originalWidth;
    } else {
      imageWidth = product.sizes[props.gridIndex].width * props.gridSettings.gridPitch;
    }

    let backgroundImageUrl = `${process.env.REACT_APP_S3_IMAGES_BUCKET}/${product.articleNumber}--Pack_1.png`;
    backgroundImageUrl = GridService.formatProductImage(backgroundImageUrl, imageWidth);

    if (showProductImages && !image404) {
      return <img src={backgroundImageUrl} alt={product.variantName} onError={() => setImage404(true)} />;
    }

    return null;
  };

  const cellClassName = classNames({
    'wb-cell': true,
    'wb-cell--placeholder': entity.type === Types.PLACEHOLDER,
    'wb-cell--bar': entity.type === Types.BAR,
    'is-selected': isSelected,
    'is-dragging': isDragging,
    'has-product-image': productImage() !== null && image404 === false,
    'no-border': props.isAbsolute,
    'no-bg': props.noBg === true,
    faded: isFaded,
  });

  const handleEditClick = e => {
    if (entity.type !== 'BAR') {
      return;
    }

    e.preventDefault();
    e.stopPropagation();

    editBar({ bar, entity, gridIndex: props.gridIndex });
  };

  const getBarLevel = () => {
    if (entity.type !== 'BAR') {
      return '';
    }

    switch (bar.level) {
      default:
      case 0:
        return 'level--0';

      case 1:
        return 'level--1';

      case 2:
        return 'level--2';
    }
  };

  const handleSetVisible = e => {
    e.preventDefault();
    e.stopPropagation();

    setIsFaded(!isFaded);
  };

  const getAttributes = () => {
    if (product) {
      return JSON.stringify({
        title: product.variantName,
        subTitle: product.articleNumber,
        articleNumber: product.articleNumber,
        width: product.width,
        height: product.height,
      });
    }

    if (placeholder) {
      return JSON.stringify({
        title: placeholder.name,
        subTitle: `${placeholder.originalWidth}x${placeholder.originalHeight}`,
      });
    }

    return undefined;
  };

  const getContextMenuId = () => {
    switch (entity.type) {
      default:
        return '';

      case 'PRODUCT':
        return 'product-context-menu';

      case 'BAR':
        return 'bar-context-menu';

      case 'PLACEHOLDER':
        return 'placeholder-context-menu';
    }
  };

  return (
    <div
      ref={drag}
      role="button"
      tabIndex="0"
      className={`${cellClassName} ${getBarLevel()}`}
      onClick={() => props.onClick({ ...propsWithoutRefs })}
      onContextMenu={e => {
        props.onRightClick(e, { entity, bar, product });
      }}
      style={getStyleFromPosition()}
    >
      <DragPreviewImage
        connect={preview}
        src={'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='}
      ></DragPreviewImage>
      <ContextMenuTrigger
        id={getContextMenuId()}
        holdToDisplay={-1}
        attributes={{
          'data-entity': JSON.stringify({ ...entity, placeholder, bar, gridIndex: props.gridIndex }),
          'data-product': JSON.stringify(product),
          'data-bar': JSON.stringify(bar),
          'data-current-index': props.gridIndex,
          'data-inspect': getAttributes(),
        }}
        disable={disableContextMenu}
      >
        <div
          className="wb-cell__inner"
          ref={elementRef}
          id={entity.id}
          data-cell={JSON.stringify({ entity, product, gridLevel: props.gridLevel, gridIndex: props.gridIndex })}
        >
          {entity.type === Types.PLACEHOLDER && (
            <Fragment>
              <div className="wb-cell__corners">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </div>
            </Fragment>
          )}
          {entity.type === Types.BAR && (
            <Fragment>
              <div className="cell-bar-header">
                <div className="cell-bar-header__left"></div>
                <div className="cell-bar-header__main">
                  <img src={Logo} className="cell-bar-header__logo" />
                  <div className="cell-bar-header__title">
                    {t(`profile-manager.custombar.level.${bar.level}`)} - {bar.name}
                  </div>
                  <div className="cell-bar-header__spacer"></div>
                </div>
                <div className="cell-bar-header__right"></div>
                <div className={`wb-cell__controls wb-cell__controls__${bar.level}`}>
                  <div className="wb-cell__control">
                    <div className="wb-cell__tag">
                      <Translate resourceString={`profile-manager.custombar.level.${bar.level}`} />
                    </div>
                  </div>
                  <div className="wb-cell__control" onClick={handleSetVisible}>
                    <div className={`icon icon--visibility ${!isFaded ? 'active' : ''}`}></div>
                  </div>
                  <div className="wb-cell__control" onClick={handleEditClick}>
                    <div className="icon icon--edit"></div>
                  </div>
                </div>
              </div>
              <AbsoluteGridView key={entity.id} bar={bar} />
            </Fragment>
          )}
        </div>
        {entity.type !== Types.BAR && <div className="wb-cell__bg">{productImage()}</div>}
      </ContextMenuTrigger>
    </div>
  );
};
// const mapStateToProps = state => ({
// });

const mapDispatchToProps = dispatch => ({
  clearSelectedEntitiesInternal: () => dispatch(clearSelectedEntities()),
});

export default connect(null, mapDispatchToProps)(Cell);
