import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Translate } from '@components/translations';
import AbsoluteGridView from './AbsoluteGridView';

const GridBar = ({ grid, entity, bar, app }) => {
  // + 1 due to CSS grid not being 0 indexed
  // const getStyleFromPosition = () => ({
  //   gridColumnStart: entity.x + 1,
  //   gridColumnEnd: entity.x + product.width + 1,
  //   gridRowStart: entity.y + 1,
  //   gridRowEnd: entity.y + product.height + 1,
  // });

  const getStyleFromPosition = () => ({
    gridColumnStart: entity.x + 1,
    gridColumnEnd: entity.x + bar.width + 1,
    gridRowStart: entity.y + 1,
    gridRowEnd: entity.y + bar.height + 1,
  });

  const cellClassName = classNames({
    'wb-cell': true,
    'is-selected': false,
    'is-dragging': false,
  });

  return (
    <div role="button" tabIndex="0" className={cellClassName} style={getStyleFromPosition()}>
      {/* <DragPreviewImage
        connect={preview}
        src={'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='}
      ></DragPreviewImage> */}
      <div className="wb-cell__inner">
        <div className="wb-cell__controls">
          <div className="wb-cell__tag">
            <Translate resourceString={`profile-manager.custombar.level.${bar.level}`} />
          </div>
        </div>
        <AbsoluteGridView key={entity.id} settings={grid[app.selectedBay]} bar={bar} products={grid.data} />
      </div>
      <div className="wb-cell__bg"></div>
    </div>
  );
};

const mapStateToProps = state => ({
  grid: state.app.present.grid,
  app: state.app.present.app,
});

export default connect(mapStateToProps, null)(GridBar);
