import { StyleSheet, Font } from '@react-pdf/renderer';

import helvetica93 from '@fonts/milwaukee/7de87fb1-de1f-45c3-adef-b60ed40c7beb.ttf';
import helvetica53 from '@fonts/milwaukee/0504481e-774c-4e57-a055-c94bb5303380.ttf';

Font.register({ family: 'helvetica53', src: helvetica53 });
Font.register({ family: 'helvetica93', src: helvetica93 });

export const brand = {
  darkRed: '#db011c',
  reallyDarkRed: '#a90000',
  grey: 'rgb(129,132,131)',
  lightGrey: '#e4e4e4',
  sideMargin: 45,
};

const styles = StyleSheet.create({
  halfSection: {
    width: '50%',
    padding: 45,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  page: {
    backgroundColor: 'white',
    fontSize: 7,
    fontFamily: 'helvetica53',
  },
  paddedPage: {},
  section: {
    paddingTop: 20,
    paddingLeft: brand.sideMargin,
    paddingRight: brand.sideMargin,
  },
  item: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: 7,
    fontWeight: 400,
    marginBottom: 10,
  },
  detail: {
    display: 'flex',
    flexDirection: 'row',

    marginBottom: 15,
  },
  detailName: { textTransform: 'uppercase', fontFamily: 'helvetica93' },
  detailValue: { color: brand.grey, maxWidth: '50%' },
  id: { color: brand.grey, width: 50 },
  details: {},
  heading: {
    fontFamily: 'helvetica93',
    fontSize: 9,
    fontWeight: 700,
    marginBottom: 10,
    textTransform: 'uppercase',
  },
  greyed: {
    color: brand.grey,
  },
  centeredLogo: { marginLeft: 'auto', marginRight: 'auto', marginTop: 21, marginBottom: 21 },
});

export default styles;
