import React, { useRef, useState } from 'react';
import debounce from 'lodash/debounce';

const NumberInput = ({ value, onChange, showControls = true }) => {
  const [internalValue, setInternalValue] = useState(parseInt(value, 10));

  // Debounced input change
  const debounceSetInternalValue = useRef(
    debounce(newValue => {
      onChange(newValue);
    }, 375),
  );

  // Handles realtime input change
  const handleSetInternalValue = e => {
    let newValue = parseInt(e.currentTarget.value, 10);

    if (Number.isNaN(newValue)) {
      newValue = 1;
    }

    setInternalValue(newValue);

    debounceSetInternalValue.current(newValue);
  };

  // Handles realtime button inputs
  const handleButtonChange = newValue => {
    if (newValue >= 0) {
      setInternalValue(newValue);
      debounceSetInternalValue.current(newValue);
    }
  };

  return (
    <div className="number__wrapper">
      <div className="number__inner">
        <button
          type="button"
          className={`number__minus ${showControls ? '' : 'number--hidden'}`}
          onClick={() => handleButtonChange(internalValue - 1)}
        ></button>

        <input
          type="number"
          className="number__value"
          value={internalValue}
          onChange={e => handleSetInternalValue(e)}
          readOnly={!showControls}
        />

        <button
          type="button"
          className={`number__plus ${showControls ? '' : 'number--hidden'}`}
          onClick={() => handleButtonChange(internalValue + 1)}
        ></button>
      </div>
    </div>
  );
};

export default NumberInput;
