import ACTIONS from '@redux/actions';
import ApiService from 'js/api/ApiService';
import keys from '@components/translations/keys';
import BarEditMode from 'js/enums/BarEditMode';

export function openSubNav(id) {
  return {
    type: ACTIONS.APP.OPEN_SUB_NAV,
    payload: id,
  };
}

export const closeSubNav = () => (dispatch, getState) => {
  const state = getState();
  const resetSelectedBay = state.app.present.grid.barEditMode === BarEditMode.CLOSED;

  dispatch({
    type: ACTIONS.APP.CLOSE_SUB_NAV,
    payload: { resetSelectedBay },
  });
};

export function openHoverNav(id) {
  return {
    type: ACTIONS.APP.OPEN_HOVER_NAV,
    payload: id,
  };
}

export const closeHoverNav = () => (dispatch, getState) => {
  const state = getState();
  const resetSelectedBay = state.app.present.grid.barEditMode === BarEditMode.CLOSED;

  dispatch({
    type: ACTIONS.APP.CLOSE_HOVER_NAV,
    payload: { resetSelectedBay },
  });
};

export function clearNavigation() {
  return {
    type: ACTIONS.APP.CLEAR_NAVIGATION,
  };
}

export function toggleProductImages() {
  return {
    type: ACTIONS.APP.TOGGLE_PRODUCT_IMAGES,
  };
}

export function toggleGridBackground() {
  return {
    type: ACTIONS.APP.TOGGLE_GRID_BACKGROUND,
  };
}

export function toggleBackground() {
  return {
    type: ACTIONS.APP.TOGGLE_BACKGROUND,
  };
}

export function setSelectedBay(index) {
  return {
    type: ACTIONS.APP.SET_SELECTED_BAY,
    payload: index,
  };
}

export function setCustomBarName(name) {
  return {
    type: ACTIONS.APP.SET_CUSTOM_BAR_NAME,
    payload: name,
  };
}

export function setCustomBarLevel(level) {
  return {
    type: ACTIONS.APP.SET_CUSTOM_BAR_LEVEL,
    payload: level,
  };
}

export const fetchTranslations = ({ cultureCode }) => async dispatch => {
  dispatch({ type: ACTIONS.APP.FETCH_TRANSLATIONS });

  try {
    const response = await ApiService.request({
      slug: 'localisations',
      controller: 'localisation',
      verb: 'POST',
      params: { cultureCode, keys },
    });

    dispatch({
      type: ACTIONS.APP.FETCH_TRANSLATIONS_SUCCESS,
      payload: { ...response.Localisations },
    });
  } catch (error) {
    dispatch({
      type: ACTIONS.APP.FETCH_TRANSLATIONS_ERROR,
      payload: error,
    });
  }
};

export function setAppCulture({ cultureCode }) {
  return {
    type: ACTIONS.APP.SET_CULTURE,
    payload: cultureCode,
  };
}

export function toggleShift({ pressed }) {
  return {
    type: ACTIONS.APP.TOGGLE_SHIFT_PRESSED,
    payload: pressed,
  };
}

export function toggleCtrl({ pressed }) {
  return {
    type: ACTIONS.APP.TOGGLE_CTRL_PRESSED,
    payload: pressed,
  };
}

export function dragSelectInitialised() {
  return {
    type: ACTIONS.APP.DRAGSELECT_INITIALISED,
  };
}
