import React from 'react';
import { ContextMenu, MenuItem } from 'react-contextmenu';
import { connect } from 'react-redux';
import { removeBay } from '@redux/actioncreators/grid';
import { t } from '../translations/index';

const BayListContextMenu = ({ project, _removeBay }) => {
  const handleRemoveBay = (e, data, target) => {
    const params = JSON.parse(target.getAttribute('data-remove'));

    params.wallbayProjectGuid = project.projectGuid;

    _removeBay(params);
  };

  return (
    <ContextMenu id="bay-context-menu">
      <MenuItem onClick={handleRemoveBay}>{t('profile-manager.bay.delete')}</MenuItem>
    </ContextMenu>
  );
};

const mapStateToProps = state => ({
  project: state.app.present.project,
});

const mapDispatchToProps = dispatch => ({
  _removeBay: params => dispatch(removeBay(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BayListContextMenu);
